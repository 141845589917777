import React from 'react'
import { useIntl } from 'react-intl'
import Error from 'components/Error/Error'
import LoadMore from 'components/LoadMore/LoadMore'
import Loader from 'components/Loader/Loader'
import NoData from 'components/NoData/NoData'
import Table from 'components/Table/Table'
import useStyles from './styles'

const UsersTab = ({ actions, users, last, loadMore, loading, loadingMore, error, parameters, handleSort }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const columns = [
    { id: 'username', sorting: true, label: formatMessage({ id: 'Users.column.name' }) },
    { id: 'email', sorting: true, label: formatMessage({ id: 'Users.column.email' }) },
    { id: 'phoneNumber', sorting: true, label: formatMessage({ id: 'Users.column.phone' }) },
    {
      id: 'boilerQuantity',
      sorting: true,
      label: formatMessage({ id: 'Users.column.boilers' }),
      render: (user) => formatMessage({ id: 'Users.table.boilerQuantity' }, { 0: user.boilerQuantity })
    },
    { id: 'company', sorting: true, label: formatMessage({ id: 'Users.column.company' }) },
    { id: 'representative', sorting: true, label: formatMessage({ id: 'Users.column.representative' }) },
    { id: 'role', sorting: true, label: formatMessage({ id: 'Users.column.role' }) }
  ]

  return (
    <>
      {error && <Error />}
      {loading && <Loader />}
      {!error && !loading && users.length === 0 && <NoData />}
      {!error && !loading && users.length > 0 && (
        <Table
          columns={columns}
          data={users}
          defaultOrderKey={parameters.sortBy}
          defaultOrder={parameters.sorting.toLowerCase()}
          onSortChange={handleSort}
          className={{ actionsContainer: classes.noJustify }}
          actions={actions}
        />
      )}
      {!error && !loading && !last && (
        <LoadMore loadMore={loadMore} isLoading={loadingMore} className={classes.loadMoreMargin} />
      )}
    </>
  )
}

export default UsersTab
