import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import BoilerDataRow from 'components/BoilerDataRow/BoilerDataRow'
import BoilerImage from 'components/BoilerImage/BoilerImage'
import { buildAddress, buildName, valueOrDefault } from 'utils/Utils'
import { useMobileContext } from 'context/MobileContext'
import useStyles from './styles'

const BoilerDataCard = ({ boiler }) => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()

  return (
    <div className={classes.leftBox}>
      {!isMobile && (
        <div className={classNames(classes.image, { [classes.imageBackground]: boiler && !boiler.boilerImage })}>
          {boiler && <BoilerImage imageUrl={boiler.boilerImage} height={270} genericHeight={226} />}
        </div>
      )}
      <div className={classes.dataRows}>
        <BoilerDataRow
          label={'BoilerStatus.data.prop.model'}
          value={valueOrDefault(boiler, 'model', '')}
          className={classes.mobileGreyRow}
          isStatus={true}
          wrap={true}
        />
        <BoilerDataRow
          label={'BoilerStatus.data.prop.serial'}
          value={valueOrDefault(boiler, 'serialNumber', '')}
          className={classes.mobileWhiteRow}
          isStatus={true}
          wrap={true}
        />
        <BoilerDataRow
          label={'BoilerStatus.data.prop.owner'}
          value={boiler ? buildName(boiler.homeOwnerFirstName, boiler.homeOwnerLastName) : ''}
          className={classes.mobileGreyRow}
          isStatus={true}
          wrap={true}
        />
        <BoilerDataRow
          label={'BoilerStatus.data.prop.address'}
          value={buildAddress(
            valueOrDefault(boiler, 'homeOwnerGeofence', null),
            useIntl().formatMessage({ id: 'Commons.noData' })
          )}
          bold={false}
          className={classes.mobileWhiteRow}
          isStatus={true}
          wrap={true}
        />
        <BoilerDataRow
          label={'BoilerStatus.data.prop.phone'}
          value={valueOrDefault(boiler, 'homeOwnerPhone', '')}
          className={classes.mobileGreyRow}
          isStatus={true}
          wrap={true}
        />
        <BoilerDataRow
          label={'BoilerStatus.data.prop.email'}
          value={valueOrDefault(boiler, 'homeOwnerEmail', '')}
          bold={false}
          email={true}
          className={classes.mobileWhiteRow}
          isStatus={true}
          wrap={true}
        />
        <BoilerDataRow
          label={'BoilerStatus.data.prop.boilerAddress'}
          value={buildAddress(
            valueOrDefault(boiler, 'geofence', null),
            useIntl().formatMessage({ id: 'Commons.noData' })
          )}
          bold={false}
          className={classes.mobileGreyRow}
          isStatus={true}
          wrap={true}
        />
        {!isMobile && (
          <BoilerDataRow
            label={'BoilerStatus.data.prop.installer'}
            value={valueOrDefault(boiler, 'companyName', '')}
            optional={valueOrDefault(boiler, 'technicianName', '')}
            bold={true}
            wrap={true}
          />
        )}
        {isMobile && (
          <BoilerDataRow
            label={'BoilerStatus.data.prop.company'}
            value={valueOrDefault(boiler, 'companyName', '')}
            bold={false}
            className={classes.mobileWhiteRow}
            isStatus={true}
            wrap={true}
          />
        )}
        {isMobile && (
          <BoilerDataRow
            label={'BoilerStatus.data.prop.soloInstaller'}
            value={valueOrDefault(boiler, 'technicianName', '')}
            bold={false}
            className={classes.mobileGreyRow}
            isStatus={true}
            wrap={true}
          />
        )}
        <BoilerDataRow
          label={'BoilerStatus.data.prop.representative'}
          value={valueOrDefault(boiler, 'representativeName', '')}
          bold={false}
          className={classes.mobileWhiteRow}
          isStatus={true}
          wrap={true}
        />
      </div>
    </div>
  )
}

export default BoilerDataCard
