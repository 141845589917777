import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    gap: 10
  },
  checkbox: {
    padding: 5,
    color: theme.palette.web.lightGrey,
    '&.Mui-checked:hover, &:hover': {
      backgroundColor: 'transparent'
    }
  },
  checked: {
    color: theme.palette.web.blue
  },
  checkboxLabel: {
    '&.MuiTypography-root': {
      fontSize: '14px !important'
    }
  },
  groupLabel: {
    color: theme.palette.web.darkGrey,
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    alignSelf: 'center'
  }
}))
