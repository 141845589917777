import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout/Layout'
import ReportsPanel from 'components/ReportsPanel/ReportsPanel'

const Reports = () => {
  const title = useIntl().formatMessage({ id: 'Reports.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout>
        <ReportsPanel />
      </Layout>
    </>
  )
}

export default Reports
