import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { Button } from '@mui/material'
import Loader from 'components/Loader/Loader'
import PageTitle from 'components/PageTitle/PageTitle'
import ReportsStepper from 'components/ReportsStepper/ReportsStepper'
import AlarmHistoryFormConfirmationStep from 'components/Steps/AlarmHistoryFormConfirmationStep/AlarmHistoryFormConfirmationStep'
import AlarmHistoryFormStep from 'components/Steps/AlarmHistoryFormStep/AlarmHistoryFormStep'
import AlarmHistoryReportStep from 'components/Steps/AlarmHistoryReportStep/AlarmHistoryReportStep'
import EnrolledAudienceFormConfirmationStep from 'components/Steps/EnrolledAudienceFormConfirmationStep/EnrolledAudienceFormConfirmationStep'
import EnrolledAudienceFormStep from 'components/Steps/EnrolledAudienceFormStep/EnrolledAudienceFormStep'
import EnrolledAudienceReportStep from 'components/Steps/EnrolledAudienceReportStep/EnrolledAudienceReportStep'
import ReportsStep from 'components/Steps/ReportStep/ReportsStep'
import useCallService from 'services/useCallService'
import {
  ALARM_HISTORY_FORM_CONFIRMATION_STEP_ID,
  ALARM_HISTORY_FORM_STEP_ID,
  ALARM_HISTORY_REPORT_STEP_ID,
  ENROLLED_AUDIENCE_FORM_CONFIRMATION_STEP_ID,
  ENROLLED_AUDIENCE_FORM_STEP_ID,
  ENROLLED_AUDIENCE_REPORT_STEP_ID,
  REPORTS_STEP_ID
} from 'data/ReportsData'
import { GET_ALARM_TYPES_URL, GET_ALARMS_URL, GET_REPRESENTATIVES_URL, GET_USER_STATUSES_URL } from 'Constants'
import useStyles from './styles'

const ReportsPanel = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callSecurityService, callBoilerService } = useCallService()
  const [selectedStep, setSelectedStep] = useState(REPORTS_STEP_ID)
  const [showButton, setShowButton] = useState(false)
  const [isLoadingGlobal, setIsLoadingGlobal] = useState(true)
  const [alarmTypes, setAlarmTypes] = useState([])
  const [alarms, setAlarms] = useState([])
  const [userStatuses, setUserStatuses] = useState([])
  const [representatives, setRepresentatives] = useState([])
  const [needsToEval, setNeedsToEval] = useState(false)
  const stepRefs = useRef({})

  const alarmReportValidationSchema = yup.object({
    alarmType: yup.string(),
    alarm: yup.array(),
    boilers: yup.array().min(1)
  })
  const alarmReportForm = useFormik({
    initialValues: {
      alarmType: '',
      alarm: [],
      boilers: [],
      allSelected: false,
      boilersFullList: [],
      completed: false,
      filters: null
    },
    validationSchema: alarmReportValidationSchema,
    onSubmit: async () => {}
  })

  const enrolledAudienceValidationSchema = yup.object({
    statuses: yup.string(),
    representative: yup.string()
  })
  const enrolledAudienceReportForm = useFormik({
    initialValues: {
      statuses: '',
      representative: '',
      boilersAssigned: undefined,
      completed: false,
      filters: null
    },
    validationSchema: enrolledAudienceValidationSchema,
    onSubmit: async () => {}
  })

  const loadAlarmTypes = () => {
    setIsLoadingGlobal(true)

    async function fetchAlarmTypes() {
      return callBoilerService('GET', `${GET_ALARM_TYPES_URL}`, {}, true)
    }

    fetchAlarmTypes()
      .then((response) => {
        setAlarmTypes(
          [{ value: 'ALL', label: formatMessage({ id: 'Commons.label.all' }) }].concat(
            response.data.map((alarmType) => ({ value: alarmType.key, label: alarmType.label }))
          )
        )
        alarmReportForm.setFieldValue('alarmType', 'ALL')
      })
      .finally(() => {
        setIsLoadingGlobal(false)
      })
  }

  const loadAlarms = () => {
    setIsLoadingGlobal(true)

    async function fetchAlarms() {
      return callBoilerService('GET', `${GET_ALARMS_URL}`, {}, true)
    }

    fetchAlarms()
      .then((response) => {
        setAlarms(response.data.map((alarm) => ({ value: alarm.key, label: alarm.label })))
      })
      .finally(() => {
        setIsLoadingGlobal(false)
      })
  }

  const loadStatuses = () => {
    setIsLoadingGlobal(true)

    async function fetchUserStatuses() {
      return callSecurityService('GET', `${GET_USER_STATUSES_URL}`, {}, true)
    }

    fetchUserStatuses()
      .then((response) => {
        setUserStatuses(
          [{ value: 'ALL', label: formatMessage({ id: 'Commons.label.all' }) }].concat(
            response.data.map((status) => ({ value: status.value, label: status.label }))
          )
        )
        enrolledAudienceReportForm.setFieldValue('statuses', 'ALL')
      })
      .finally(() => {
        setIsLoadingGlobal(false)
      })
  }

  const loadRepresentatives = () => {
    setIsLoadingGlobal(true)

    async function fetchRepresentatives() {
      return callSecurityService('GET', `${GET_REPRESENTATIVES_URL}?filterUSB=true`, {}, true)
    }

    fetchRepresentatives()
      .then((response) => {
        setRepresentatives(
          [{ value: 'ALL', label: formatMessage({ id: 'Commons.label.all' }) }].concat(
            response.data.map((rep) => ({ value: rep.id, label: rep.name }))
          )
        )
        enrolledAudienceReportForm.setFieldValue('representative', 'ALL')
      })
      .finally(() => {
        setIsLoadingGlobal(false)
      })
  }

  useEffect(() => {
    loadAlarmTypes()
    loadAlarms()
    loadStatuses()
    loadRepresentatives()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeStep = (step) => {
    setSelectedStep(step)
  }

  const assignStepRef = (name) => (stepRef) => {
    stepRefs.current[name] = stepRef
  }

  const reset = () => {
    Object.values(stepRefs.current).forEach((stepRef) => {
      stepRef.reset()
    })
  }

  const newReport = () => {
    setShowButton(false)
    reset()
    changeStep(REPORTS_STEP_ID)
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <PageTitle titleId={'Reports.panel.title'} />
        {showButton && (
          <Button onClick={newReport} className={classes.primaryButton}>
            {formatMessage({ id: 'Reports.actions.new' })}
          </Button>
        )}
      </div>
      <div className={classes.dataContainer}>
        {isLoadingGlobal && <Loader />}
        {!isLoadingGlobal && (
          <ReportsStepper activeStep={selectedStep}>
            <ReportsStep
              ref={assignStepRef(REPORTS_STEP_ID)}
              step={REPORTS_STEP_ID}
              changeStep={changeStep}
              showButton={setShowButton}
            />
            <AlarmHistoryFormStep
              ref={assignStepRef(ALARM_HISTORY_FORM_STEP_ID)}
              step={ALARM_HISTORY_FORM_STEP_ID}
              changeStep={changeStep}
              alarms={alarms}
              alarmTypes={alarmTypes}
              reportForm={alarmReportForm}
              setNeedsToEval={setNeedsToEval}
            />
            <AlarmHistoryFormConfirmationStep
              ref={assignStepRef(ALARM_HISTORY_FORM_CONFIRMATION_STEP_ID)}
              step={ALARM_HISTORY_FORM_CONFIRMATION_STEP_ID}
              changeStep={changeStep}
              showButton={setShowButton}
              alarms={alarms}
              alarmTypes={alarmTypes}
              reportForm={alarmReportForm}
              needsToEval={needsToEval}
              setNeedsToEval={setNeedsToEval}
            />
            <AlarmHistoryReportStep
              ref={assignStepRef(ALARM_HISTORY_REPORT_STEP_ID)}
              step={ALARM_HISTORY_REPORT_STEP_ID}
              alarms={alarms}
              alarmTypes={alarmTypes}
              reportForm={alarmReportForm}
            />
            <EnrolledAudienceFormStep
              ref={assignStepRef(ENROLLED_AUDIENCE_FORM_STEP_ID)}
              step={ENROLLED_AUDIENCE_FORM_STEP_ID}
              changeStep={changeStep}
              reportForm={enrolledAudienceReportForm}
              userStatuses={userStatuses}
              representatives={representatives}
            />
            <EnrolledAudienceFormConfirmationStep
              ref={assignStepRef(ENROLLED_AUDIENCE_FORM_CONFIRMATION_STEP_ID)}
              step={ENROLLED_AUDIENCE_FORM_CONFIRMATION_STEP_ID}
              changeStep={changeStep}
              showButton={setShowButton}
              reportForm={enrolledAudienceReportForm}
              userStatuses={userStatuses}
              representatives={representatives}
            />
            <EnrolledAudienceReportStep
              ref={assignStepRef(ENROLLED_AUDIENCE_REPORT_STEP_ID)}
              step={ENROLLED_AUDIENCE_REPORT_STEP_ID}
              reportForm={enrolledAudienceReportForm}
              userStatuses={userStatuses}
              representatives={representatives}
            />
          </ReportsStepper>
        )}
      </div>
    </div>
  )
}

export default ReportsPanel
