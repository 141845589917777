import useSWR from 'swr'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import ButtonWithMenu from 'components/ButtonWithMenu/ButtonWithMenu'
import MenuPopperItem from 'components/MenuPopperItem/MenuPopperItem'
import Table from 'components/Table/Table'
import useCallService from 'services/useCallService'
import { hasPermission } from 'utils/Utils'
import { useMobileContext } from 'context/MobileContext'
import BUTTON_DOTS from 'assets/images/icons-button-dots.svg'
import ICON_DELETE from 'assets/images/icons-delete.svg'
import ICON_DOWNLOAD from 'assets/images/icons-download.svg'
import ICON_VIEW from 'assets/images/icons-show.svg'
import { DELETE_ARCHIVES_PERMISSION, DELETE_FILE_URL, GET_ARCHIVES_BY_ID_URL } from 'Constants'
import Error from '../Error/Error'
import Loader from '../Loader/Loader'
import NoData from '../NoData/NoData'
import useStyles from './styles'

const ArchivesTab = ({ boilerId, searchValue }) => {
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { callFileStorageService } = useCallService()
  const fetcher = (url) => callFileStorageService('GET', url, {}, true).then((response) => response.data)
  const [sorting, setSorting] = useState('ASC')
  const [sortBy, setSortBy] = useState('createdDate')
  const { isMobile } = useMobileContext()
  const canDeleteArchives = hasPermission(DELETE_ARCHIVES_PERMISSION)

  const { data, error, mutate } = useSWR(
    `${GET_ARCHIVES_BY_ID_URL}?boilerId=${boilerId}${
      searchValue ? `&searchString=${encodeURIComponent(searchValue)}` : ''
    }&sorting=${sorting}&sortBy=${sortBy}`,
    fetcher
  )

  const columns = [
    {
      id: 'createdDate',
      sorting: true,
      label: formatMessage({ id: 'BoilerStatus.archives.column.date' }),
      type: 'date'
    },
    { id: 'type', sorting: true, label: formatMessage({ id: 'BoilerStatus.archives.column.type' }) },
    { id: 'createdBy', sorting: true, label: formatMessage({ id: 'BoilerStatus.archives.column.createdBy' }) },
    { id: 'company', sorting: true, label: formatMessage({ id: 'BoilerStatus.archives.column.company' }) }
  ]

  const handleSort = (order, orderBy) => {
    setSorting(order.toUpperCase())
    setSortBy(orderBy)
  }

  const removeArchive = (fileId) => callFileStorageService('DELETE', DELETE_FILE_URL + fileId, {}, true)

  const handleView = (boiler) => {
    window.open(boiler.url, '_blank')
  }

  const handleDelete = async (archiveToRemove) => {
    const removedArchive = data.filter((archive) => archive.id !== archiveToRemove.id)

    mutate(removedArchive)

    try {
      await removeArchive(archiveToRemove.id)
    } catch (e) {
      enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), { variant: 'error' })
    }

    mutate()
  }

  // Error in request
  if (error) {
    return <Error />
  }
  // Loading
  if (!data) {
    return <Loader />
  }

  if (data.length === 0) {
    return <NoData />
  }

  return (
    <div>
      {!isMobile ? (
        <Table
          columns={columns}
          data={data}
          defaultOrderKey={sortBy}
          defaultOrder={sorting.toLowerCase()}
          onSortChange={handleSort}
          actions={[
            {
              label: (boiler) =>
                boiler.url.includes('.pdf')
                  ? formatMessage({ id: 'Commons.actions.view' })
                  : formatMessage({ id: 'Commons.actions.download' }),
              onClick: handleView,
              className: classes.viewButton
            },
            {
              label: formatMessage({ id: 'Commons.actions.delete' }),
              onClick: handleDelete,
              visible: () => !canDeleteArchives
            }
          ]}
        />
      ) : (
        <div className={classes.itemsList}>
          {data.map((archive, index) => (
            <div key={index} className={classes.tableRowSimple}>
              <div className={classes.rowData}>
                <div className={classes.primaryLine}>{archive.type}</div>
                <div className={classes.secondaryLine}>{moment(archive.createdDate).format('L LTS')}</div>
                <div className={classes.secondaryLine}>
                  {archive.createdBy} - {archive.company}
                </div>
              </div>
              <div className={classes.actionButton}>
                <ButtonWithMenu icon={BUTTON_DOTS}>
                  <MenuPopperItem
                    icon={archive.url.includes('.pdf') ? ICON_VIEW : ICON_DOWNLOAD}
                    labelId={archive.url.includes('.pdf') ? 'Commons.actions.view' : 'Commons.actions.download'}
                    onClick={() => handleView(archive)}
                    onMouseDown={() => handleView(archive)}
                  />
                  {canDeleteArchives && (
                    <MenuPopperItem
                      icon={ICON_DELETE}
                      labelId={'Commons.actions.delete'}
                      onClick={() => handleDelete(archive)}
                      onMouseDown={() => handleDelete(archive)}
                    />
                  )}
                </ButtonWithMenu>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default ArchivesTab
