import axios from 'axios'
import { useHistory } from 'react-router-dom'
import cookie from 'js-cookie'
import { BEARER, INDEX, LANGUAGE, TOKEN } from 'Constants'

const useCallService = () => {
  const history = useHistory()

  const callSecurityService = async (method, url, options, withCredentials) =>
    callService(method, process.env.REACT_APP_MS_SECURITY_SERVICE + url, options, withCredentials)

  const callBoilerService = async (method, url, options, withCredentials) =>
    callService(method, process.env.REACT_APP_MS_BOILER_SERVICE + url, options, options, withCredentials)

  const callFileStorageService = async (method, url, options, withCredentials) =>
    callService(method, process.env.REACT_APP_MS_FILE_STORAGE_SERVICE + url, options, withCredentials)

  const callGatewayService = async (method, url, options, withCredentials) =>
    callService(method, process.env.REACT_APP_MS_GATEWAY_SERVICE + url, options, withCredentials)

  const callService = async (method, url, options = {}, withCredentials = true) => {
    try {
      const token = cookie.get(TOKEN) ? cookie.get(TOKEN) : ''
      const language = cookie.get(LANGUAGE) ? cookie.get(LANGUAGE) : ''
      return await axios({
        method,
        baseURL: options.baseUrl || process.env.REACT_APP_API_HOST,
        url,
        data: options.data,
        headers: {
          Language: language,
          ...(withCredentials && { Authorization: `${BEARER} ${token}` }),
          ...options.headers
        }
      })
    } catch (error) {
      // The user is not authenticated or has invalid credentials.
      // Redirect him to the login page
      if (error.response && error.response.status === 401) {
        cookie.remove(TOKEN)
        history.push(INDEX)
      }
      throw error
    }
  }

  return { callSecurityService, callBoilerService, callFileStorageService, callGatewayService, callService }
}

export default useCallService
