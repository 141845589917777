import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import CustomButton from 'components/CustomButton/CustomButton'
import Loader from 'components/Loader/Loader'
import useStyles from './styles'

const ConfirmationScreen = ({ messageId, hasButton = false, buttonId, buttonAction, loading = false }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.textContainer}>
        {loading ? (
          <Loader />
        ) : (
          <Typography className={classes.text}>
            <FormattedMessage id={messageId} />
          </Typography>
        )}
      </div>
      {hasButton && (
        <div className={classes.bottomContainer}>
          <CustomButton labelId={buttonId} action={buttonAction} fullWidth disabled={loading} />
        </div>
      )}
    </>
  )
}

export default ConfirmationScreen
