import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import cookie from 'js-cookie'
import Button from '@material-ui/core/Button'
import LanguageSelector from 'components/LanguageSelector/LanguageSelector'
import PageTitle from 'components/PageTitle/PageTitle'
import ReadOnlyTextField from 'components/ReadOnlyTextField/ReadOnlyTextField'
import ResetMyPasswordMobileModal from 'components/ResetPasswordMobileModal/ResetMyPasswordMobileModal'
import ResetMyPasswordModal from 'components/ResetPasswordModal/ResetMyPasswordModal'
import useCallService from 'services/useCallService'
import * as Utils from 'utils/Utils'
import { useMobileContext } from 'context/MobileContext'
import { CONTRACTOR_ROLE, REP_ROLE, TECHNICIAN_ROLE, USER_DATA, USER_PROFILE_URL } from 'Constants'
import useStyles from './styles'

const ProfilePanel = () => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const { formatMessage } = useIntl()
  const { callSecurityService } = useCallService()
  const userData = JSON.parse(cookie.get(USER_DATA))
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [phoneNumber, setPhoneNumber] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')
  const [companyAddress, setCompanyAddress] = React.useState('')
  const [openModal, setOpenModal] = React.useState(false)

  useEffect(() => {
    async function fetchUserProfile() {
      return callSecurityService('GET', USER_PROFILE_URL.replace('{userId}', userData.id), {}, true)
    }

    fetchUserProfile().then((response) => {
      userData.firstName = response.data.firstName
      setFirstName(response.data.firstName)
      userData.lastName = response.data.lastName
      setLastName(response.data.lastName)
      userData.email = response.data.email
      setEmail(response.data.email)
      userData.phoneNumber = response.data.phoneNumber
      setPhoneNumber(response.data.phoneNumber)
      userData.companyName = response.data.companyName
      setCompanyName(response.data.companyName)
      const compA = Utils.buildAddress(response.data.companyAddress)
      userData.companyAddress = compA
      setCompanyAddress(compA)
      cookie.set(USER_DATA, userData, { expires: 1 })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <PageTitle titleId={'Profile.panel.title'} />
      </div>
      <div className={classes.dataContainer}>
        <div>
          <ReadOnlyTextField
            labelId={userData.role === REP_ROLE ? 'Profile.panel.company' : 'Profile.panel.firstName'}
            value={firstName}
          />
          <ReadOnlyTextField
            labelId={userData.role === REP_ROLE ? 'Profile.panel.name' : 'Profile.panel.lastName'}
            value={lastName}
          />
          <ReadOnlyTextField labelId={'Profile.panel.email'} value={email} />
          <ReadOnlyTextField labelId={'Profile.panel.phoneNumber'} value={phoneNumber} />
          {(userData.role === CONTRACTOR_ROLE || userData.role === TECHNICIAN_ROLE) && (
            <ReadOnlyTextField labelId={'Profile.panel.companyName'} value={companyName} />
          )}
          {(userData.role === CONTRACTOR_ROLE || userData.role === TECHNICIAN_ROLE) && (
            <ReadOnlyTextField labelId={'Profile.panel.companyAddress'} value={companyAddress} />
          )}
        </div>
        <div className={classes.line} />
        <div className={classes.actionsContainer}>
          <LanguageSelector userId={userData.id} />
          <Button type={'button'} fullWidth={isMobile} className={classes.button} onClick={handleOpenModal}>
            <FormattedMessage id={'Profile.panel.resetPassword'} />
          </Button>
        </div>
      </div>
      {!isMobile ? (
        <ResetMyPasswordModal
          open={openModal}
          handleClose={handleCloseModal}
          title={formatMessage({ id: 'Profile.modal.title' })}
          userId={userData.id}
        />
      ) : (
        <ResetMyPasswordMobileModal
          open={openModal}
          handleClose={handleCloseModal}
          title={formatMessage({ id: 'Profile.modal.title' })}
          userId={userData.id}
        />
      )}
    </div>
  )
}

export default ProfilePanel
