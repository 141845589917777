import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  text: {
    fontSize: 14,
    lineHeight: 1.57,
    color: theme.palette.web.darkGrey
  },
  title: {
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 10
  }
}))
