import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  enabledButton: {
    color: theme.palette.web.blue
  },
  icon: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.blue,
      width: 24,
      height: 24
    }
  },
  iconPadding: {
    padding: '0px 8px 0px 0px'
  },
  noJustify: {
    justifyContent: 'normal'
  },
  loadMoreMargin: {
    marginTop: 20
  }
}))
