import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { TextField } from '@material-ui/core'
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener'
import InputAdornment from '@material-ui/core/InputAdornment'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import ICON_SEARCH from 'assets/images/icons-search.svg'
import useStyles from './styles'

const SearchBar = ({
  placeholderId,
  onChange = () => {},
  value = '',
  persistOpened = false,
  rootClassName,
  mobileRoot,
  fullWidth
}) => {
  const classes = useStyles()
  const placeholder = useIntl().formatMessage({ id: placeholderId, defaultMessage: ' ' })
  const [close, setClose] = useState(true)

  const handleOpen = () => {
    setClose(false)
  }

  const handleClose = () => {
    setClose(true)
  }

  return (
    <>
      {!persistOpened && !value && close ? (
        <CustomIcon
          icon={ICON_SEARCH}
          className={classNames(classes.icon, classes.iconPadding)}
          onClick={() => handleOpen()}
        />
      ) : (
        <ClickAwayListener onClickAway={() => handleClose()}>
          <TextField
            autoFocus
            value={value}
            classes={{ root: classes.mobileRoot }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: rootClassName || classNames(classes.root, mobileRoot),
                input: classes.input
              },
              endAdornment: (
                <InputAdornment position="end">
                  <CustomIcon icon={ICON_SEARCH} className={classNames(classes.icon, classes.inputIconPadding)} />
                </InputAdornment>
              )
            }}
            fullWidth={fullWidth}
            onChange={onChange}
            variant={'standard'}
            placeholder={placeholder}
            type={'text'}
            autoComplete={'off'}
          />
        </ClickAwayListener>
      )}
    </>
  )
}

export default SearchBar
