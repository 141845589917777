import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import ContentTable from 'components/ContentTable/ContentTable'
import Loader from 'components/Loader/Loader'
import PageTitle from 'components/PageTitle/PageTitle'
import PolicyBlock from 'components/PolicyBlock/PolicyBlock'
import TextBlock from 'components/TextBlock/TextBlock'
import useCallService from 'services/useCallService'
import { contentTable } from 'data/PolicyContent'
import { GET_BRAND_DATA_URL } from 'Constants'
import useStyles from './styles'

const PrivacyPanel = () => {
  const classes = useStyles()
  const [partner, setPartner] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { callSecurityService } = useCallService()

  useEffect(() => {
    async function fetchBrand() {
      setIsLoading(true)
      return callSecurityService(
        'GET',
        GET_BRAND_DATA_URL.replace('{brand}', process.env.REACT_APP_PARTNER_NAME),
        {},
        false
      )
    }

    fetchBrand().then((response) => {
      setPartner(response.data)
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          <div className={classes.containerHeader}>
            <PageTitle titleId={'Privacy.panel.title'} />
          </div>
          <div className={classes.dataContainer}>
            <Typography className={classNames(classes.text, classes.date)}>
              <FormattedMessage id={'Privacy.panel.lastUpdate'} />
            </Typography>
            <Typography className={classNames(classes.text, classes.title)}>
              <FormattedMessage id={'Privacy.panel.contents'} />
            </Typography>
            <ContentTable elements={contentTable} />
            <PolicyBlock id={'title1'} title={'Privacy.panel.title1'}>
              <TextBlock label={'Privacy.panel.title1.p1'} values={{ 0: partner.name }} />
              <TextBlock label={'Privacy.panel.title1.p2'} style={{ marginTop: 10 }} />
              <ul className={classes.list}>
                <li>
                  <TextBlock
                    label={'Privacy.panel.title1.l1'}
                    values={{ 0: partner.name, 1: <a href={partner.website}>{partner.website}</a> }}
                  />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l2'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l3'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l4'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l5'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l6'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l7'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l8'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l9'} />
                </li>
                <li>
                  <TextBlock label={'Privacy.panel.title1.l10'} />
                </li>
              </ul>
              <TextBlock label={'Privacy.panel.title1.p3'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title1.p4'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title1.p5'} style={{ marginTop: 10 }} />
            </PolicyBlock>
            <PolicyBlock id={'title2'} title={'Privacy.panel.title2'}>
              <TextBlock label={'Privacy.panel.title2.p1'} />
              <TextBlock label={'Privacy.panel.title2.p2'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title2.p3'} style={{ marginTop: 10 }} />
            </PolicyBlock>
            <PolicyBlock id={'title3'} title={'Privacy.panel.title3'}>
              <TextBlock label={'Privacy.panel.title3.p1'} />
            </PolicyBlock>
            <PolicyBlock id={'title4'} title={'Privacy.panel.title4'}>
              <TextBlock label={'Privacy.panel.title4.p1'} />
              <TextBlock label={'Privacy.panel.title4.p2'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title4.p3'} style={{ marginTop: 10 }} />
            </PolicyBlock>
            <PolicyBlock id={'title5'} title={'Privacy.panel.title5'}>
              <TextBlock label={'Privacy.panel.title5.p1'} />
            </PolicyBlock>
            <PolicyBlock id={'title6'} title={'Privacy.panel.title6'}>
              <TextBlock label={'Privacy.panel.title6.p1'} />
              <TextBlock label={'Privacy.panel.title6.p2'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title6.p3'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title6.p4'} style={{ marginTop: 10 }} />
            </PolicyBlock>
            <PolicyBlock id={'title7'} title={'Privacy.panel.title7'}>
              <TextBlock label={'Privacy.panel.title7.p1'} />
              <TextBlock
                label={'Privacy.panel.title7.p2'}
                values={{ b: (...chunks) => <b>{chunks}</b> }}
                style={{ marginTop: 10 }}
              />
            </PolicyBlock>
            <PolicyBlock id={'title8'} title={'Privacy.panel.title8'}>
              <TextBlock label={'Privacy.panel.title8.p1'} />
              <TextBlock
                label={'Privacy.panel.title8.p2'}
                style={{ marginTop: 10 }}
                values={{ 0: <a href={`mailto:${partner.email}`}>{partner.email}</a>, 1: partner.phone }}
              />
            </PolicyBlock>
            <PolicyBlock id={'title9'} title={'Privacy.panel.title9'}>
              <TextBlock
                label={'Privacy.panel.title9.p1'}
                values={{ 0: <a href={`mailto:${partner.email}`}>{partner.email}</a>, 1: partner.phone }}
              />
              <TextBlock label={'Privacy.panel.title9.p2'} style={{ marginTop: 10 }} />
            </PolicyBlock>
            <PolicyBlock id={'title10'} title={'Privacy.panel.title10'}>
              <TextBlock
                label={'Privacy.panel.title10.p1'}
                values={{ 0: <a href={`mailto:${partner.email}`}>{partner.email}</a> }}
              />
            </PolicyBlock>
            <PolicyBlock id={'title11'} title={'Privacy.panel.title11'}>
              <TextBlock label={'Privacy.panel.title11.p1'} />
              <TextBlock label={'Privacy.panel.title11.p2'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title11.p3'} style={{ marginTop: 10 }} />
            </PolicyBlock>
            <PolicyBlock id={'title12'} title={'Privacy.panel.title12'}>
              <TextBlock label={'Privacy.panel.title12.p1'} />
            </PolicyBlock>
            <PolicyBlock id={'title13'} title={'Privacy.panel.title13'}>
              <TextBlock label={'Privacy.panel.title13.p1'} />
              <TextBlock
                label={'Privacy.panel.title13.p2'}
                style={{ marginTop: 10 }}
                values={{ 0: <a href={`mailto:${partner.email}`}>{partner.email}</a> }}
              />
              <TextBlock label={'Privacy.panel.title13.p3'} style={{ marginTop: 10 }} />
              <TextBlock label={'Privacy.panel.title13.p2'} style={{ marginTop: 10 }} values={{ 0: partner.phone }} />
            </PolicyBlock>
          </div>
        </div>
      )}
    </>
  )
}

export default PrivacyPanel
