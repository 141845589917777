import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  label: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.web.darkGrey,
    margin: '8px 18px 0 0'
  },
  radioContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
}))
