import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  card: {
    width: 140,
    height: 140,
    borderRadius: 8,
    padding: 23,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.palette.web.blue,
    color: theme.palette.web.white,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardText: {
    fontSize: 16,
    fontWeight: 900,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'normal'
    }
  }
}))
