import React, { useEffect, useState } from 'react'
import BoilerForm from 'components/BoilerForm/BoilerForm'
import Loader from 'components/Loader/Loader'
import Modal from 'components/Modal/Modal'
import useCallService from 'services/useCallService'
import { GET_BOILERS_MANAGEMENT_DETAILS_URL } from 'Constants'
import useStyles from './styles'

const BoilerModal = ({ open = false, handleClose, title, boilerId }) => {
  const classes = useStyles()
  const { callBoilerService } = useCallService()
  const [boilerToView, setBoilerToView] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchBoilerDetails() {
      setIsLoading(true)
      return callBoilerService('GET', GET_BOILERS_MANAGEMENT_DETAILS_URL + boilerId, {}, true)
    }

    if (boilerId) {
      fetchBoilerDetails()
        .then((response) => {
          setBoilerToView(response.data)
        })
        .finally(() => setIsLoading(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boilerId])

  return (
    <Modal open={open} handleClose={handleClose} title={title} fullWidth={false} classNameModal={classes.modal}>
      {isLoading ? <Loader /> : <BoilerForm boilerToView={boilerToView} />}
    </Modal>
  )
}

export default BoilerModal
