import React from 'react'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const ManualCard = ({ manual }) => {
  const classes = useStyles()

  const openManual = () => {
    window.open(manual.url, '_blank')
  }

  return (
    <div className={classes.card} onClick={openManual}>
      <Typography className={classes.cardText}>{manual.name}</Typography>
    </div>
  )
}

export default ManualCard
