import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh'
  },
  loginLogo: {
    textAlign: 'center'
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '44px 58px 45px',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    [theme.breakpoints.up('sm')]: {
      margin: 'auto',
      boxShadow: `0 4px 12px 0 ${theme.palette.web.shadowGrey}`,
      backgroundColor: theme.palette.web.white,
      borderRadius: 2,
      width: 396,
      height: 466
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto 0'
    }
  }
}))
