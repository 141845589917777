import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: 168,
    maxWidth: 400,
    backgroundColor: theme.palette.web.white,
    borderRadius: 8,
    boxShadow: `0 2px 4px 0 ${theme.palette.web.cardShadow}`,
    overflow: 'hidden',
    cursor: 'pointer'
  },
  cardHeader: {
    height: 32,
    padding: '8px 12px 10px 12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  connection: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cardBody: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    padding: '0 20px 16px 24px'
  },
  image: {
    marginRight: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  imageBackground: {
    width: 88,
    height: 88,
    backgroundColor: theme.palette.web.transparentGrey
  },
  dataRows: {
    width: 'calc(100% - 96px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}))
