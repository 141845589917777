import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    height: '50%'
  },
  root: {
    top: '30% !important',
    right: '0px !important',
    left: '0px !important',
    bottom: '-70% !important'
  },
  paper: {
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    padding: 32
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 20,
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: 'normal',
    textTransform: 'uppercase',
    color: theme.palette.web.darkGrey
  },
  closeIcon: {
    fontSize: 0,
    padding: 0,
    '& svg': {
      fill: theme.palette.web.darkGrey,
      width: 18,
      height: 18
    }
  },
  bodyContainer: {
    display: 'flex'
  },
  contentRoot: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      whiteSpace: 'nowrap',
      scrollbarWidth: 'none' /* Firefox */,
      msOverflowStyle: 'none' /* Internet Explorer 11 */,
      '&::-webkit-scrollbar': {
        display: 'none' /* WebKit (Chrome, Safari) */
      }
    }
  }
}))
