import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  icon: {
    fontSize: 0,
    marginRight: 5,
    opacity: 0.9
  },
  topRight: {
    top: '10%',
    right: 28
  },
  snackbar: {
    height: 44,
    minWidth: 366,
    maxWidth: 500,
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 0.19,
    flexWrap: 'nowrap',
    boxShadow: 'none',
    borderRadius: 8
  },
  variantError: {
    color: `${theme.palette.web.darkRed} !important`,
    border: `1px solid ${theme.palette.web.darkRed} !important`,
    backgroundColor: `${theme.palette.web.transparentRed} !important`,
    '& svg': {
      fill: theme.palette.web.darkRed
    }
  },
  variantSuccess: {
    color: `${theme.palette.web.darkGreen} !important`,
    border: `1px solid ${theme.palette.web.darkGreen} !important`,
    backgroundColor: `${theme.palette.web.transparentGreen} !important`,
    '& svg': {
      fill: theme.palette.web.darkGreen
    }
  },
  variantWarning: {
    color: `${theme.palette.web.darkYellow} !important`,
    border: `1px solid ${theme.palette.web.darkYellow} !important`,
    backgroundColor: `${theme.palette.web.transparentYellow} !important`,
    '& svg': {
      fill: theme.palette.web.darkYellow
    }
  }
}))
