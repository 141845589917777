import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { useIntl } from 'react-intl'
import { KeyboardArrowRight } from '@mui/icons-material'
import { Button } from '@mui/material'
import ReportCard from 'components/ReportCard/ReportCard'
import { hasPermission } from 'utils/Utils'
import { reportsList } from 'data/ReportsData'
import useStyles from './styles'

const ReportsStep = forwardRef(({ step, changeStep = () => undefined }, ref) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [selectedReport, setSelectReport] = useState(null)

  const selectReport = (report) => {
    setSelectReport(report)
  }

  const next = () => {
    changeStep(selectedReport.firstStep)
  }

  const reset = () => {
    setSelectReport(null)
  }

  useImperativeHandle(ref, () => ({
    reset
  }))

  return (
    <React.Fragment key={step}>
      <div className={classes.gridContainer}>
        <div className={classes.gridRoot}>
          {reportsList
            .filter((report) => !report.hasOwnProperty('permissions') || hasPermission(report.permissions))
            .map((report) => (
              <ReportCard
                key={report.id}
                report={report}
                selected={report.id === (selectedReport ? selectedReport.id : undefined)}
                onClick={() => selectReport(report)}
              />
            ))}
        </div>
      </div>
      <div className={classes.bottom}>
        <Button className={classes.transitionButton} onClick={next} disabled={selectedReport === null}>
          {formatMessage({ id: 'Reports.actions.next' })} <KeyboardArrowRight />
        </Button>
      </div>
    </React.Fragment>
  )
})

export default ReportsStep
