import React from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'
import { Virtuoso } from 'react-virtuoso'
import shortId from 'shortid'
import { Typography } from '@material-ui/core'
import StatusTableRow from 'components/StatusTab/StatusTableRow/StatusTableRow'
import ICON_DISCONNECTED from 'assets/images/icons-disconnected.svg'
import useStyles from './styles'

const StatusTab = ({ connected, boilerStatusData }) => {
  const classes = useStyles()

  return (
    <>
      {connected && boilerStatusData && (
        <div>
          <Virtuoso
            className={classes.table}
            style={{ width: '100%', height: '300px' }}
            totalCount={boilerStatusData.length}
            itemContent={(index) => (
              <StatusTableRow
                key={shortId.generate()}
                label={boilerStatusData[index].label}
                value={boilerStatusData[index].value}
                secondaryValue={boilerStatusData[index].secondaryValue}
              />
            )}
          />
        </div>
      )}

      {(!connected || !boilerStatusData) && (
        <div className={classes.disconnectedContainer}>
          <ReactSVG src={ICON_DISCONNECTED} className={classes.disconnectedImage} />
          <Typography className={classes.disconnectedText}>
            <FormattedMessage id={'BoilerStatus.status.disconnected'} />
          </Typography>
        </div>
      )}
    </>
  )
}

export default StatusTab
