import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  boilerRow: {
    display: 'flex',
    flexDirection: 'row',
    height: 80
  },
  mobileRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: 97,
    maxHeight: 97
  },
  firstBlock: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  label: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'capitalize',
    color: theme.palette.web.usbGrey,
    height: 18
  },
  value: {
    fontSize: 34,
    lineHeight: 1,
    fontWeight: 'bold',
    color: theme.palette.web.black
  },
  arrowBlock: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  redArrow: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.red
    }
  },
  blueArrow: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.blue
    }
  },
  placeholder: {
    fontSize: 10,
    fontWeight: 'normal',
    textTransform: 'uppercase',
    marginTop: 1,
    color: theme.palette.web.usbGrey
  },
  image: {
    width: '20%',
    position: 'relative',
    alignSelf: 'center'
  },
  flame: {
    fontSize: 0,
    transform: 'translate(-50%,-50%)',
    '& svg': {
      fill: theme.palette.web.darkRed
    },
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      top: '-8%',
      left: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '8%',
      left: '50%'
    }
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: theme.palette.web.usbGrey,
    fontSize: 0,
    margin: 'auto',
    '& svg': {
      fill: theme.palette.web.white
    },
    [theme.breakpoints.up('sm')]: {
      width: 80,
      height: 60
    },
    [theme.breakpoints.down('sm')]: {
      width: 70,
      height: 70
    }
  },
  notActiveImage: {
    opacity: 0.2
  },
  secondBlock: {
    width: '20%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  mobileValuesBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 8
  },
  mobileImageBlock: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    marginBottom: 8
  }
}))
