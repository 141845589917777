import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import UsersTab from 'components/UsersTab/UsersTab'
import useStyles from 'components/UsersTab/styles'
import { isAdminRole, isEngineeringAdminRole, isRepRole, isUserAdminRole } from 'utils/Utils'
import ICON_ENABLED from 'assets/images/icons-enabled.svg'
import ICON_HIDE from 'assets/images/icons-hide.svg'
import ICON_RESEND from 'assets/images/icons-resend.svg'
import ICON_SHOW from 'assets/images/icons-show.svg'
import ICON_UPGRADE from 'assets/images/icons-upgrade.svg'
import { ENGINEERING_ADMIN_ROLE, HO_ROLE, TECHNICIAN_ROLE, USER_ADMIN_ROLE } from 'Constants'

const PendingUsersTab = ({
  users,
  loading,
  loadingMore,
  loadMore,
  error,
  last,
  parameters,
  handleSort,
  handleEdit,
  handleDisable,
  handleSend,
  handleHODataToggle,
  handleTechToggle,
  loggedRole
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const handleRoleForOnClick = (user) => {
    if ((isEngineeringAdminRole(loggedRole) || isUserAdminRole(loggedRole)) && user.roleEnum === HO_ROLE) {
      return handleHODataToggle(user)
    }
    if ((isEngineeringAdminRole(loggedRole) || isUserAdminRole(loggedRole)) && user.roleEnum === TECHNICIAN_ROLE) {
      return handleTechToggle(user)
    }
    return null
  }

  const handleRoleForIcon = (user) => {
    if ((isEngineeringAdminRole(loggedRole) || isUserAdminRole(loggedRole)) && user.roleEnum === HO_ROLE) {
      return (
        <CustomIcon
          icon={user.dataPrivate ? ICON_SHOW : ICON_HIDE}
          className={classNames(classes.icon, classes.iconPadding)}
          tooltipText={formatMessage({ id: user.dataPrivate ? 'Commons.button.show' : 'Commons.button.hide' })}
        />
      )
    }
    if ((isEngineeringAdminRole(loggedRole) || isUserAdminRole(loggedRole)) && user.roleEnum === TECHNICIAN_ROLE) {
      return (
        <CustomIcon
          icon={ICON_UPGRADE}
          className={classNames(classes.icon, classes.iconPadding)}
          tooltipText={formatMessage({ id: 'Commons.actions.upgrade' })}
        />
      )
    }
    return ''
  }

  const actions = [
    {
      label: formatMessage({ id: 'Commons.actions.edit' }),
      onClick: handleEdit,
      className: classes.enabledButton,
      disabled: (user) =>
        ((isEngineeringAdminRole(loggedRole) || isUserAdminRole(loggedRole)) &&
          user.roleEnum === HO_ROLE &&
          user.dataPrivate) ||
        (isUserAdminRole(loggedRole) &&
          (user.roleEnum === ENGINEERING_ADMIN_ROLE || user.roleEnum === USER_ADMIN_ROLE)) ||
        isAdminRole(loggedRole) ||
        isRepRole(loggedRole)
    },
    {
      label: (
        <CustomIcon
          icon={ICON_ENABLED}
          className={classNames(classes.icon, classes.iconPadding)}
          tooltipText={formatMessage({ id: 'Commons.button.disable' })}
        />
      ),
      onClick: handleDisable,
      className: classes.enabledButton,
      disabled: (user) =>
        (isUserAdminRole(loggedRole) &&
          (user.roleEnum === ENGINEERING_ADMIN_ROLE || user.roleEnum === USER_ADMIN_ROLE)) ||
        isAdminRole(loggedRole) ||
        isRepRole(loggedRole)
    },
    {
      label: (
        <CustomIcon
          icon={ICON_RESEND}
          className={classNames(classes.icon, classes.iconPadding)}
          tooltipText={formatMessage({ id: 'Commons.button.send' })}
        />
      ),
      onClick: handleSend,
      className: classes.enabledButton,
      disabled: () => isRepRole(loggedRole)
    },
    {
      label: handleRoleForIcon,
      onClick: handleRoleForOnClick,
      className: classes.enabledButton
    }
  ]

  return (
    <>
      <UsersTab
        actions={actions}
        users={users}
        loading={loading}
        loadingMore={loadingMore}
        loadMore={loadMore}
        error={error}
        last={last}
        parameters={parameters}
        handleSort={handleSort}
      />
    </>
  )
}

export default PendingUsersTab
