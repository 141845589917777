import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  buttonContainer: {
    height: '100%',
    padding: '6px 8px',
    borderRadius: 8,
    border: `2px solid ${theme.palette.web.blue}`,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    display: 'inline-flex',
    cursor: 'pointer'
  },
  blueIcon: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.blue
    }
  },
  sorting: {
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    display: 'flex'
  },
  label: {
    color: theme.palette.web.blue,
    fontSize: 14,
    fontWeight: 600,
    wordWrap: 'break-word'
  },
  menu: {
    minWidth: 170,
    marginTop: 5,
    borderRadius: 6,
    border: '1px solid #BEBEBE',
    background: 'rgba(255, 255, 255, 0.70)',
    backdropFilter: 'blur(40px)',
    padding: '10px 0px'
  }
}))
