import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useIntl } from 'react-intl'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { Button } from '@mui/material'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomNumberInput from 'components/CustomNumberInput/CustomNumberInput'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import { usePartnersContext } from 'context/PartnersContext'
import { ENROLLED_AUDIENCE_FORM_STEP_ID, ENROLLED_AUDIENCE_REPORT_STEP_ID } from 'data/ReportsData'
import useStyles from './styles'

const EnrolledAudienceFormConfirmationStep = forwardRef(
  (
    { step, changeStep = () => undefined, showButton = () => undefined, reportForm, userStatuses, representatives },
    ref
  ) => {
    const classes = useStyles()
    const { getPartners } = usePartnersContext()
    const [partners, setPartners] = useState([])
    const { formatMessage } = useIntl()

    useEffect(() => {
      async function fetchPartners() {
        return getPartners()
      }

      fetchPartners().then((response) => {
        setPartners(response)
      })
    }, [getPartners])

    const runReport = () => {
      changeStep(ENROLLED_AUDIENCE_REPORT_STEP_ID)
      showButton(true)
      reportForm.setFieldValue('completed', true)
    }

    const back = () => {
      changeStep(ENROLLED_AUDIENCE_FORM_STEP_ID)
    }

    const reset = () => {}

    useImperativeHandle(ref, () => ({
      reset
    }))

    return (
      <React.Fragment key={step}>
        <CustomForm form={reportForm} className={classes.formContainer}>
          <div className={classes.gridContainer}>
            <div className={classes.filtersContainer}>
              <div className={classes.body}>
                <CustomSelect
                  form={reportForm}
                  formFieldName={'statuses'}
                  labelId={'Reports.form.statuses'}
                  items={userStatuses}
                  disabled={true}
                />
                <CustomSelect
                  form={reportForm}
                  formFieldName={'representative'}
                  labelId={'Reports.form.representative'}
                  items={representatives}
                  disabled={true}
                />
                <CustomNumberInput
                  type={'number'}
                  form={reportForm}
                  formFieldName={'boilersAssigned'}
                  labelId={'Reports.form.boilersAssigned'}
                  disabled={true}
                />
              </div>
            </div>
            <div className={classes.resultsContainer}>
              <div className={classes.filtersRow}>
                <div className={classes.rightHeaders}>
                  <NavbarCheckboxes
                    values={reportForm.values.filters ? reportForm.values.filters.partners : []}
                    checkboxes={partners}
                    groupLabel={formatMessage({ id: 'Reports.form.brands' })}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bottom}>
            <Button onClick={back} className={classes.transitionButton}>
              <KeyboardArrowLeft /> {formatMessage({ id: 'Reports.actions.back' })}
            </Button>
            <Button onClick={runReport} className={classes.primaryButton}>
              {formatMessage({ id: 'Reports.actions.run' })}
            </Button>
          </div>
        </CustomForm>
      </React.Fragment>
    )
  }
)

export default EnrolledAudienceFormConfirmationStep
