import React from 'react'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import useStyles from './styles'

const BrandsChipsList = ({ values, onChange = () => {}, chips }) => {
  const classes = useStyles()

  const isSelected = (brand) => values.includes(brand.enumName)

  return (
    <div className={classes.chipsContainer}>
      {chips
        .filter((brand) => isSelected(brand))
        .map((brand) => (
          <Chip
            disabled={false}
            key={brand.id.toString()}
            size={'small'}
            label={brand.name}
            className={classes.chip}
            deleteIcon={<CloseIcon className={classes.deleteIcon} />}
            onDelete={() => onChange(brand.id)}
          />
        ))}
    </div>
  )
}

export default BrandsChipsList
