import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import FieldWithCopyToClipboard from 'components/FieldWithCopyToClipboard/FieldWithCopyToClipboard'
import useStyles from './styles'

const InvitationForm = ({ handleClose, inviteLink }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <div>
          <FieldWithCopyToClipboard labelId={'Users.invitation.form.link'} value={inviteLink} />
        </div>
      </div>
      <div className={classes.actions}>
        <div className={classes.backButton} onClick={handleClose}>
          <Typography>
            <FormattedMessage id={'Commons.button.close'} />
          </Typography>
        </div>
      </div>
    </div>
  )
}

export default InvitationForm
