import React, { useEffect, useState } from 'react'
import InvitationForm from 'components/InvitationForm/InvitationForm'
import Loader from 'components/Loader/Loader'
import Modal from 'components/Modal/Modal'
import useStyles from './styles'

const InvitationModal = ({ open = false, handleClose, title, inviteLink }) => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    if (inviteLink) {
      setIsLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inviteLink])

  return (
    <Modal open={open} handleClose={handleClose} title={title} fullWidth={true} classNameModal={classes.modal}>
      {isLoading ? <Loader /> : <InvitationForm handleClose={handleClose} inviteLink={inviteLink} />}
    </Modal>
  )
}

export default InvitationModal
