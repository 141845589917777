import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    padding: '24px 33px 0px 33px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  containerHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dataContainer: {
    flex: 1,
    width: '100%',
    marginTop: 25,
    padding: '24px 48px',
    borderRadius: 8,
    boxShadow: `0 2px 4px 0 ${theme.palette.web.shadowGrey}`,
    backgroundColor: theme.palette.web.white
  },
  button: {
    height: 32,
    padding: '8px 39.5px 7px 38.5px',
    backgroundColor: theme.palette.web.blue,
    borderRadius: 6,
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.palette.web.white,
    textTransform: 'none',
    float: 'right',
    '&:hover': {
      backgroundColor: theme.palette.web.blue
    },
    '&:disabled': {
      color: theme.palette.web.white,
      opacity: 0.5
    }
  },
  wrapText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  table: {
    tableLayout: 'fixed'
  },
  smallWidth: {
    width: '15%'
  },
  mediumWidth: {
    width: '40%'
  }
}))
