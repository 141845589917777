import React from 'react'
import { useIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import MenuPopper from 'components/MenuPopper/MenuPopper'
import DROPDOWN_IMAGE from 'assets/images/icons-dropdown.svg'
import SORTING_IMAGE from 'assets/images/icons-sorting.svg'
import useStyles from './styles'

const SortingButton = ({ children }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <div className={classes.buttonContainer} ref={anchorRef} onClick={() => handleToggle()}>
      <div className={classes.sorting}>
        <ReactSVG src={SORTING_IMAGE} className={classes.blueIcon} />
        <div className={classes.label}>{formatMessage({ id: 'Sorting.sortBy' })}</div>
      </div>
      <ReactSVG src={DROPDOWN_IMAGE} className={classes.blueIcon} />
      <MenuPopper
        open={open}
        anchorRef={anchorRef}
        handleClose={handleClose}
        className={classes.menu}
        placement={'bottom-start'}
      >
        {children}
      </MenuPopper>
    </div>
  )
}

export default SortingButton
