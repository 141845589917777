import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '24px 33px 0px 33px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 25px 0px 25px'
    }
  },
  statusContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dot: {
    marginRight: 4,
    [theme.breakpoints.up('sm')]: {
      width: 18,
      height: 18
    },
    [theme.breakpoints.down('sm')]: {
      width: 14,
      height: 14
    }
  },
  connectionText: {
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    [theme.breakpoints.up('sm')]: {
      fontSize: 14
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  headerContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      marginTop: 12
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  mobileImage: {
    width: 72,
    height: 72,
    backgroundColor: theme.palette.web.white,
    display: 'flex',
    justifyContent: 'center'
  },
  mobileBoilerName: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  mobileBoilerStatus: {
    width: '100%',
    marginLeft: 16,
    padding: '5px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  arrowBack: {
    color: theme.palette.web.usbGrey,
    cursor: 'pointer',
    marginRight: 20
  },
  boilerModel: {
    fontWeight: 'bold',
    color: theme.palette.web.darkGrey,
    [theme.breakpoints.up('sm')]: {
      fontSize: 32
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      marginBottom: 5
    }
  },
  badge: {
    padding: '4.5px 25px',
    borderRadius: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 12,
      width: '100%'
    }
  },
  badgeRed: {
    color: theme.palette.web.darkRed,
    backgroundColor: theme.palette.web.lightRed
  },
  badgeGreen: {
    color: theme.palette.web.green,
    backgroundColor: theme.palette.web.lightGreen
  },
  errorIcon: {
    fontSize: 0,
    marginRight: 8,
    '& svg': {
      fill: theme.palette.web.darkRed
    }
  },
  status: {
    fontSize: 14,
    fontWeight: 800,
    marginRight: 8
  },
  message: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.17,
    paddingTop: 4
  },
  dataContainer: {
    marginTop: 16,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'grid',
      gridGap: '36px',
      gridTemplateColumns: 'repeat(auto-fit, minmax(542px, auto))'
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column-reverse'
    }
  },
  tablesContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  }
}))
