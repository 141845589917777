import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const LinkedTitle = ({ title, to }) => {
  const classes = useStyles()

  const scrollToTitle = () =>
    document.getElementById(to).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })

  return (
    <Typography onClick={scrollToTitle} className={classes.link}>
      <FormattedMessage id={title} />
    </Typography>
  )
}

export default LinkedTitle
