import useSWR from 'swr'
import React, { useState, useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import { Card, CardMedia, Checkbox, CardContent, Grid, FormControlLabel, Button } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { Delete } from '@material-ui/icons'
import Error from 'components/Error/Error'
import Loader from 'components/Loader/Loader'
import NoData from 'components/NoData/NoData'
import useCallService from 'services/useCallService'
import { DELETE_FILE_URL, GET_PHOTOS_URL } from 'Constants'
import DetailedPhotoView from './DetailedPhotoView'
import useStyles from './styles'

const PhotosTab = ({ boilerId }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callFileStorageService } = useCallService()
  const [selectedPhotos, setSelectedPhotos] = useState({})
  const [detailedPhotoViewActive, setDetailedPhotoView] = useState(false)
  const [activePhotoViewIndex, setActivePhotoViewIndex] = useState(null)

  const { enqueueSnackbar } = useSnackbar()

  const fetcher = (url) => callFileStorageService('GET', url, {}, true).then((response) => response.data)

  const { data, error, mutate } = useSWR(`${GET_PHOTOS_URL}?boilerId=${boilerId}`, fetcher)

  useEffect(() => {
    if (data) {
      const photoSelection = data.reduce(
        (acc, photo) => ({
          ...acc,
          [photo.id]: false
        }),
        {}
      )
      setSelectedPhotos(photoSelection)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const selectionState = useMemo(() => {
    const photosCount = Object.keys(selectedPhotos).length
    const selectionCount = Object.values(selectedPhotos).reduce((acc, isSelected) => {
      if (isSelected) return acc + 1

      return acc
    }, 0)

    if (selectionCount === photosCount) return 'ALL'

    if (selectionCount > 0) return 'PARTIAL'

    return 'NONE'
  }, [selectedPhotos])

  const selectAllPhotos = () =>
    setSelectedPhotos((currentSelection) =>
      Object.keys(currentSelection).reduce(
        (acc, photoId) => ({
          ...acc,
          [photoId]: true
        }),
        {}
      )
    )

  const deselectAll = () =>
    setSelectedPhotos((currentSelection) =>
      Object.keys(currentSelection).reduce(
        (acc, photoId) => ({
          ...acc,
          [photoId]: false
        }),
        {}
      )
    )

  const handleSelectAll = () => {
    if (selectionState === 'PARTIAL') {
      return deselectAll()
    }

    if (selectionState === 'ALL') {
      return deselectAll()
    }

    if (selectionState === 'NONE') {
      return selectAllPhotos()
    }
  }

  const deletePhotos = (photosIds) => {
    photosIds.forEach((id) => callFileStorageService('DELETE', DELETE_FILE_URL + id, {}, true))
  }

  const handleMultipleDelete = async () => {
    const selectedPhotosIds = Object.entries(selectedPhotos).reduce((acc, [photoId, isSelected]) => {
      if (isSelected) return [...acc, photoId]

      return acc
    }, [])

    const photosAfterDelete = data.filter((photo) => !selectedPhotosIds.includes(photo.id))

    mutate(photosAfterDelete)

    try {
      await deletePhotos(selectedPhotosIds)
    } catch (e) {
      enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), { variant: 'error' })
    }

    mutate()
  }

  const handleSingleDelete = async (photoId) => {
    try {
      await deletePhotos([photoId])

      const newPhotoList = data.filter((photo) => photo.id !== photoId)

      mutate(newPhotoList)

      if (newPhotoList.length === 0) {
        setDetailedPhotoView(false)
      }
      // If last photo is removed, set index to last
      else if (activePhotoViewIndex === newPhotoList.length) {
        handlePreviousPhoto()
      }
    } catch (e) {
      enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), { variant: 'error' })
    }
  }

  const handleBack = () => {
    setDetailedPhotoView(false)
  }

  const handleSelectImage = (photoIndex) => {
    setActivePhotoViewIndex(photoIndex)
    setDetailedPhotoView(true)
  }

  const handleNextPhoto = () => setActivePhotoViewIndex((currentIndex) => currentIndex + 1)

  const handlePreviousPhoto = () => setActivePhotoViewIndex((currentIndex) => currentIndex - 1)

  const renderCard = (photo, index) => {
    const handleSelection = (e) => {
      setSelectedPhotos((currentSelection) => ({
        ...currentSelection,
        [photo.id]: e.target.checked
      }))
    }

    return (
      <Card>
        <div onClick={() => handleSelectImage(index)}>
          <CardMedia className={classes.img} component="img" image={photo.url} />
        </div>
        <CardContent className={classes.cardContent} classes={{ root: classes.cardRoot }}>
          <div>{moment(photo.createdDate).format('L LTS')}</div>
          <Checkbox
            checked={selectedPhotos[photo.id] || false}
            onChange={handleSelection}
            className={classes.checkbox}
          />
        </CardContent>
      </Card>
    )
  }

  // Error in request
  if (error) {
    return <Error />
  }
  // Loading
  if (!data) {
    return <Loader />
  }

  if (data.length === 0) {
    return <NoData />
  }

  if (data.length > 0 && detailedPhotoViewActive) {
    const activePhotoView = data[activePhotoViewIndex]

    return (
      <DetailedPhotoView
        onBack={handleBack}
        activePhoto={activePhotoView}
        onPreviousPhoto={activePhotoViewIndex === 0 ? null : handlePreviousPhoto}
        onNextPhoto={activePhotoViewIndex + 1 === data.length ? null : handleNextPhoto}
        onDelete={handleSingleDelete}
      />
    )
  }

  return (
    <div>
      <div className={classes.actionsContainer}>
        <FormControlLabel
          control={
            <Checkbox
              checked={selectionState === 'ALL'}
              onChange={handleSelectAll}
              className={classes.checkbox}
              indeterminate={selectionState === 'PARTIAL'}
              name="selectAll"
              color="primary"
            />
          }
          label={formatMessage({ id: 'BoilerStatus.photos.action.selectAll' })}
        />
        <div>
          <Button onClick={handleMultipleDelete} disabled={selectionState === 'NONE'} startIcon={<Delete />}>
            <Typography>{formatMessage({ id: 'BoilerStatus.photos.action.delete' })}</Typography>
          </Button>
        </div>
      </div>
      <Grid container spacing={4}>
        {data.map((photo, index) => (
          <Grid key={photo.id} item xs={12} xl={2} lg={2} sm={6} md={4}>
            {renderCard(photo, index)}
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default PhotosTab
