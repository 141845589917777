import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  successContainer: {
    minHeight: 350,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      minHeight: 250
    }
  },
  image: {
    marginBottom: 32,
    '& svg': {
      fill: theme.palette.web.green
    }
  },
  textSuccess: {
    maxWidth: 280,
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 1.44,
    letterSpacing: 0.24,
    textAlign: 'center',
    color: theme.palette.web.blueGrey,
    textTransform: 'uppercase'
  }
}))
