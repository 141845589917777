import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useIntl } from 'react-intl'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { Button } from '@mui/material'
import CustomChipsList from 'components/CustomChipsList/CustomChipsList'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import Loader from 'components/Loader/Loader'
import useCallService from 'services/useCallService'
import { ALARM_HISTORY_FORM_STEP_ID, ALARM_HISTORY_REPORT_STEP_ID } from 'data/ReportsData'
import { GET_SERIALS_URL } from 'Constants'
import useStyles from './styles'

const AlarmHistoryFormConfirmationStep = forwardRef(
  (
    {
      step,
      changeStep = () => undefined,
      needsToEval = false,
      setNeedsToEval = () => undefined,
      showButton = () => undefined,
      alarms,
      alarmTypes,
      reportForm
    },
    ref
  ) => {
    const classes = useStyles()
    const { formatMessage } = useIntl()
    const [isLoading, setIsLoading] = useState(true)
    const { callBoilerService } = useCallService()
    const parameters = reportForm.values.filters

    useEffect(() => {
      if (needsToEval) {
        loadSerials()
        setNeedsToEval(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [needsToEval])

    const loadSerials = () => {
      setIsLoading(true)

      async function fetchSerials() {
        return callBoilerService(
          'GET',
          `${GET_SERIALS_URL}?&partners=${parameters.partners
            .filter(Boolean)
            .join(',')}&types=${parameters.modelTypes.filter(Boolean).join(',')}${
            parameters.search ? `&searchString=${encodeURIComponent(parameters.search)}` : ''
          }`,
          {},
          true
        )
      }

      if (reportForm.values.allSelected === true) {
        if (parameters) {
          fetchSerials()
            .then((response) => {
              reportForm.setFieldValue('boilersFullList', response.data)
            })
            .finally(() => {
              setIsLoading(false)
            })
        }
      } else {
        reportForm.setFieldValue('boilersFullList', reportForm.values.boilers)
        setIsLoading(false)
      }
    }

    const runReport = () => {
      changeStep(ALARM_HISTORY_REPORT_STEP_ID)
      showButton(true)
      reportForm.setFieldValue('completed', true)
    }

    const back = () => {
      reportForm.setFieldValue('boilersFullList', [])
      changeStep(ALARM_HISTORY_FORM_STEP_ID)
    }

    const reset = () => {}

    useImperativeHandle(ref, () => ({
      reset
    }))

    return (
      <React.Fragment key={step}>
        <CustomForm form={reportForm} className={classes.formContainer}>
          <div className={classes.gridContainer}>
            <div className={classes.filtersContainer}>
              {isLoading && <Loader />}
              {!isLoading && (
                <div className={classes.body}>
                  <CustomSelect
                    form={reportForm}
                    formFieldName={'alarmType'}
                    labelId={'Reports.form.alarmType'}
                    items={alarmTypes}
                    disabled={true}
                  />
                  <CustomSelect
                    form={reportForm}
                    formFieldName={'alarm'}
                    labelId={'Reports.form.alarm'}
                    isMultiple={true}
                    items={alarms}
                    disabled={true}
                  />
                  <CustomChipsList
                    form={reportForm}
                    formFieldName={'boilersFullList'}
                    labelId={'Reports.form.boilers'}
                    offset={10}
                  />
                </div>
              )}
            </div>
            <div className={classes.resultsContainer} />
          </div>
          <div className={classes.bottom}>
            <Button onClick={back} className={classes.transitionButton}>
              <KeyboardArrowLeft /> {formatMessage({ id: 'Reports.actions.back' })}
            </Button>
            <Button onClick={runReport} className={classes.primaryButton}>
              {formatMessage({ id: 'Reports.actions.run' })}
            </Button>
          </div>
        </CustomForm>
      </React.Fragment>
    )
  }
)

export default AlarmHistoryFormConfirmationStep
