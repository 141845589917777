import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  link: {
    cursor: 'default',
    padding: 12
  },
  linkActive: {
    backgroundColor: theme.palette.sidebar.main,
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: theme.palette.sidebar.selectedBackground,
      '& span': {
        color: theme.palette.sidebar.selected,
        fontWeight: 600
      },
      '& svg': {
        fill: theme.palette.sidebar.selected
      }
    }
  },
  linkIcon: {
    marginRight: theme.spacing(1),
    transition: theme.transitions.create('color'),
    minWidth: 16,
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      fill: theme.palette.sidebar.selectedBackground
    }
  },
  linkIconNested: {
    marginRight: 0
  },
  linkText: {
    textDecoration: 'none',
    padding: 0,
    transition: theme.transitions.create(['opacity', 'color']),
    fontSize: 12
  },
  linkTextActive: {
    color: theme.palette.sidebar.primary
  },
  linkTextHidden: {
    opacity: 0
  },
  linkImage: {
    fontSize: 0
  }
}))
