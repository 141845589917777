import { createTheme } from '@material-ui/core/styles'

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 850,
      md: 850,
      lg: 1200
    }
  },
  props: {
    MuiButtonBase: {
      // Disables ripple (click effect on button) for the whole app
      disableRipple: true
    }
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backdropFilter: 'blur(10px)',
        backgroundColor: 'rgba(68, 68, 68, 0.67)'
      }
    },
    MuiIconButton: {
      root: {
        // Disables hover button effect
        '&:hover': {
          backgroundColor: 'transparent !important'
        }
      }
    }
  },
  palette: {
    web: {
      black: '#000000',
      white: '#ffffff',
      grey: '#f7f7f7',
      blueGrey: '#30383f',
      darkGrey: '#444444',
      disabled: 'rgba(0, 0, 0, 0.38)',
      shadowGrey: 'rgba(0, 0, 0, 0.22)',
      red: '#ee3a43',
      lightRed: 'rgba(209, 51, 59, 0.2)',
      transparentRed: 'rgba(238, 58, 67, 0.1)',
      darkRed: '#d1333b',
      green: '#67bc4e',
      lightGreen: 'rgba(103, 188, 78, 0.2)',
      transparentGreen: 'rgba(87, 165, 63, 0.1)',
      darkGreen: '#57a53f',
      transparentYellow: 'rgba(255, 148, 28, 0.1)',
      darkYellow: '#ff941c',
      blue: 'rgb(70, 113, 209)',
      lightBlue: 'rgb(70, 113, 209, 0.7)',
      transparentBlue: 'rgb(70, 113, 209, 0.2)',
      chipBlue: '#e7ecff',
      usbGrey: 'rgb(117, 123, 131)',
      transparentUsbGrey: 'rgb(117, 123, 131, 0.2)',
      lightGrey: '#bebebe',
      transparentGrey: '#cccccc',
      cardShadow: 'rgba(0, 0, 0, 0.1)',
      greyText: 'rgba(68, 68, 68, 0.8)',
      dropdownColor: 'rgba(255, 255, 255, 0.22)',
      greyRow: 'var(--shades-2, #EAEAEA)',
      mobileTabsBorder: 'var(--secondaryshade-1, rgba(87, 125, 255, 0.60))',
      mobileTabText: 'var(--shades-30-op, rgba(0, 0, 0, 0.30))'
    },
    header: {
      main: '#ffffff',
      icon: '#757b83',
      selected: '#d1333b',
      transparentRed: 'rgba(209, 51, 59, 0.2)',
      lightGrey: '#f7f7f7',
      darkGrey: '#444444'
    },
    background: {
      default: '#f7f7f7'
    },
    text: {
      primary: '#757b83'
    },
    sidebar: {
      main: '#757b83',
      primary: '#ffffff',
      selected: '#d1333b',
      selectedBackground: '#f7f7f7',
      hover: '#2161c1'
    }
  },
  typography: {
    fontFamily: ['Open Sans'].join(',')
  }
})
export default theme
