export const contentTable = [
  { id: 'title1', label: 'Privacy.panel.title1' },
  { id: 'title2', label: 'Privacy.panel.title2' },
  { id: 'title3', label: 'Privacy.panel.title3' },
  {
    id: 'title4',
    label: 'Privacy.panel.title4'
  },
  { id: 'title5', label: 'Privacy.panel.title5' },
  { id: 'title6', label: 'Privacy.panel.title6' },
  { id: 'title7', label: 'Privacy.panel.title7' },
  {
    id: 'title8',
    label: 'Privacy.panel.title8'
  },
  { id: 'title9', label: 'Privacy.panel.title9' },
  { id: 'title10', label: 'Privacy.panel.title10' },
  { id: 'title11', label: 'Privacy.panel.title11' },
  {
    id: 'title12',
    label: 'Privacy.panel.title12'
  },
  { id: 'title13', label: 'Privacy.panel.title13' }
]
