import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import FirmwarePanel from 'components/FirmwarePanel/FirmwarePanel'
import Layout from 'components/Layout/Layout'

const Firmware = () => {
  const title = useIntl().formatMessage({ id: 'Firmware.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout>
        <FirmwarePanel />
      </Layout>
    </>
  )
}

export default Firmware
