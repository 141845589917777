import React from 'react'
import { ReactSVG } from 'react-svg'
import IconButton from '@material-ui/core/IconButton'
import useStyles from 'components/Snackbar/styles'

const VariantIcon = ({ icon }) => {
  const classes = useStyles()

  return (
    <IconButton className={classes.icon}>
      <ReactSVG src={icon} />
    </IconButton>
  )
}

export default VariantIcon
