import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Chip from '@material-ui/core/Chip'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const CustomChipsList = ({ form, formFieldName, labelId, offset = 0 }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const renderChips = (list) =>
    list.map((boiler) => (
      <Chip disabled={true} key={boiler.id} size={'small'} label={boiler.serialNumber} className={classes.chip} />
    ))

  const renderShortChips = (list) => (
    <>
      {renderChips(list)}
      {form.values[formFieldName].length - offset > 0 && (
        <Chip
          disabled={true}
          key={'plus'}
          size={'small'}
          label={formatMessage({ id: 'CustomChipsList.label.plus' }, { 0: form.values[formFieldName].length - offset })}
          className={classes.chip}
        />
      )}
    </>
  )

  return (
    <div className={classes.container}>
      <Typography className={classes.label}>
        <FormattedMessage id={labelId} />
      </Typography>
      <div className={classes.chipsContainer}>
        {offset > 0
          ? renderShortChips(form.values[formFieldName].slice(0, offset))
          : renderChips(form.values[formFieldName])}
      </div>
    </div>
  )
}

export default CustomChipsList
