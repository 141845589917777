import React, { useState } from 'react'
import { ReactSVG } from 'react-svg'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import ICON_VISIBILITY_OFF from 'assets/images/icons-visibility-off.svg'
import ICON_VISIBILITY_ON from 'assets/images/icons-visibility-on.svg'

const PasswordTextField = ({
  form,
  formFieldName,
  labelId,
  serviceError,
  labelClasses,
  inputClasses,
  helperText = '',
  helperTextClasses = null
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  return (
    <CustomTextField
      form={form}
      formFieldName={formFieldName}
      labelId={labelId}
      serviceError={serviceError}
      type={showPassword ? 'text' : 'password'}
      labelClasses={labelClasses}
      inputClasses={inputClasses}
      helperText={helperText}
      helperTextClasses={helperTextClasses}
      inputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
              {showPassword ? <ReactSVG src={ICON_VISIBILITY_ON} /> : <ReactSVG src={ICON_VISIBILITY_OFF} />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default PasswordTextField
