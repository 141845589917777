import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import cookie from 'js-cookie'
import Typography from '@material-ui/core/Typography'
import CustomAutocomplete from 'components/CustomAutocomplete/CustomAutocomplete'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomCheckbox from 'components/CustomCheckbox/CustomCheckbox'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import FieldError from 'components/FieldError/FieldError'
import useCallService from 'services/useCallService'
import { CONTRACTOR_ROLE, REP_ROLE, SAVE_OR_UPDATE_USER_URL, TECHNICIAN_ROLE, USER_DATA } from 'Constants'
import useStyles from './styles'

const UserForm = ({ handleClose, onSuccess, userToEdit, roles, brands, representatives, companies }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callSecurityService } = useCallService()
  const [serviceError, setServiceError] = useState(false)
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')
  const userData = JSON.parse(cookie.get(USER_DATA))

  yup.addMethod(yup.array, 'keepExistingBrands', function (errorMessage) {
    return this.test('keep-existing-brands', formatMessage({ id: 'Commons.error.brand.keep' }), function (value) {
      const { path, createError } = this
      if (userToEdit) {
        const currentElements = userToEdit.brands
        return currentElements.every((v) => value.includes(v)) || createError({ path, message: errorMessage })
      }

      return true
    })
  })

  const authValidationSchema = yup.object({
    firstName: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    lastName: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    role: yup.string().required(formatMessage({ id: 'Commons.error.required' })),
    company: yup.object().when('role', {
      is: () =>
        (userData.role === CONTRACTOR_ROLE && userToEdit !== null) ||
        (userData.role !== CONTRACTOR_ROLE &&
          (authForm.values.role === CONTRACTOR_ROLE || authForm.values.role === TECHNICIAN_ROLE)),
      then: yup
        .object()
        .shape({
          id: yup.number().required(formatMessage({ id: 'Commons.error.required' })),
          name: yup.string().required(formatMessage({ id: 'Commons.error.required' }))
        })
        .nullable()
        .required(formatMessage({ id: 'Commons.error.required' })),
      otherwise: yup.object().nullable()
    }),
    phoneNumber: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    emailAddress: yup
      .string()
      .trim()
      .email(formatMessage({ id: 'Commons.error.invalidEmail' }))
      .required(formatMessage({ id: 'Commons.error.required' })),
    address: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    city: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    state: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    zipCode: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    brands: yup
      .array()
      .min(1, formatMessage({ id: 'Commons.error.required' }))
      .keepExistingBrands(formatMessage({ id: 'Commons.error.brand.keep' })),
    assignRepresentative: yup.number().when('role', {
      is: (value) => value === CONTRACTOR_ROLE,
      then: yup.number().required(formatMessage({ id: 'Commons.error.required' })),
      otherwise: yup.number()
    }),
    notifyEmail: yup.boolean(),
    notifySMS: yup.boolean()
  })

  const authForm = useFormik({
    initialValues: {
      firstName: userToEdit ? userToEdit.firstName : '',
      lastName: userToEdit ? userToEdit.lastName : '',
      role: userToEdit ? userToEdit.role : '',
      company: userToEdit ? userToEdit.company : null,
      phoneNumber: userToEdit ? userToEdit.phoneNumber : '',
      emailAddress: userToEdit ? userToEdit.emailAddress.toLowerCase() : '',
      address: userToEdit ? userToEdit.address : '',
      city: userToEdit ? userToEdit.city : '',
      state: userToEdit ? userToEdit.state : '',
      zipCode: userToEdit ? userToEdit.zipCode : '',
      brands: userToEdit ? userToEdit.brands : [],
      assignRepresentative: userToEdit ? userToEdit.assignRepresentative : '',
      notifyEmail: userToEdit ? userToEdit.notifyEmail : false,
      notifySMS: userToEdit ? userToEdit.notifySMS : false
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      setServiceError(false)
      setServiceErrorMessage('')
      const roleMap = Object.create(null)
      roleMap[values.role] = ['READ', 'WRITE']
      const saveOrUpdateRequest = {
        companyId: values.company != null ? values.company.id : null,
        geofence: {
          address: values.address,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode
        },
        partnerNames: values.brands,
        partnerName: process.env.REACT_APP_PARTNER_NAME,
        representativeId: values.assignRepresentative,
        roles: roleMap,
        user: {
          email: values.emailAddress,
          phoneNumber: values.phoneNumber
        },
        userHasPartner: {
          firstName: values.firstName,
          lastName: values.lastName
        },
        userPreference: {
          emailNotification: values.notifyEmail,
          smsNotification: values.notifySMS
        }
      }
      try {
        await callSecurityService('POST', SAVE_OR_UPDATE_USER_URL, { data: saveOrUpdateRequest }, true)
        onSuccess()
        handleClose()
      } catch (error) {
        setServiceError(true)
        if (error.response && error.response.data.message) {
          setServiceErrorMessage(error.response.data.message)
        } else {
          setServiceErrorMessage(formatMessage({ id: 'Commons.genericError' }))
        }
      }
    }
  })

  return (
    <div className={classes.container}>
      <CustomForm form={authForm}>
        <div className={classes.body}>
          <CustomTextField
            form={authForm}
            formFieldName={'firstName'}
            labelId={authForm.values.role === REP_ROLE ? 'Users.form.company' : 'Users.form.firstName'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'lastName'}
            labelId={authForm.values.role === REP_ROLE ? 'Users.form.name' : 'Users.form.lastName'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
          />
          <CustomSelect
            form={authForm}
            formFieldName={'role'}
            labelId={'Users.form.role'}
            serviceError={serviceError}
            items={
              !userToEdit ? roles.filter((r) => r.value !== CONTRACTOR_ROLE && r.value !== TECHNICIAN_ROLE) : roles
            }
            disabled={userToEdit !== null}
          />
          {((userData.role === CONTRACTOR_ROLE && userToEdit !== null) ||
            (userData.role !== CONTRACTOR_ROLE &&
              (authForm.values.role === CONTRACTOR_ROLE || authForm.values.role === TECHNICIAN_ROLE))) && (
            <CustomAutocomplete
              form={authForm}
              formFieldName={'company'}
              elemName={'name'}
              labelId={'Users.form.company'}
              serviceError={serviceError}
              helperTextClasses={classes.helperTextPosition}
              labelClasses={{ formControl: classes.label }}
              inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
              options={companies != null && companies.length > 0 ? companies : []}
              disabled={
                userData.role === CONTRACTOR_ROLE && authForm.values.role === TECHNICIAN_ROLE && userToEdit !== null
              }
            />
          )}
          <CustomTextField
            form={authForm}
            formFieldName={'phoneNumber'}
            labelId={'Users.form.phoneNumber'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'emailAddress'}
            labelId={'Users.form.emailAddress'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
            disabled={userToEdit !== null}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'address'}
            labelId={'Users.form.address'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'city'}
            labelId={'Users.form.city'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'state'}
            labelId={'Users.form.state'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'zipCode'}
            labelId={'Users.form.zipCode'}
            serviceError={serviceError}
            helperTextClasses={classes.helperTextPosition}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
          />
          <CustomSelect
            form={authForm}
            formFieldName={'brands'}
            labelId={'Users.form.brands'}
            serviceError={serviceError}
            isMultiple={true}
            items={brands}
          />
          {authForm.values.role === CONTRACTOR_ROLE && (
            <CustomSelect
              form={authForm}
              formFieldName={'assignRepresentative'}
              labelId={'Users.form.assignRepresentative'}
              serviceError={serviceError}
              items={representatives}
            />
          )}
          <div
            className={classNames(classes.notificationsBox, {
              [classes.notificationsJustify]:
                (userData.role !== CONTRACTOR_ROLE && authForm.values.role === TECHNICIAN_ROLE) ||
                (userData.role === CONTRACTOR_ROLE && userToEdit !== null && authForm.values.role === TECHNICIAN_ROLE)
            })}
          >
            <Typography className={classes.notificationLabel}>
              <FormattedMessage id={'Users.form.notificationLabel'} />
            </Typography>
            <div>
              <CustomCheckbox form={authForm} formFieldName={'notifyEmail'} labelId={'Users.form.emailLabel'} />
              <CustomCheckbox form={authForm} formFieldName={'notifySMS'} labelId={'Users.form.smsLabel'} />
            </div>
          </div>
        </div>
        <FieldError hasError={serviceError} errorMessage={serviceErrorMessage} />
        <div className={classes.actions}>
          <div className={classes.backButton} onClick={handleClose}>
            <Typography>
              <FormattedMessage id={'Commons.button.cancel'} />
            </Typography>
          </div>
          <CustomButton labelId={'Commons.button.save'} type={'submit'} />
        </div>
      </CustomForm>
    </div>
  )
}

export default UserForm
