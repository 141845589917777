import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import { TextField } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import ICON_COPY from 'assets/images/icons-copy-to-clipboard.svg'
import useStyles from './styles'

const FieldWithCopyToClipboard = ({ labelId, value = '' }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()

  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
    enqueueSnackbar(formatMessage({ id: 'Commons.actions.copied' }), { variant: 'success' })
  }

  return (
    <div className={classes.readOnlyTextField}>
      <Typography className={classes.label}>
        <FormattedMessage id={labelId} />
      </Typography>
      <TextField
        InputLabelProps={{
          disableAnimation: true,
          shrink: true
        }}
        InputProps={{
          disableUnderline: true,
          classes: {
            formControl: classes.formControl,
            root: classNames(classes.root, classes.textareaHeight),
            input: classNames(classes.input, classes.textarea)
          }
        }}
        value={value}
        margin="normal"
        variant={'standard'}
        type={'text'}
        fullWidth
        autoComplete={false}
        disabled={true}
        multiline={true}
        minRows={8}
        maxRows={10}
      />
      <CustomIcon
        icon={ICON_COPY}
        className={classNames(classes.icon, classes.iconPadding)}
        onClick={() => {
          handleCopyToClipboard(value)
        }}
        tooltipText={formatMessage({ id: 'Commons.actions.copyToClipboard' })}
      />
    </div>
  )
}

export default FieldWithCopyToClipboard
