import React, { useRef } from 'react'
import { ReactSVG } from 'react-svg'
import { Tooltip } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import useStyles from './styles'

const CustomIcon = ({
  icon,
  onClick = () => undefined,
  onMouseDown = () => undefined,
  styles,
  className,
  anchorRef,
  tooltipText
}) => {
  const classes = useStyles()
  const localRef = useRef(null)

  const renderIconButton = () => (
    <IconButton
      ref={anchorRef || localRef}
      classes={styles}
      className={className || classes.headerMenuButton}
      onClick={() => onClick()}
      onMouseDown={() => onMouseDown()}
    >
      <ReactSVG src={icon} />
    </IconButton>
  )

  return <>{tooltipText ? <Tooltip title={tooltipText}>{renderIconButton()}</Tooltip> : renderIconButton()}</>
}

export default CustomIcon
