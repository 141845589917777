import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  label: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.web.darkGrey
  },
  root: {
    color: theme.palette.web.lightGrey
  },
  checked: {
    color: theme.palette.web.blue
  }
}))
