import React from 'react'
import GENERIC_BOILER from 'assets/images/images-boiler-icon.svg'

const BoilerImage = ({ imageUrl, height, genericHeight }) =>
  imageUrl ? (
    <img src={imageUrl} height={height} alt="boiler_image" />
  ) : (
    <img src={GENERIC_BOILER} height={genericHeight} alt="generic_boiler_image" />
  )

export default BoilerImage
