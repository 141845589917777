import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import CloseIcon from '@material-ui/icons/Close'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import useStyles from './styles'

const CustomSelect = ({
  form,
  formFieldName,
  labelClasses,
  inputClasses,
  labelId,
  inputProps = null,
  inputVariant = 'standard',
  autoComplete = 'off',
  required = false,
  disabled = false,
  serviceError = false,
  items,
  isMultiple = false
}) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseChip = (key) => {
    form.setFieldValue(
      formFieldName,
      form.values[formFieldName].filter((value) => value !== key)
    )
  }

  const closeAllChips = () => {
    form.setFieldValue(formFieldName, [])
  }

  const returnChip = (value) => {
    const selectedItem = items.find((item) => item.value === value)
    return (
      <Chip
        disabled={disabled}
        key={selectedItem.value}
        size={'small'}
        label={selectedItem.label}
        onMouseDown={(e) => e.stopPropagation()}
        onDelete={() => handleCloseChip(selectedItem.value)}
        className={classes.chip}
        deleteIcon={!disabled ? <CloseIcon onClick={() => handleCloseChip(selectedItem.value)} /> : <></>}
      />
    )
  }

  const renderChips = (selected) => <div className={classes.chips}>{selected.map((value) => returnChip(value))}</div>

  const renderSimpleValue = (selected) =>
    items.find((item) => item.value === selected) ? items.find((item) => item.value === selected).label : ''

  const renderValue = (selected) => (isMultiple ? renderChips(selected) : renderSimpleValue(selected))

  return (
    <TextField
      select
      SelectProps={{
        endAdornment:
          isMultiple && !disabled ? (
            <CloseIcon
              onClick={disabled ? undefined : closeAllChips}
              className={classNames(classes.deleteChips, { [classes.pointer]: !disabled })}
            />
          ) : (
            ''
          ),
        MenuProps: {
          classes: {
            paper: classes.paper
          }
        },
        open,
        onOpen: () => handleOpen(),
        onClose: () => handleClose(),
        IconComponent: (props) => (
          <KeyboardArrowDownIcon
            {...props}
            className={classNames(classes.icon, { [classes.pointer]: !disabled })}
            onClick={disabled ? undefined : handleOpen}
            onMouseDown={disabled ? undefined : handleOpen}
          />
        ),
        multiple: isMultiple,
        classes: {
          select: classes.select
        },
        renderValue: (selected) => renderValue(selected)
      }}
      FormHelperTextProps={{
        className: classes.errorMessage
      }}
      InputLabelProps={{
        disableAnimation: true,
        shrink: true,
        classes: {
          shrink: classes.shrink,
          formControl: classes.label,
          ...labelClasses
        }
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          root: classes.root,
          input: classes.input,
          ...inputClasses
        },
        ...inputProps
      }}
      id={formFieldName}
      name={formFieldName}
      value={form.values[formFieldName]}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
      error={(form.touched[formFieldName] && Boolean(form.errors[formFieldName])) || serviceError}
      helperText={form.touched[formFieldName] && form.errors[formFieldName]}
      label={useIntl().formatMessage({ id: labelId })}
      variant={inputVariant}
      fullWidth
      margin="normal"
      autoComplete={autoComplete}
      required={required}
      disabled={disabled}
    >
      {items.map((item) => (
        <MenuItem key={item.value} value={item.value} className={classes.menuItem}>
          {isMultiple && (
            <Checkbox
              icon={<CheckBoxOutlineBlankOutlinedIcon />}
              checkedIcon={<CheckBoxOutlinedIcon className={classes.checked} />}
              checked={form.values[formFieldName].indexOf(item.value) > -1}
              className={classes.checkbox}
              disableRipple
            />
          )}
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default CustomSelect
