import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import Loader from 'components/Loader/Loader'
import useStyles from './styles'

const LoadMore = ({ loadMore = () => undefined, className, isLoading = false }) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.loadMore, className)}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={classes.line} />
          <Typography onClick={loadMore} className={classes.loadMoreText}>
            <FormattedMessage id={'Dashboard.panel.loadMore'} />
          </Typography>
          <div className={classes.line} />
        </>
      )}
    </div>
  )
}

export default LoadMore
