import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  chipsContainer: {
    flex: 1,
    width: '100%',
    display: 'grid',
    gridGap: '2px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(68px, 114px))',
    alignItems: 'center',
    marginTop: 20
  },
  chip: {
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: '-0.34px',
    backgroundColor: theme.palette.web.chipBlue,
    color: theme.palette.web.blue,
    borderRadius: 4,
    margin: 2,
    height: 36,
    maxWidth: 129,
    '& svg': {
      width: 14,
      height: 14,
      fill: theme.palette.web.blue
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.web.chipBlue
    },
    '&.Mui-disabled': {
      opacity: '1'
    }
  },
  deleteIcon: {
    width: 13,
    height: 13,
    color: theme.palette.web.blue,
    '&:hover': {
      color: theme.palette.web.blue
    }
  }
}))
