import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import cookie from 'js-cookie'
import { useSnackbar } from 'notistack'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import InvitationModal from 'components/InvitationModal/InvitationModal'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import PageTitle from 'components/PageTitle/PageTitle'
import SearchBar from 'components/SearchBar/SearchBar'
import TabPanel from 'components/TabPanel/TabPanel'
import TechnicianModal from 'components/TechnicianModal/TechnicianModal'
import UserModal from 'components/UserModal/UserModal'
import DisabledUsersTab from 'components/UsersTab/DisabledUsersTab/DisabledUsersTab'
import EnabledUsersTab from 'components/UsersTab/EnabledUsersTab/EnabledUsersTab'
import PendingUsersTab from 'components/UsersTab/PendingUsersTab/PendingUsersTab'
import useCallService from 'services/useCallService'
import { hasPermission, useDebounce } from 'utils/Utils'
import { usePartnersContext } from 'context/PartnersContext'
import ICON_ADD from 'assets/images/icons-add.svg'
import {
  ADD_USERS_PERMISSION,
  ADMIN_ROLE,
  CHANGE_STATUS_USER_URL,
  ENGINEERING_ADMIN_ROLE,
  GET_COMPANIES_URL,
  GET_REPRESENTATIVES_URL,
  GET_ROLES_URL,
  GET_USERS_URL,
  PENDING_USERS_PERMISSION,
  SELECTED_PARTNERS,
  SEND_INVITE_URL,
  TOGGLE_HO_DATA,
  USER_ADMIN_ROLE,
  USER_DATA
} from 'Constants'
import useStyles from './styles'

const UsersPanel = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { getPartners } = usePartnersContext()
  const { callSecurityService } = useCallService()
  const pendingUsersAllowed = hasPermission(PENDING_USERS_PERMISSION)
  const addUsersAllowed = hasPermission(ADD_USERS_PERMISSION)
  const [tab, setTab] = React.useState(pendingUsersAllowed ? 'PENDING' : 'ENABLED')
  const [openModal, setOpenModal] = React.useState(false)
  const [openTechModal, setOpenTechModal] = React.useState(false)
  const [modalTitle, setModalTitle] = React.useState('')
  const [modalTechTitle, setModalTechTitle] = React.useState('')
  const [userToEdit, setUserToEdit] = React.useState(null)
  const [techId, setTechId] = React.useState(null)
  const [openInviteModal, setOpenInviteModal] = React.useState(false)
  const [modalInviteTitle, setModalInviteTitle] = React.useState('')
  const [inviteLink, setInviteLink] = React.useState(null)
  const [users, setUsers] = useState([])
  const [parameters, setParameters] = useState({
    search: '',
    page: 0,
    sortBy: 'username',
    sorting: 'ASC',
    partners: []
  })
  const pageSize = process.env.REACT_APP_DASHBOARD_PAGE_SIZE
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isError, setIsError] = useState(false)
  const [last, setLast] = useState(true)
  const { enqueueSnackbar } = useSnackbar()
  const [roleList, setRoleList] = useState([])
  const [partners, setPartners] = useState([])
  const [brandList, setBrandList] = useState([])
  const [representativeList, setRepresentativeList] = useState([])
  const [companiesList, setCompaniesList] = useState([])
  const userData = JSON.parse(cookie.get(USER_DATA))
  const showBrandFilter =
    userData.role === ADMIN_ROLE || userData.role === ENGINEERING_ADMIN_ROLE || userData.role === USER_ADMIN_ROLE

  const debouncedParameters = useDebounce(parameters, 500)

  useEffect(() => {
    async function fetchRoles() {
      return callSecurityService('GET', GET_ROLES_URL, {}, true)
    }

    async function fetchBrands() {
      return getPartners()
    }

    async function fetchRepresentatives() {
      return callSecurityService('GET', GET_REPRESENTATIVES_URL, {}, true)
    }

    async function fetchCompanies() {
      return callSecurityService('GET', GET_COMPANIES_URL, {}, true)
    }

    fetchBrands().then((response) => {
      setPartners(response)
      setBrandList(response.map((brand) => ({ value: brand.name, label: brand.name })))
      const selectedPartners = cookie.get(SELECTED_PARTNERS)
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page,
        sortBy: currentParameters.sortBy,
        sorting: currentParameters.sorting,
        partners: selectedPartners ? JSON.parse(selectedPartners) : response.map((el) => el.enumName)
      }))
    })

    if (addUsersAllowed) {
      fetchRoles().then((response) => {
        setRoleList(response.data.roles.map((role) => ({ value: role.name, label: role.prettyName })))
      })
      fetchRepresentatives().then((response) => {
        setRepresentativeList(response.data.map((rep) => ({ value: rep.id, label: rep.name })))
      })
      fetchCompanies().then((response) => {
        setCompaniesList(response.data.map((company) => ({ id: company.id, name: company.name })))
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParameters])

  useEffect(() => {
    setUsers([])
    setParameters((currentParameters) => ({
      search: '',
      page: 0,
      sortBy: 'username',
      sorting: 'ASC',
      partners: currentParameters.partners
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  const loadUsers = () => {
    async function fetchUsers() {
      setIsError(false)
      return callSecurityService(
        'GET',
        `${GET_USERS_URL}?userStatus=${tab}&size=${pageSize}&page=${parameters.page}&sortBy=${
          parameters.sortBy
        }&sorting=${parameters.sorting}${
          parameters.search ? `&searchString=${encodeURIComponent(parameters.search)}` : ''
        }&partners=${parameters.partners.filter(Boolean).join(',')}`,
        {},
        true
      )
    }

    if (partners.length !== 0) {
      fetchUsers()
        .then((response) => {
          setLast(response.data.last)
          if (parameters.page === 0) {
            setUsers(response.data.data)
          } else {
            setUsers(users.concat(response.data.data))
          }
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false)
          setIsLoadingMore(false)
        })
    }
  }

  const search = (e) => {
    setIsLoading(true)
    setParameters((currentParameters) => ({
      search: e.target.value,
      page: 0,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const loadMore = async () => {
    setIsLoadingMore(true)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: currentParameters.page + 1,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const handleSort = (order, orderBy) => {
    setIsLoading(true)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      sortBy: orderBy,
      sorting: order.toUpperCase(),
      partners: currentParameters.partners
    }))
  }

  const check = (e) => {
    setIsLoading(true)
    if (e.target.checked) {
      parameters.partners[e.target.id - 1] = e.target.value
    } else {
      parameters.partners[e.target.id - 1] = null
    }
    cookie.set(SELECTED_PARTNERS, parameters.partners)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const handleChangeTab = (event, newTab) => {
    setIsLoading(true)
    setTab(newTab)
  }

  const handleNewUser = () => {
    setModalTitle(formatMessage({ id: 'Users.form.addUser' }))
    setUserToEdit(null)
    setOpenModal(true)
  }

  const handleEditUser = (user) => {
    setModalTitle(formatMessage({ id: 'Users.form.editUser' }))
    setUserToEdit(user.id)
    setOpenModal(true)
  }

  const changeStatusUser = (user) => {
    async function changeStatus() {
      return callSecurityService('PUT', CHANGE_STATUS_USER_URL.replace('{userId}', user.id), {}, true)
    }

    changeStatus()
      .then(() => {
        loadUsers()
        const message = user.enabled ? 'Users.table.disabled' : 'Users.table.enabled'
        enqueueSnackbar(formatMessage({ id: message }, { 0: user.username }), { variant: 'success' })
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' })
        } else {
          enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), {
            variant: 'error'
          })
        }
      })
  }

  const handleHODataToggle = (user) => {
    async function toggleHOData() {
      return callSecurityService('PUT', TOGGLE_HO_DATA.replace('{userId}', user.id), {}, true)
    }

    toggleHOData()
      .then(() => {
        loadUsers()
        const message = user.dataPrivate ? 'Users.table.showed' : 'Users.table.hidden'
        enqueueSnackbar(formatMessage({ id: message }, { 0: user.username }), { variant: 'success' })
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' })
        } else {
          enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), {
            variant: 'error'
          })
        }
      })
  }

  const handleTechToggle = (user) => {
    setModalTechTitle(formatMessage({ id: 'Users.form.upgradeTechnician' }))
    setTechId(user.id)
    setOpenTechModal(true)
  }

  const handleSendInviteUser = (user) => {
    setOpenInviteModal(true)
    setModalInviteTitle(formatMessage({ id: 'Users.modal.invitation' }, { 0: user.username }))
    async function sendInvite() {
      return callSecurityService(
        'POST',
        `${SEND_INVITE_URL.replace('{userId}', user.id)}?partnerName=${process.env.REACT_APP_PARTNER_NAME}`,
        {},
        true
      )
    }

    sendInvite()
      .then((response) => {
        setInviteLink(response.data.invitationLink)
        enqueueSnackbar(formatMessage({ id: 'Users.table.sendInvite' }, { 0: user.username }), { variant: 'success' })
      })
      .catch((error) => {
        if (error.response && error.response.data.message) {
          enqueueSnackbar(error.response.data.message, { variant: 'error' })
        } else {
          enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), {
            variant: 'error'
          })
        }
      })
  }

  const handleCloseInviteModal = () => {
    setOpenInviteModal(false)
    setModalInviteTitle('')
    setInviteLink(null)
  }

  const handleCloseTechModal = () => {
    setOpenTechModal(false)
    setModalTechTitle('')
    setTechId(null)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setModalTitle('')
    setUserToEdit(null)
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <PageTitle titleId={'Users.panel.title'} />
      </div>
      <div className={classes.dataContainer}>
        <div className={classes.tabsHeader}>
          <Tabs
            classes={{ indicator: classes.indicator, scroller: classes.scroller, fixed: classes.fixed }}
            value={tab}
            onChange={handleChangeTab}
          >
            {pendingUsersAllowed && (
              <Tab
                classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
                label={formatMessage({ id: 'Users.tab.pending' })}
                value={'PENDING'}
              />
            )}
            <Tab
              classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
              label={formatMessage({ id: 'Users.tab.enabled' })}
              value={'ENABLED'}
            />
            <Tab
              classes={{ root: classes.tab, wrapper: classes.textWrapper, selected: classes.selected }}
              label={formatMessage({ id: 'Users.tab.disabled' })}
              value={'DISABLED'}
            />
          </Tabs>
          <div className={classes.tabIcons}>
            {showBrandFilter && (
              <NavbarCheckboxes values={parameters.partners} checkboxes={partners} onChange={check} />
            )}
            <SearchBar value={parameters.search} onChange={search} placeholderId={'Users.tab.search'} />
            {addUsersAllowed && (
              <CustomIcon
                icon={ICON_ADD}
                className={classNames(classes.icon, classes.iconPadding)}
                onClick={handleNewUser}
              />
            )}
          </div>
        </div>
      </div>
      {pendingUsersAllowed && (
        <TabPanel className={classes.tabContainer} value={tab} index="PENDING">
          <PendingUsersTab
            users={tab === 'PENDING' ? users : []}
            loading={isLoading}
            loadingMore={isLoadingMore}
            error={isError}
            last={last}
            loadMore={loadMore}
            handleSort={handleSort}
            parameters={parameters}
            handleEdit={handleEditUser}
            handleDisable={changeStatusUser}
            handleSend={handleSendInviteUser}
            handleHODataToggle={handleHODataToggle}
            handleTechToggle={handleTechToggle}
            loggedRole={userData.role}
          />
        </TabPanel>
      )}
      <TabPanel className={classes.tabContainer} value={tab} index="ENABLED">
        <EnabledUsersTab
          users={tab === 'ENABLED' ? users : []}
          loading={isLoading}
          loadingMore={isLoadingMore}
          error={isError}
          last={last}
          loadMore={loadMore}
          handleSort={handleSort}
          parameters={parameters}
          handleEdit={handleEditUser}
          handleDisable={changeStatusUser}
          handleHODataToggle={handleHODataToggle}
          handleTechToggle={handleTechToggle}
          loggedRole={userData.role}
        />
      </TabPanel>
      <TabPanel className={classes.tabContainer} value={tab} index="DISABLED">
        <DisabledUsersTab
          users={tab === 'DISABLED' ? users : []}
          loading={isLoading}
          loadingMore={isLoadingMore}
          error={isError}
          last={last}
          loadMore={loadMore}
          handleSort={handleSort}
          parameters={parameters}
          handleEdit={handleEditUser}
          handleEnable={changeStatusUser}
          handleHODataToggle={handleHODataToggle}
          handleTechToggle={handleTechToggle}
          loggedRole={userData.role}
        />
      </TabPanel>
      <UserModal
        open={openModal}
        handleClose={handleCloseModal}
        onSuccess={loadUsers}
        title={modalTitle}
        userIdToEdit={userToEdit}
        roles={roleList}
        brands={brandList}
        representatives={representativeList}
        companies={companiesList}
      />
      <TechnicianModal
        open={openTechModal}
        handleClose={handleCloseTechModal}
        onSuccess={loadUsers}
        title={modalTechTitle}
        techId={techId}
      />
      <InvitationModal
        open={openInviteModal}
        handleClose={handleCloseInviteModal}
        title={modalInviteTitle}
        inviteLink={inviteLink}
      />
    </div>
  )
}

export default UsersPanel
