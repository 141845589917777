import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  formControl: {
    margin: `${theme.spacing(2)}px 0`
  },
  root: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.web.grey,
    border: `1px solid ${theme.palette.web.blue}`,
    padding: '15px 12px',
    color: theme.palette.web.darkGrey,
    height: 48,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.web.darkRed}`,
      color: theme.palette.web.red
    }
  },
  input: {
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.19px'
  },
  label: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.19px',
    color: theme.palette.web.blueGrey,
    '&.Mui-focused, &.Mui-error': {
      color: theme.palette.web.blueGrey
    }
  },
  shrink: {
    transform: 'scale(0.8)'
  },
  helperText: {
    position: 'absolute',
    color: theme.palette.web.usbGrey,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.16px',
    lineHeight: 'normal',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      wordWrap: 'break-word',
      overflowWrap: 'break-word',
      whiteSpace: 'normal'
    }
  },
  helperTextPosition: {
    top: '72px'
  },
  helperTextMultilinePosition: {
    top: '93px'
  },
  errorMessage: {
    color: theme.palette.web.red
  }
}))
