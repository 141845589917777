import React, { useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import { LinearProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import FieldError from 'components/FieldError/FieldError'
import useCallService from 'services/useCallService'
import { bytesToMB, validateFirmwareVersionMask } from 'utils/Utils'
import { UPLOAD_ALTA_FIRMWARE } from 'Constants'
import useStyles from './styles'

const AltaFirmwareForm = ({ handleClose, onSuccess }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callFileStorageService } = useCallService()
  const hiddenFirmwareFileInput = useRef(null)
  const hiddenRgdFileInput = useRef(null)
  const [serviceError, setServiceError] = useState(false)

  yup.addMethod(yup.string, 'validateFirmwareVersionMask', function () {
    return this.test('validate-firmware-version-mask', formatMessage({ id: 'Commons.error.firmware.mask' }), (value) =>
      validateFirmwareVersionMask(value)
    )
  })

  const authValidationSchema = yup.object({
    firmwareVersion: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'AltaFirmware.modal.firmwareVersion.error.required' }))
      .validateFirmwareVersionMask(),
    releaseNotes: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'AltaFirmware.modal.releaseNotes.error.required' })),
    selectedFirmwareFile: yup
      .mixed()
      .required(formatMessage({ id: 'AltaFirmware.modal.selectedFirmwareFile.error.required' })),
    selectedRgdFile: yup.mixed().required(formatMessage({ id: 'AltaFirmware.modal.selectedRgdFile.error.required' }))
  })

  const authForm = useFormik({
    initialValues: {
      firmwareVersion: '',
      releaseNotes: '',
      selectedFirmwareFile: null,
      selectedRgdFile: null
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      setServiceError(false)
      const firmwareUploadRequest = {
        firmwareVersion: values.firmwareVersion.trim(),
        releaseNotes: values.releaseNotes,
        brand: process.env.REACT_APP_PARTNER_NAME
      }
      const data = new FormData()
      data.append('request', JSON.stringify(firmwareUploadRequest))
      data.append('firmwareFile', values.selectedFirmwareFile)
      data.append('rgdFile', values.selectedRgdFile)
      try {
        await callFileStorageService('POST', UPLOAD_ALTA_FIRMWARE, { data }, true)
        onSuccess()
        handleClose()
      } catch (error) {
        setServiceError(true)
      }
    }
  })

  const handleFirmwareSelect = () => {
    hiddenFirmwareFileInput.current.click()
  }

  const handleRgdSelect = () => {
    hiddenRgdFileInput.current.click()
  }

  const handleFirmwareChange = (event) => {
    const fileSelected = event.target.files[0]
    authForm.setFieldValue('selectedFirmwareFile', fileSelected)
  }

  const handleRgdChange = (event) => {
    const fileSelected = event.target.files[0]
    authForm.setFieldValue('selectedRgdFile', fileSelected)
  }

  return (
    <div className={classes.container}>
      <CustomForm form={authForm}>
        <div className={classes.boxContainer}>
          <CustomTextField
            form={authForm}
            formFieldName={'firmwareVersion'}
            labelId={'AltaFirmware.modal.firmwareVersion'}
            labelClasses={{ formControl: classes.label }}
            helperTextClasses={classes.helperTextPosition}
            inputClasses={{
              formControl: classes.formControl,
              root: classNames(classes.root, classes.inputHeight),
              input: classes.input
            }}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'releaseNotes'}
            labelId={'AltaFirmware.modal.releaseNotes'}
            multiline={true}
            rows={2}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{
              formControl: classes.formControl,
              root: classNames(classes.root, classes.textareaHeight),
              input: classNames(classes.input, classes.textarea)
            }}
          />
          <div className={classes.buttonBox}>
            <Button fullWidth className={classes.selectButton} onClick={handleFirmwareSelect}>
              <Typography className={classes.buttonLabel}>
                <FormattedMessage id={'AltaFirmware.modal.selectFirmwareFile'} />
              </Typography>
            </Button>
            <input
              type={'file'}
              ref={hiddenFirmwareFileInput}
              onChange={handleFirmwareChange}
              accept={process.env.REACT_APP_ALTA_FIRMWARE_EXTENSION}
              style={{ display: 'none' }}
            />
            <div hidden={!authForm.values.selectedFirmwareFile}>
              <Typography className={classes.selectedFile}>
                <FormattedMessage
                  id={'AltaFirmware.modal.selectedFile'}
                  values={{
                    0: authForm.values.selectedFirmwareFile ? authForm.values.selectedFirmwareFile.name : '',
                    1: authForm.values.selectedFirmwareFile ? bytesToMB(authForm.values.selectedFirmwareFile.size) : 0
                  }}
                />
              </Typography>
            </div>
            <FieldError
              hasError={authForm.touched.selectedFirmwareFile && authForm.errors.selectedFirmwareFile}
              errorMessage={authForm.errors.selectedFirmwareFile}
            />
          </div>
          <div className={classes.buttonBox}>
            <Button fullWidth className={classes.selectButton} onClick={handleRgdSelect}>
              <Typography className={classes.buttonLabel}>
                <FormattedMessage id={'AltaFirmware.modal.selectRgdFile'} />
              </Typography>
            </Button>
            <input
              type={'file'}
              ref={hiddenRgdFileInput}
              onChange={handleRgdChange}
              accept={process.env.REACT_APP_RGD_EXTENSION}
              style={{ display: 'none' }}
            />
            <div hidden={!authForm.values.selectedRgdFile}>
              <Typography className={classes.selectedFile}>
                <FormattedMessage
                  id={'AltaFirmware.modal.selectedFile'}
                  values={{
                    0: authForm.values.selectedRgdFile ? authForm.values.selectedRgdFile.name : '',
                    1: authForm.values.selectedRgdFile ? bytesToMB(authForm.values.selectedRgdFile.size) : 0
                  }}
                />
              </Typography>
            </div>
            <FieldError
              hasError={authForm.touched.selectedRgdFile && authForm.errors.selectedRgdFile}
              errorMessage={authForm.errors.selectedRgdFile}
            />
          </div>
          <div className={classes.buttonBox}>
            <FieldError
              hasError={serviceError}
              errorMessage={formatMessage({ id: 'AltaFirmware.modal.service.error' })}
            />
            {authForm.isSubmitting && <LinearProgress className={classes.loader} />}
            <Button
              disabled={!authForm.isValid || authForm.isSubmitting}
              classes={{ disabled: classes.disabledButton }}
              fullWidth
              className={classes.selectButton}
              type={'submit'}
            >
              <Typography className={classes.buttonLabel}>
                <FormattedMessage id={'AltaFirmware.modal.uploadFile'} />
              </Typography>
            </Button>
          </div>
        </div>
      </CustomForm>
    </div>
  )
}

export default AltaFirmwareForm
