import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  createContainer: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex'
  },
  customForm: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex'
  },
  form: {
    padding: `${theme.spacing(2)}px 0`,
    flex: 1
  },
  emptySpace: {
    marginTop: 20
  },
  bottomContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))
