import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { useMobileContext } from 'context/MobileContext'
import { TERMS_AND_CONDITIONS, PRIVACY } from 'Constants'
import useStyles from './styles'

const Footer = () => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const history = useHistory()

  const gotoTermsAndConditions = () => {
    history.push(TERMS_AND_CONDITIONS)
  }

  const gotoPrivacy = () => {
    history.push(PRIVACY)
  }

  return (
    <>
      <div className={classes.root}>
        <Typography className={classNames(classes.footerText, classes.clickable)} onClick={gotoTermsAndConditions}>
          <FormattedMessage id={'Footer.terms&conditions'} />
        </Typography>
        <Typography className={classes.footerText}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Typography>
        <Typography className={classNames(classes.footerText, classes.clickable)} onClick={gotoPrivacy}>
          <FormattedMessage id={'Footer.privacy'} />
        </Typography>
        {!isMobile && <Typography className={classes.footerText}>&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Typography>}
        {!isMobile && <Typography className={classes.footerText}>Copyright Disclaimer © 2023</Typography>}
      </div>
      {isMobile && (
        <div className={classes.rootMobile}>
          <Typography className={classes.footerText}>Copyright Disclaimer © 2023</Typography>
        </div>
      )}
    </>
  )
}

export default Footer
