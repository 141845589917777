import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  item: {
    minHeight: theme.spacing(6),
    padding: `${theme.spacing(0)}px ${theme.spacing(3)}px ${theme.spacing(0)}px ${theme.spacing(3)}px`
  },
  menuPopperIcon: {
    fontSize: 0,
    paddingLeft: theme.spacing(0),
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(0),
    '& svg': {
      fill: theme.palette.web.blue
    }
  },
  menuLabel: {
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.web.darkGrey
  },
  selected: {
    backgroundColor: theme.palette.web.transparentBlue
  },
  itemSelected: {
    background: 'var(--secondaryshade-2, rgba(87, 125, 255, 0.10))'
  }
}))
