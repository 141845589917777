import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import cookie from 'js-cookie'
import { useSnackbar } from 'notistack'
import Error from 'components/Error/Error'
import LoadMore from 'components/LoadMore/LoadMore'
import Loader from 'components/Loader/Loader'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import NoData from 'components/NoData/NoData'
import PageTitle from 'components/PageTitle/PageTitle'
import SearchBar from 'components/SearchBar/SearchBar'
import Table from 'components/Table/Table'
import useCallService from 'services/useCallService'
import { hasPermission, useDebounce } from 'utils/Utils'
import { usePartnersContext } from 'context/PartnersContext'
import {
  ADMIN_ROLE,
  DELETE_ARCHIVES_PERMISSION,
  DELETE_FILE_URL,
  ENGINEERING_ADMIN_ROLE,
  GET_ARCHIVES_URL,
  SELECTED_PARTNERS,
  USER_ADMIN_ROLE,
  USER_DATA
} from 'Constants'
import useStyles from './styles'

const ArchivesPanel = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { getPartners } = usePartnersContext()
  const { callFileStorageService } = useCallService()
  const [archivesList, setArchivesList] = useState([])
  const [partners, setPartners] = useState([])
  const [last, setLast] = useState(true)
  const userData = JSON.parse(cookie.get(USER_DATA))
  const showBrandFilter =
    userData.role === ADMIN_ROLE || userData.role === ENGINEERING_ADMIN_ROLE || userData.role === USER_ADMIN_ROLE
  const [parameters, setParameters] = useState({
    search: '',
    page: 0,
    sortBy: 'createdDate',
    sorting: 'ASC',
    partners: []
  })
  const pageSize = process.env.REACT_APP_DASHBOARD_PAGE_SIZE
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isError, setIsError] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const canDeleteArchives = hasPermission(DELETE_ARCHIVES_PERMISSION)

  const debouncedParameters = useDebounce(parameters, 500)

  useEffect(() => {
    async function fetchPartners() {
      return getPartners()
    }

    fetchPartners().then((response) => {
      setPartners(response)
      const selectedPartners = cookie.get(SELECTED_PARTNERS)
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page,
        sortBy: currentParameters.sortBy,
        sorting: currentParameters.sorting,
        partners: selectedPartners ? JSON.parse(selectedPartners) : response.map((el) => el.enumName)
      }))
    })
  }, [getPartners])

  useEffect(() => {
    async function fetchArchives() {
      setIsError(false)
      return callFileStorageService(
        'GET',
        `${GET_ARCHIVES_URL}?size=${pageSize}&page=${parameters.page}&sortBy=${parameters.sortBy}&sorting=${
          parameters.sorting
        }${
          parameters.search ? `&searchString=${encodeURIComponent(parameters.search)}` : ''
        }&partners=${parameters.partners.filter(Boolean).join(',')}`,
        {},
        true
      )
    }

    if (partners.length !== 0) {
      fetchArchives()
        .then((response) => {
          setLast(response.data.last)
          if (parameters.page === 0) {
            setArchivesList(response.data.data)
          } else {
            setArchivesList(archivesList.concat(response.data.data))
          }
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false)
          setIsLoadingMore(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParameters])

  const columns = [
    {
      id: 'createdDate',
      sorting: true,
      label: formatMessage({ id: 'Archives.column.date' }),
      type: 'date'
    },
    { id: 'boilerSerialNumber', sorting: true, label: formatMessage({ id: 'Archives.column.boilerSerialNumber' }) },
    { id: 'technician', sorting: true, label: formatMessage({ id: 'Archives.column.installer' }) },
    { id: 'company', sorting: true, label: formatMessage({ id: 'Archives.column.company' }) },
    { id: 'homeOwner', sorting: true, label: formatMessage({ id: 'Archives.column.homeOwner' }) },
    { id: 'type', sorting: true, label: formatMessage({ id: 'Archives.column.reports' }) }
  ]

  const handleDelete = async (file) => {
    setIsLoading(true)
    await callFileStorageService('DELETE', DELETE_FILE_URL + file.id, {}, true)
      .then(() => {
        const filteredList = archivesList.filter((archive) => archive.id !== file.id)
        setArchivesList(filteredList)
      })
      .catch(() => {
        enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), { variant: 'error' })
      })
      .finally(() => setIsLoading(false))
  }

  const handleView = (file) => {
    window.open(file.url, '_blank')
  }

  const search = (e) => {
    setIsLoading(true)
    setParameters((currentParameters) => ({
      search: e.target.value,
      page: 0,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const loadMore = async () => {
    setIsLoadingMore(true)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: currentParameters.page + 1,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const handleSort = (order, orderBy) => {
    setIsLoading(true)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      sortBy: orderBy,
      sorting: order.toUpperCase(),
      partners: currentParameters.partners
    }))
  }

  const check = (e) => {
    setIsLoading(true)
    if (e.target.checked) {
      parameters.partners[e.target.id - 1] = e.target.value
    } else {
      parameters.partners[e.target.id - 1] = null
    }
    cookie.set(SELECTED_PARTNERS, parameters.partners)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <PageTitle titleId={'Archives.panel.title'} />
        <div className={classes.rightHeaders}>
          {showBrandFilter && <NavbarCheckboxes values={parameters.partners} checkboxes={partners} onChange={check} />}
          <SearchBar value={parameters.search} placeholderId={'Archives.panel.search'} onChange={search} />
        </div>
      </div>
      <div className={classes.dataContainer}>
        {isError && <Error />}
        {isLoading && <Loader />}
        {!isError && !isLoading && archivesList.length === 0 && <NoData />}
        {!isError && !isLoading && archivesList.length > 0 && (
          <Table
            columns={columns}
            data={archivesList}
            defaultOrderKey={parameters.sortBy}
            defaultOrder={parameters.sorting.toLowerCase()}
            onSortChange={handleSort}
            actions={[
              {
                label: (boiler) =>
                  boiler.url.includes('.pdf')
                    ? formatMessage({ id: 'Commons.actions.view' })
                    : formatMessage({ id: 'Commons.actions.download' }),
                onClick: handleView,
                className: classes.viewButton
              },
              {
                label: formatMessage({ id: 'Commons.actions.delete' }),
                onClick: handleDelete,
                visible: () => !canDeleteArchives
              }
            ]}
          />
        )}
        {!isError && !isLoading && !last && (
          <LoadMore loadMore={loadMore} isLoading={isLoadingMore} className={classes.loadMoreMargin} />
        )}
      </div>
    </div>
  )
}

export default ArchivesPanel
