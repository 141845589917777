import React from 'react'
import { TOGGLE_SIDEBAR, CLOSE_SIDEBAR } from 'Constants'

const LayoutStateContext = React.createContext()
const LayoutDispatchContext = React.createContext()

function layoutReducer(state, action) {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return { ...state, isSidebarOpened: !state.isSidebarOpened }
    case CLOSE_SIDEBAR:
      return { ...state, isSidebarOpened: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function LayoutProvider({ children }) {
  const [state, dispatch] = React.useReducer(layoutReducer, {
    isSidebarOpened: true
  })

  return (
    <LayoutStateContext.Provider value={state}>
      <LayoutDispatchContext.Provider value={dispatch}>{children}</LayoutDispatchContext.Provider>
    </LayoutStateContext.Provider>
  )
}

function useLayoutState() {
  const context = React.useContext(LayoutStateContext)
  if (context === undefined) {
    throw new Error('useLayoutState must be used within a LayoutProvider')
  }
  return context
}

function useLayoutDispatch() {
  const context = React.useContext(LayoutDispatchContext)
  if (context === undefined) {
    throw new Error('useLayoutDispatch must be used within a LayoutProvider')
  }
  return context
}

export { LayoutProvider, useLayoutState, useLayoutDispatch, toggleSidebar, closeSidebar }

// ###########################################################
function toggleSidebar(dispatch) {
  dispatch({
    type: TOGGLE_SIDEBAR
  })
}

function closeSidebar(dispatch) {
  dispatch({
    type: CLOSE_SIDEBAR
  })
}
