import React from 'react'
import { useLocation, Link } from 'react-router-dom'
import classNames from 'classnames'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import useStyles from './styles'

const LinkIcon = ({ icon, link }) => {
  const classes = useStyles()
  const currentPath = useLocation()

  return (
    <Link to={link}>
      <CustomIcon
        styles={{
          root: classNames({
            [classes.selected]: currentPath.pathname.includes(link)
          })
        }}
        icon={icon}
      />
    </Link>
  )
}

export default LinkIcon
