import React, { useState, createContext } from 'react'
import { useHistory } from 'react-router-dom'
import cookie from 'js-cookie'
import useCallService from 'services/useCallService'
import * as Utils from 'utils/Utils'
import { useLanguageContext } from 'context/LanguageContext'
import {
  DASHBOARD,
  USER_DATA,
  HEADER_MODULES,
  INDEX,
  LOGIN_URL,
  SIDEBAR_MODULES,
  TOKEN,
  PERMISSIONS,
  LANGUAGE,
  PARTNERS,
  SELECTED_PARTNERS,
  SELECTED_SORTING,
  MODEL_TYPES
} from 'Constants'

const AuthorizationContext = createContext()

const AuthorizationContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const history = useHistory()
  const { updateLanguage } = useLanguageContext()
  const { callSecurityService } = useCallService()

  const handleLogout = () => {
    setIsAuthenticated(false)
    cookie.remove(TOKEN)
    cookie.remove(USER_DATA)
    cookie.remove(LANGUAGE)
    cookie.remove(HEADER_MODULES)
    cookie.remove(SIDEBAR_MODULES)
    cookie.remove(PERMISSIONS)
    cookie.remove(PARTNERS)
    cookie.remove(MODEL_TYPES)
    cookie.remove(SELECTED_PARTNERS)
    cookie.remove(SELECTED_SORTING)
    history.push(INDEX)
    updateLanguage()
  }

  const handleLogin = async (request) => {
    const response = await callSecurityService('POST', LOGIN_URL, { data: request }, false)
    const {
      accessToken,
      id,
      firstName,
      lastName,
      email,
      phoneNumber,
      companyName,
      companyAddress,
      headerModules,
      sidebarModules,
      permissions,
      language,
      roles
    } = response.data
    cookie.set(TOKEN, accessToken, { expires: 1 })
    const userData = {
      id,
      firstName,
      lastName,
      email,
      phoneNumber,
      companyName,
      companyAddress: Utils.buildAddress(companyAddress),
      role: roles[0]
    }
    cookie.set(LANGUAGE, language)
    cookie.set(USER_DATA, userData, { expires: 1 })
    cookie.set(HEADER_MODULES, headerModules, { expires: 1 })
    cookie.set(SIDEBAR_MODULES, sidebarModules, { expires: 1 })
    cookie.set(PERMISSIONS, permissions, { expires: 1 })
    cookie.set(SELECTED_SORTING, 'dateTime', { expires: 1 })
    history.push(DASHBOARD)
    updateLanguage()
  }

  return (
    <AuthorizationContext.Provider value={{ handleLogin, handleLogout, setIsAuthenticated, isAuthenticated }}>
      {children}
    </AuthorizationContext.Provider>
  )
}

export default AuthorizationContextProvider

function useAuthorizationContext() {
  const context = React.useContext(AuthorizationContext)
  if (context === undefined) {
    throw new Error('useAuthorizationContext must be used within a AuthorizationContextProvider')
  }
  return context
}

export { useAuthorizationContext }
