import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import ForgotPasswordForm from 'components/ForgotPasswordForm/ForgotPasswordForm'
import LoginContainer from 'components/LoginContainer/LoginContainer'
import { useMobileContext } from 'context/MobileContext'

const ForgotPassword = () => {
  const title = useIntl().formatMessage({ id: 'ForgotPassword.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })
  const { setShowBackButton } = useMobileContext()

  useEffect(() => {
    setShowBackButton(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LoginContainer>
        <ForgotPasswordForm />
      </LoginContainer>
    </>
  )
}

export default ForgotPassword
