import React from 'react'
import Footer from 'components/Footer/Footer'
import useStyles from './styles'

const LoginContainer = ({ children }) => {
  const classes = useStyles()
  const logo = process.env.REACT_APP_LOGIN_LOGO

  return (
    <div className={classes.root}>
      <div className={classes.loginContainer}>
        <div className={classes.loginLogo}>
          <img src={logo} alt="logo" />
        </div>
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default LoginContainer
