import React from 'react'
import classNames from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList/MenuList'
import Paper from '@material-ui/core/Paper/Paper'
import Popper from '@material-ui/core/Popper/Popper'
import useStyles from './styles'

const MenuPopper = ({ children, open, anchorRef, handleClose, className, placement = 'bottom-end' }) => {
  const classes = useStyles()

  return (
    <Popper open={open} anchorEl={anchorRef.current} disablePortal placement={placement}>
      <Paper className={classNames(classes.paperMenu, className)}>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList>{children}</MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  )
}

export default MenuPopper
