import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Slide from '@material-ui/core/Slide'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import ICON_CLOSE from 'assets/images/icons-close.svg'
import useStyles from './styles'

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />)

const MobileModal = ({ open, handleClose, title, children }) => {
  const classes = useStyles()

  return (
    <Dialog
      fullScreen={true}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      classes={{ root: classes.root, container: classes.container, paper: classes.paper }}
    >
      <div className={classes.modalHeader}>
        <DialogTitle disableTypography={true} classes={{ root: classes.title }}>
          {title}
        </DialogTitle>
        <CustomIcon icon={ICON_CLOSE} onClick={handleClose} className={classes.closeIcon} />
      </div>
      <DialogContent classes={{ root: classes.contentRoot }} className={classes.bodyContainer}>
        {children}
      </DialogContent>
    </Dialog>
  )
}

export default MobileModal
