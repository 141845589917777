import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import BoilersPanel from 'components/BoilersPanel/BoilersPanel'
import Layout from 'components/Layout/Layout'

const Boilers = () => {
  const title = useIntl().formatMessage({ id: 'Boilers.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout>
        <BoilersPanel />
      </Layout>
    </>
  )
}

export default Boilers
