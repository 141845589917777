import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dot: {
    width: 8,
    height: 8,
    marginRight: 4
  },
  dotRed: {
    color: theme.palette.web.red
  },
  dotGreen: {
    color: theme.palette.web.green
  },
  connectionText: {
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase',
    fontSize: 10,
    fontWeight: 'bold'
  }
}))
