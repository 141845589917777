import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 30
  },
  buttonsContainer: {
    padding: '0 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  filterButton: {
    cursor: 'pointer',
    color: theme.palette.web.blue,
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'Uppercase',
    padding: '8px 21px',
    borderRadius: 15,
    lineHeight: 1.17,
    letterSpacing: 0.6
  },
  deleteButton: {
    position: 'absolute',
    top: -8,
    right: 10
  },
  deleteText: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1.17,
    letterSpacing: 0.6,
    color: theme.palette.web.usbGrey
  },
  activeFilterButton: {
    backgroundColor: theme.palette.web.transparentBlue
  },
  smallWidth: {
    width: '10%'
  },
  mediumWidth: {
    width: '30%'
  },
  largeWidth: {
    width: '60%'
  },
  itemsList: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableRowSimple: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 10px 24px 10px',
    justifyContent: 'center'
  },
  primaryLine: {
    color: theme.palette.web.black,
    fontSize: 14,
    fontWeight: 700,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  },
  secondaryLine: {
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 600,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  }
}))
