import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  readOnlyTextField: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'normal'
    }
  },
  label: {
    width: '30%',
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.web.darkGrey,
    marginRight: 35,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  valueBox: {
    flex: 1,
    height: 38,
    borderRadius: 6,
    backgroundColor: theme.palette.web.grey,
    padding: '8px 13px 5px 13px',
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      backgroundColor: theme.palette.web.white
    }
  },
  value: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.web.black,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))
