import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import shortId from 'shortid'
import classNames from 'classnames'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import useStyles from './styles'

const MenuPopperItem = ({
  icon,
  labelId,
  onClick = () => undefined,
  onMouseDown = () => undefined,
  link = null,
  className,
  selected = false
}) => {
  const classes = useStyles()
  const history = useHistory()
  const currentPath = useLocation()

  return (
    <MenuItem
      key={shortId.generate()}
      className={classNames(classes.item, className, {
        [classes.selected]: currentPath.pathname.includes(link),
        [classes.itemSelected]: selected
      })}
      onClick={() => (link ? history.push(link) : onClick())}
      onMouseDown={() => (link ? history.push(link) : onMouseDown())}
    >
      {icon && <CustomIcon className={classes.menuPopperIcon} icon={icon} />}
      <Typography className={classes.menuLabel}>
        <FormattedMessage id={labelId} />
      </Typography>
    </MenuItem>
  )
}

export default MenuPopperItem
