import useSWR from 'swr'
import React from 'react'
import ManualCard from 'components/ManualCard/ManualCard'
import useCallService from 'services/useCallService'
import { GET_MANUALS_URL } from 'Constants'
import Error from '../Error/Error'
import Loader from '../Loader/Loader'
import NoData from '../NoData/NoData'
import useStyles from './styles'

const ManualsTab = ({ boilerModelId }) => {
  const classes = useStyles()
  const { callFileStorageService } = useCallService()
  const fetcher = (url) => callFileStorageService('GET', url, {}, true).then((response) => response.data)

  const { data, error } = useSWR(`${GET_MANUALS_URL}?boilerModel=${boilerModelId}`, fetcher)

  // Error in request
  if (error) {
    return <Error />
  }
  // Loading
  if (!data) {
    return <Loader />
  }

  if (data.length === 0) {
    return <NoData />
  }

  return (
    <div className={classes.gridRoot}>
      {data.map((manual) => (
        <ManualCard key={manual.id} manual={manual} />
      ))}
    </div>
  )
}

export default ManualsTab
