import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import cookie from 'js-cookie'
import BoilerModal from 'components/BoilerModal/BoilerModal'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import DeleteBoilerModal from 'components/DeleteBoilerModal/DeleteBoilerModal'
import Error from 'components/Error/Error'
import LoadMore from 'components/LoadMore/LoadMore'
import Loader from 'components/Loader/Loader'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import NoData from 'components/NoData/NoData'
import PageTitle from 'components/PageTitle/PageTitle'
import SearchBar from 'components/SearchBar/SearchBar'
import Table from 'components/Table/Table'
import useCallService from 'services/useCallService'
import { hasPermission, useDebounce } from 'utils/Utils'
import { usePartnersContext } from 'context/PartnersContext'
import ICON_DELETE from 'assets/images/icons-delete.svg'
import {
  ADMIN_ROLE,
  DELETE_BOILERS_PERMISSION,
  ENGINEERING_ADMIN_ROLE,
  GET_BOILERS_MANAGEMENT_URL,
  SELECTED_PARTNERS,
  USER_ADMIN_ROLE,
  USER_DATA
} from 'Constants'
import useStyles from './styles'

const BoilersPanel = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { getPartners, getModelTypes } = usePartnersContext()
  const { callBoilerService } = useCallService()
  const [boilersList, setBoilersList] = useState([])
  const [partners, setPartners] = useState([])
  const [modelTypes, setModelTypes] = useState([])
  const [parameters, setParameters] = useState({
    search: '',
    page: 0,
    sortBy: 'createdDate',
    sorting: 'ASC',
    partners: []
  })
  const pageSize = process.env.REACT_APP_DASHBOARD_PAGE_SIZE
  const [boilerToView, setBoilerToView] = useState(null)
  const [openModal, setOpenModal] = React.useState(false)
  const [last, setLast] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [isError, setIsError] = useState(false)
  const [modalDeleteTitle, setModalDeleteTitle] = React.useState('')
  const [boilerToDelete, setBoilerToDelete] = React.useState(null)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)
  const userData = JSON.parse(cookie.get(USER_DATA))
  const showBrandFilter =
    userData.role === ADMIN_ROLE || userData.role === ENGINEERING_ADMIN_ROLE || userData.role === USER_ADMIN_ROLE

  const debouncedParameters = useDebounce(parameters, 500)
  const canDeleteBoilers = hasPermission(DELETE_BOILERS_PERMISSION)

  useEffect(() => {
    async function fetchPartners() {
      return getPartners()
    }

    fetchPartners().then((response) => {
      setPartners(response)
      const selectedPartners = cookie.get(SELECTED_PARTNERS)
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page,
        sortBy: currentParameters.sortBy,
        sorting: currentParameters.sorting,
        partners: selectedPartners ? JSON.parse(selectedPartners) : response.map((el) => el.enumName)
      }))
    })
  }, [getPartners])

  useEffect(() => {
    async function fetchModelTypes() {
      return getModelTypes()
    }

    fetchModelTypes().then((response) => {
      setModelTypes(response)
    })
  }, [getModelTypes])

  const loadBoilers = () => {
    async function fetchBoilers() {
      setIsError(false)
      return callBoilerService(
        'GET',
        `${GET_BOILERS_MANAGEMENT_URL}?size=${pageSize}&page=${parameters.page}&sortBy=${parameters.sortBy}&sorting=${
          parameters.sorting
        }${
          parameters.search ? `&searchString=${encodeURIComponent(parameters.search)}` : ''
        }&partners=${parameters.partners.filter(Boolean).join(',')}&types=${modelTypes
          .map((el) => el.enumName)
          .filter(Boolean)
          .join(',')}`,
        {},
        true
      )
    }

    if (partners.length !== 0) {
      fetchBoilers()
        .then((response) => {
          setLast(response.data.last)
          if (parameters.page === 0) {
            setBoilersList(response.data.data)
          } else {
            setBoilersList(boilersList.concat(response.data.data))
          }
        })
        .catch(() => setIsError(true))
        .finally(() => {
          setIsLoading(false)
          setIsLoadingMore(false)
        })
    }
  }

  useEffect(() => {
    loadBoilers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParameters])

  const handleSort = (order, orderBy) => {
    setIsLoading(true)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      sortBy: orderBy,
      sorting: order.toUpperCase(),
      partners: currentParameters.partners
    }))
  }

  const loadMore = async () => {
    setIsLoadingMore(true)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: currentParameters.page + 1,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const search = (e) => {
    setIsLoading(true)
    setParameters((currentParameters) => ({
      search: e.target.value,
      page: 0,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const check = (e) => {
    setIsLoading(true)
    if (e.target.checked) {
      parameters.partners[e.target.id - 1] = e.target.value
    } else {
      parameters.partners[e.target.id - 1] = null
    }
    cookie.set(SELECTED_PARTNERS, parameters.partners)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      sortBy: currentParameters.sortBy,
      sorting: currentParameters.sorting,
      partners: currentParameters.partners
    }))
  }

  const handleOpenModal = (boiler) => {
    setBoilerToView(boiler.id)
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setBoilerToView(null)
  }

  const handleDeleteModal = (boiler) => {
    setModalDeleteTitle(formatMessage({ id: 'Boilers.modal.deleteBoiler.title' }))
    setBoilerToDelete(boiler)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
    setModalDeleteTitle('')
    setBoilerToDelete(null)
  }

  const columns = [
    {
      id: 'createdDate',
      sorting: true,
      label: formatMessage({ id: 'Boilers.column.date' }),
      type: 'date'
    },
    {
      id: 'serialNumber',
      sorting: true,
      label: formatMessage({ id: 'Boilers.column.boilerSN' })
    },
    {
      id: 'installer',
      sorting: true,
      label: formatMessage({ id: 'Boilers.column.installer' })
    },
    {
      id: 'company',
      sorting: true,
      label: formatMessage({ id: 'Boilers.column.company' })
    },
    {
      id: 'gateway',
      sorting: true,
      label: formatMessage({ id: 'Boilers.column.gateway' })
    }
  ]

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <PageTitle titleId={'Boilers.panel.title'} />
        <div className={classes.rightHeaders}>
          {showBrandFilter && <NavbarCheckboxes values={parameters.partners} checkboxes={partners} onChange={check} />}
          <SearchBar value={parameters.search} placeholderId={'Boilers.panel.search'} onChange={search} />
        </div>
      </div>
      <div className={classes.dataContainer}>
        {isError && <Error />}
        {isLoading && <Loader />}
        {!isError && !isLoading && boilersList.length === 0 && <NoData />}
        {!isError && !isLoading && boilersList.length > 0 && (
          <Table
            columns={columns}
            data={boilersList}
            defaultOrderKey={parameters.sortBy}
            defaultOrder={parameters.sorting.toLowerCase()}
            onSortChange={handleSort}
            actions={[
              {
                label: formatMessage({ id: 'Commons.actions.view' }),
                onClick: handleOpenModal,
                className: classes.viewButton
              },
              {
                label: (
                  <CustomIcon
                    icon={ICON_DELETE}
                    className={classNames(classes.icon, classes.iconPadding)}
                    tooltipText={formatMessage({ id: 'Commons.button.deleteBoiler' })}
                  />
                ),
                onClick: handleDeleteModal,
                className: classes.enabledButton,
                visible: () => !canDeleteBoilers
              }
            ]}
          />
        )}
        {!isError && !isLoading && !last && (
          <LoadMore loadMore={loadMore} isLoading={isLoadingMore} className={classes.loadMoreMargin} />
        )}
      </div>
      <BoilerModal
        open={openModal}
        handleClose={handleCloseModal}
        title={formatMessage({ id: 'Boilers.modal.title' })}
        boilerId={boilerToView}
      />
      <DeleteBoilerModal
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        onSuccess={loadBoilers}
        title={modalDeleteTitle}
        boilerToDelete={boilerToDelete}
      />
    </div>
  )
}

export default BoilersPanel
