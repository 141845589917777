import React from 'react'
import shortId from 'shortid'
import ChipItem from 'components/ChipItem/ChipItem'
import useStyles from './styles'

const ChipsList = ({ list, icon, onAction, chipClassName }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {list.map((item) => (
        <ChipItem key={shortId.generate()} item={item} icon={icon} onAction={onAction} chipClassName={chipClassName} />
      ))}
    </div>
  )
}

export default ChipsList
