import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 40
  },
  boxContainer: {
    minHeight: 300,
    borderRadius: 8,
    padding: '15px 25px 25px 25px',
    boxShadow: `0 2px 4px 0 ${theme.palette.web.cardShadow}`,
    backgroundColor: theme.palette.web.white,
    margin: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.web.darkGrey,
    marginBottom: 10
  },
  searchBarForm: {
    width: '100%'
  },
  searchBarRoot: {
    margin: theme.spacing(0),
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.web.grey,
    padding: '7px 8px 7px 13px',
    color: theme.palette.web.darkGrey,
    height: 40,
    width: '100%'
  },
  addChip: {
    color: theme.palette.web.usbGrey,
    backgroundColor: theme.palette.web.transparentUsbGrey,
    '&:focus': {
      backgroundColor: theme.palette.web.transparentUsbGrey
    }
  },
  addIcon: {
    width: 15,
    height: 15,
    color: theme.palette.web.usbGrey,
    '&:hover': {
      color: theme.palette.web.usbGrey
    }
  },
  deleteChip: {
    color: theme.palette.web.blue,
    backgroundColor: theme.palette.web.transparentBlue,
    '&:focus': {
      backgroundColor: theme.palette.web.transparentBlue
    }
  },
  deleteIcon: {
    width: 13,
    height: 13,
    color: theme.palette.web.blue,
    '&:hover': {
      color: theme.palette.web.blue
    }
  },
  selectButton: {
    borderRadius: 6,
    backgroundColor: theme.palette.web.blue,
    '&:hover': {
      backgroundColor: theme.palette.web.blue
    }
  },
  disabledButton: {
    opacity: 0.2
  },
  buttonLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    textAlign: 'center',
    color: theme.palette.web.white,
    textTransform: 'none'
  },
  fileData: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  fileName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: theme.palette.web.darkGrey
  },
  fileDate: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.web.darkGrey
  },
  label: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.38px',
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase',
    '&.Mui-focused, &.Mui-error': {
      color: theme.palette.web.usbGrey
    }
  },
  formControl: {
    margin: 0,
    'p + &': {
      position: 'static'
    }
  },
  root: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.web.white,
    border: `1px solid ${theme.palette.web.lightGrey}`,
    padding: '8px 6px 8px 12px',
    color: theme.palette.web.darkGrey,
    'label + &': {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2)
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.web.darkRed}`,
      color: theme.palette.web.red
    }
  },
  inputHeight: {
    height: 36
  },
  textareaHeight: {
    height: 70
  },
  input: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.44px'
  },
  textarea: {
    height: 'auto !important'
  },
  selectedFile: {
    fontSize: 12,
    margin: '10px 0px'
  },
  helperTextPosition: {
    top: '60px'
  },
  loader: {
    margin: '5px 0'
  }
}))
