import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import CustomRadioButtonGroup from 'components/CustomRadioButtonGroup/CustomRadioButtonGroup'
import FieldError from 'components/FieldError/FieldError'
import useCallService from 'services/useCallService'
import { useLanguageContext } from 'context/LanguageContext'
import { CHANGE_LANGUAGE_URL } from 'Constants'
import useStyles from './styles'

const LanguageSelector = ({ userId }) => {
  const classes = useStyles()
  const { callSecurityService } = useCallService()
  const { formatMessage } = useIntl()
  const { language, languagesList, changeLanguage } = useLanguageContext()
  const [serviceError, setServiceError] = useState(false)
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')

  const handleChangeLanguage = async (e) => {
    setServiceError(false)
    try {
      await callSecurityService(
        'PUT',
        CHANGE_LANGUAGE_URL.replace('{userId}', userId),
        { headers: { Language: e.target.value } },
        true
      )
      changeLanguage(e.target.value)
    } catch (error) {
      setServiceError(true)
      if (error.response && error.response.data.message) {
        setServiceErrorMessage(error.response.data.message)
      } else {
        setServiceErrorMessage(formatMessage({ id: 'Commons.genericError' }))
      }
      setTimeout(() => setServiceError(false), 5000)
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.radioContainer}>
        <Typography className={classes.label}>
          <FormattedMessage id={'Profile.panel.language'} />
        </Typography>
        <CustomRadioButtonGroup
          selectedValue={language}
          handleChange={(e) => handleChangeLanguage(e)}
          radioButtonList={languagesList}
        />
      </div>
      <FieldError hasError={serviceError} errorMessage={serviceErrorMessage} />
    </div>
  )
}

export default LanguageSelector
