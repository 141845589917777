import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import ConfirmationScreen from 'components/ConfirmationScreen/ConfirmationScreen'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomForm from 'components/CustomForm/CustomForm'
import PasswordTextField from 'components/PasswordTextField/PasswordTextField'
import useCallService from 'services/useCallService'
import { useMobileContext } from 'context/MobileContext'
import { INDEX, PASSWORD_REGEX, RESET_URL, TOKEN } from 'Constants'
import useStyles from './styles'

const ResetPasswordForm = () => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const queryParams = new URLSearchParams(useLocation().search)
  const history = useHistory()
  const { callSecurityService } = useCallService()
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [notYetReset, setNotYetReset] = useState(true)
  const [serviceError, setServiceError] = useState(false)

  const authValidationSchema = yup.object({
    newPassword: yup
      .string()
      .required(formatMessage({ id: 'ResetPassword.newPassword.error.required' }))
      .matches(PASSWORD_REGEX, formatMessage({ id: 'Commons.error.passwordFormat' })),
    repeatPassword: yup
      .string()
      .required(formatMessage({ id: 'ResetPassword.repeatPassword.error.required' }))
      .oneOf([yup.ref('newPassword')], formatMessage({ id: 'ResetPassword.repeatPassword.error.equals' }))
  })

  const authForm = useFormik({
    initialValues: {
      newPassword: '',
      repeatPassword: ''
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      const resetRequest = {
        password: values.newPassword,
        confirmPassword: values.repeatPassword,
        token: queryParams.get(TOKEN),
        partnerName: process.env.REACT_APP_PARTNER_NAME
      }
      try {
        await callSecurityService('POST', RESET_URL, { data: resetRequest }, false)
        setNotYetReset(false)
      } catch (error) {
        setServiceError(true)
        const errorMessage =
          error.response && error.response.data.message
            ? error.response.data.message
            : formatMessage({ id: 'Commons.genericError' })
        enqueueSnackbar(errorMessage, { variant: 'error', onClose: () => setServiceError(false) })
      }
    }
  })

  const handleBackToLogin = () => {
    history.push(INDEX)
  }

  return (
    <div className={classes.resetContainer}>
      {notYetReset ? (
        <CustomForm form={authForm} className={classes.customForm}>
          <div className={classes.form}>
            <PasswordTextField
              form={authForm}
              formFieldName={'newPassword'}
              labelId={'ResetPassword.newPassword.label'}
              helperText={formatMessage({ id: 'Commons.newPassword.helperText' })}
              serviceError={serviceError}
            />
            {isMobile && <div className={classes.emptySpace} />}
            <PasswordTextField
              form={authForm}
              formFieldName={'repeatPassword'}
              labelId={'ResetPassword.repeatPassword.label'}
              serviceError={serviceError}
            />
          </div>
          <div className={classes.bottomContainer}>
            <CustomButton
              labelId={'ResetPassword.reset.button'}
              disabled={!authForm.isValid || authForm.isSubmitting || serviceError}
              type={'submit'}
              fullWidth
            />
          </div>
        </CustomForm>
      ) : (
        <ConfirmationScreen
          messageId={'ResetPassword.confirmation.text'}
          hasButton={true}
          buttonId={'ResetPassword.login.button'}
          buttonAction={handleBackToLogin}
        />
      )}
    </div>
  )
}

export default ResetPasswordForm
