import React from 'react'
import { FormattedMessage } from 'react-intl'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import Typography from '@material-ui/core/Typography'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import useStyles from './styles'

const CustomCheckbox = ({ form, formFieldName, labelId }) => {
  const classes = useStyles()

  const onChange = (e) => {
    form.setFieldValue(formFieldName, e.target.checked)
  }

  return (
    <div className={classes.checkboxContainer}>
      <Checkbox
        icon={<CheckBoxOutlineBlankOutlinedIcon />}
        checkedIcon={<CheckBoxOutlinedIcon className={classes.checked} />}
        checked={form.values[formFieldName]}
        onChange={onChange}
        className={classes.checkbox}
        disableRipple={true}
      />
      {labelId && (
        <Typography className={classes.notificationLabel}>
          <FormattedMessage id={labelId} />
        </Typography>
      )}
    </div>
  )
}

export default CustomCheckbox
