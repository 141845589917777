import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  forgotContainer: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex'
  },
  customForm: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex'
  },
  form: {
    padding: `${theme.spacing(2)}px 0`,
    flex: 1
  },
  bottomContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  textContainer: {
    marginTop: theme.spacing(4)
  },
  textTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.24px',
    color: theme.palette.web.blueGrey
  },
  text: {
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.19px',
    color: theme.palette.web.blueGrey
  },
  backButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      marginTop: 20
    },
    '& p': {
      fontSize: 14,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.19px',
      textTransform: 'uppercase',
      color: theme.palette.web.blue
    }
  }
}))
