import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  gridContainer: {
    flex: 1,
    overflowY: 'auto'
  },
  filtersContainer: {
    marginBottom: 15
  },
  body: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(300px, 470px))',
    gridColumnGap: 45,
    gridRowGap: 0,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(300px, 470px))'
    }
  },
  resultsContainer: {
    height: '72%'
  },
  filtersRow: {
    height: '41px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.header.icon}`
  },
  rightHeaders: {
    display: 'flex',
    columnGap: '20px'
  },
  bottom: {
    width: '100%',
    flex: '0 0 auto',
    height: 50,
    alignSelf: 'flex-end',
    borderTop: '0.50px rgba(87, 125, 255, 0.60) solid',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 15
  },
  primaryButton: {
    color: `${theme.palette.web.white} !important`,
    backgroundColor: `${theme.palette.web.blue} !important`,
    borderRadius: '6px !important',
    height: '32px !important',
    minWidth: '118px !important',
    padding: '0px 40px !important',
    fontFamily: 'Open Sans !important',
    fontSize: '12px !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase !important'
  },
  transitionButton: {
    height: '32px !important',
    fontFamily: 'Open Sans !important',
    fontSize: '12px !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    textTransform: 'uppercase !important'
  }
}))
