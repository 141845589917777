import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Typography } from '@material-ui/core'
import useStyles from './styles'

const FieldError = ({ errorMessageId, errorMessage, hasError }) => {
  const classes = useStyles()

  return (
    <div hidden={!hasError}>
      {(errorMessage || errorMessageId) && (
        <Typography className={classes.errorMessage}>
          {errorMessage || <FormattedMessage id={errorMessageId} />}
        </Typography>
      )}
    </div>
  )
}

export default FieldError
