import React from 'react'
import useStyles from './styles'

const TabPanel = ({ children, value, index, ...other }) => {
  const classes = useStyles()

  return (
    <div className={classes.tabPanelContainer} hidden={value !== index} {...other}>
      {children}
    </div>
  )
}

export default TabPanel
