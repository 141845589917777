import React from 'react'
import { ReactSVG } from 'react-svg'
import Button from '@material-ui/core/Button'
import MenuPopper from 'components/MenuPopper/MenuPopper'

const ButtonWithMenu = ({ children, icon }) => {
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <Button disableTouchRipple ref={anchorRef} onClick={() => handleToggle()}>
        <ReactSVG src={icon} />
      </Button>
      <MenuPopper open={open} anchorRef={anchorRef} handleClose={handleClose} placement={'left'}>
        {children}
      </MenuPopper>
    </>
  )
}

export default ButtonWithMenu
