import React from 'react'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import { useMobileContext } from 'context/MobileContext'
import ARROW_IMAGE from 'assets/images/arrows-arrow.svg'
import MOBILE_ARROW_IMAGE from 'assets/images/mobile-arrow.svg'
import useStyles from './styles'

const BoilerTempsRow = ({
  connected,
  firstLabelId,
  secondLabelId,
  valueUnit,
  hasArrows,
  firstValue,
  secondValue,
  firstPlaceholderId,
  secondPlaceholderId,
  active,
  heating,
  primaryImage,
  secondaryImage
}) => {
  const classes = useStyles()
  const firstLabel = { label: firstLabelId, value: firstValue }
  const secondLabel = { label: secondLabelId, value: secondValue }
  const firstPlaceholder = { label: firstPlaceholderId }
  const secondPlaceholder = { label: secondPlaceholderId }
  const { isMobile } = useMobileContext()

  return (
    <>
      {!isMobile ? (
        <div className={classNames(classes.boilerRow)}>
          <div className={classes.firstBlock}>
            {hasArrows && <Typography className={classes.label}>{firstLabel.label}</Typography>}
            {hasArrows && (
              <Typography className={classes.value}>
                {connected ? firstLabel.value : '-'}
                {valueUnit}
              </Typography>
            )}
          </div>
          <div className={classes.arrowBlock}>
            {hasArrows && <ReactSVG src={ARROW_IMAGE} className={classes.redArrow} />}
            {firstPlaceholder.label && (
              <Typography className={classes.placeholder}>{firstPlaceholder.label}</Typography>
            )}
          </div>
          <div className={classes.image}>
            {connected && active && heating && <ReactSVG src={secondaryImage} className={classes.flame} />}
            <div className={classNames(classes.imageContainer, { [classes.notActiveImage]: !connected || !active })}>
              <ReactSVG src={primaryImage} />
            </div>
          </div>
          <div className={classes.secondBlock}>
            {hasArrows && <Typography className={classes.label}>{secondLabel.label}</Typography>}
            {hasArrows && (
              <Typography className={classes.value}>
                {connected ? secondLabel.value : '-'}
                {valueUnit}
              </Typography>
            )}
          </div>
          <div className={classes.arrowBlock}>
            {hasArrows && <ReactSVG src={ARROW_IMAGE} className={classes.blueArrow} />}
            {secondPlaceholder.label && (
              <Typography className={classes.placeholder}>{secondPlaceholder.label}</Typography>
            )}
          </div>
        </div>
      ) : (
        <div className={classes.mobileRow}>
          <div className={classes.mobileValuesBlock}>
            {hasArrows && <Typography className={classes.label}>{firstLabel.label}</Typography>}
            {hasArrows && <ReactSVG src={MOBILE_ARROW_IMAGE} className={classes.redArrow} />}
            {hasArrows && (
              <Typography className={classes.value}>
                {connected ? firstLabel.value : '-'}
                {valueUnit}
              </Typography>
            )}
            {firstPlaceholder.label && (
              <Typography className={classes.placeholder}>{firstPlaceholder.label}</Typography>
            )}
          </div>
          <div className={classes.mobileImageBlock}>
            {connected && active && heating && <ReactSVG src={secondaryImage} className={classes.flame} />}
            <div className={classNames(classes.imageContainer, { [classes.notActiveImage]: !connected || !active })}>
              <ReactSVG src={primaryImage} />
            </div>
          </div>
          <div className={classes.mobileValuesBlock}>
            {hasArrows && <Typography className={classes.label}>{secondLabel.label}</Typography>}
            {hasArrows && <ReactSVG src={MOBILE_ARROW_IMAGE} className={classes.blueArrow} />}
            {hasArrows && (
              <Typography className={classes.value}>
                {connected ? secondLabel.value : '-'}
                {valueUnit}
              </Typography>
            )}
            {secondPlaceholder.label && (
              <Typography className={classes.placeholder}>{secondPlaceholder.label}</Typography>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export default BoilerTempsRow
