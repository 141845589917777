import React from 'react'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { FormControlLabel } from '@mui/material'
import useStyles from './styles'

const NavbarCheckboxes = ({ values, onChange = () => {}, checkboxes, groupLabel, disabled = false }) => {
  const classes = useStyles()

  return (
    <>
      <div className={classes.groupLabel}>{groupLabel}</div>
      <div className={classes.checkboxContainer}>
        {checkboxes.map((checkbox) => (
          <FormControlLabel
            key={checkbox.id.toString()}
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                checkedIcon={<CheckBoxOutlinedIcon className={classes.checked} />}
                checked={values.indexOf(checkbox.enumName) > -1}
                onChange={onChange}
                className={classes.checkbox}
                disableRipple={true}
                value={checkbox.enumName}
                id={checkbox.id.toString()}
                disabled={disabled}
              />
            }
            className={classes.checkboxLabel}
            label={checkbox.name}
          />
        ))}
      </div>
    </>
  )
}

export default NavbarCheckboxes
