import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { LinearProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import CustomButton from 'components/CustomButton/CustomButton'
import FieldError from 'components/FieldError/FieldError'
import useCallService from 'services/useCallService'
import { DELETE_BOILER_BY_ID_URL } from 'Constants'
import useStyles from './styles'

const DeleteBoilerForm = ({ handleClose, onSuccess, boilerToDelete }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callBoilerService } = useCallService()
  const [serviceError, setServiceError] = useState(false)
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirm = async () => {
    if (boilerToDelete) {
      setIsLoading(true)
      try {
        await callBoilerService('DELETE', DELETE_BOILER_BY_ID_URL + boilerToDelete.id, {}, true)
        onSuccess()
        handleClose()
      } catch (error) {
        setServiceError(true)
        if (error.response && error.response.data.message) {
          setServiceErrorMessage(error.response.data.message)
        } else {
          setServiceErrorMessage(formatMessage({ id: 'Commons.genericError' }))
        }
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        {formatMessage(
          { id: 'Boilers.modal.deleteBoiler.message' },
          { 0: boilerToDelete ? boilerToDelete.serialNumber : '' }
        )}
      </div>
      <FieldError hasError={serviceError} errorMessage={serviceErrorMessage} />
      {isLoading && <LinearProgress className={classes.loader} />}
      <div className={classes.actions}>
        <div className={classes.backButton} onClick={handleClose}>
          <Typography>
            <FormattedMessage id={'Commons.button.cancel'} />
          </Typography>
        </div>
        <CustomButton labelId={'Commons.button.confirm'} disabled={isLoading} type={'button'} action={handleConfirm} />
      </div>
    </div>
  )
}

export default DeleteBoilerForm
