import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  formContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  filtersContainer: {
    marginBottom: 15
  },
  body: {
    flex: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(300px, 470px))',
    gridColumnGap: 45,
    gridRowGap: 0,
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, minmax(300px, 470px))'
    }
  },
  checkboxes: {
    marginTop: 20,
    display: 'flex',
    columnGap: '20px'
  },
  resultsContainer: {
    flex: 1
  },
  filtersRow: {
    height: '41px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `2px solid var(--secondaryshade-1, rgba(87, 125, 255, 0.60))`
  },
  tableTitle: {
    color: `${theme.palette.web.darkGrey} !important`,
    fontSize: '16px !important',
    fontFamily: 'Open Sans !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase !important'
  },
  rightHeaders: {
    display: 'flex',
    columnGap: '20px'
  },
  resultsRow: {
    marginTop: 20
  },
  exportButton: {
    width: 73,
    display: 'flex',
    alignItems: 'center !important',
    justifyContent: 'space-between !important',
    marginRight: 10,
    cursor: 'pointer',
    '& a': {
      display: 'flex',
      alignItems: 'center !important',
      justifyContent: 'space-between !important',
      color: `${theme.palette.web.blue} !important`,
      fontSize: '14px !important',
      fontWeight: '600 !important',
      textDecoration: 'none !important'
    }
  },
  smallWidth: {
    width: '5%'
  },
  mediumWidth: {
    width: '10%'
  },
  largeWidth: {
    width: '15%'
  }
}))
