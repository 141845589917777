import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  buttonContainer: {
    boxShadow: 'none',
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {}
  },
  profileButton: {
    [theme.breakpoints.up('sm')]: {
      height: 38,
      minWidth: 182,
      padding: '7px 6px 7px 8px',
      borderRadius: 6,
      backgroundColor: theme.palette.header.lightGrey,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.header.lightGrey
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      backgroundColor: theme.palette.web.white,
      '&:hover': {
        boxShadow: 'none',
        backgroundColor: theme.palette.web.white
      }
    }
  },
  profileIcon: {
    fontSize: 0,
    marginRight: 5,
    '& svg': {
      fill: theme.palette.header.icon
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      '& svg': {
        width: 30,
        height: 30
      }
    }
  },
  text: {
    color: theme.palette.header.darkGrey,
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none'
  },
  arrowIcon: {
    marginLeft: 5,
    fill: theme.palette.header.darkGrey
  }
}))
