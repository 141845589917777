import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const TCIndex = ({ index, text, values, blockClass, textClass, children }) => {
  const classes = useStyles()

  return (
    <div>
      <div className={classNames(classes.indexContainer, blockClass)}>
        <Typography className={classNames(classes.text, classes.index)}>{index}</Typography>
        <Typography className={classNames(classes.text, textClass)}>
          <FormattedMessage id={text} values={values} />
        </Typography>
      </div>
      {children && <div className={blockClass}>{children}</div>}
    </div>
  )
}

export default TCIndex
