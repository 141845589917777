import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import CustomButton from 'components/CustomButton/CustomButton'
import FieldError from 'components/FieldError/FieldError'
import ReadOnlyTextField from 'components/ReadOnlyTextField/ReadOnlyTextField'
import useCallService from 'services/useCallService'
import { valueOrDefault } from 'utils/Utils'
import { PUT_UPGRADE_TECHNICIAN_URL } from 'Constants'
import useStyles from './styles'

const TechForm = ({ handleClose, onSuccess, technicianData }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callSecurityService } = useCallService()
  const [serviceError, setServiceError] = useState(false)
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')

  const handleConfirm = async () => {
    if (technicianData) {
      try {
        await callSecurityService('PUT', PUT_UPGRADE_TECHNICIAN_URL + technicianData.id, {}, true)
        onSuccess()
        handleClose()
      } catch (error) {
        setServiceError(true)
        if (error.response && error.response.data.message) {
          setServiceErrorMessage(error.response.data.message)
        } else {
          setServiceErrorMessage(formatMessage({ id: 'Commons.genericError' }))
        }
      }
    }
  }

  return (
    <div className={classes.container}>
      <div className={classes.body}>
        <div>
          <ReadOnlyTextField
            labelId={'Users.technician.form.company'}
            value={valueOrDefault(technicianData, 'companyName', '')}
          />
          <ReadOnlyTextField
            labelId={'Users.technician.form.currentContractor'}
            value={`${valueOrDefault(technicianData, 'contractorName', '')} - ${valueOrDefault(
              technicianData,
              'contractorEmail',
              ''
            )}`}
          />
          <ReadOnlyTextField
            labelId={'Users.technician.form.newContractor'}
            value={`${valueOrDefault(technicianData, 'techName', '')} - ${valueOrDefault(
              technicianData,
              'techEmail',
              ''
            )}`}
          />
        </div>
        <div className={classes.line} />
      </div>
      <FieldError hasError={serviceError} errorMessage={serviceErrorMessage} />
      <div className={classes.actions}>
        <div className={classes.backButton} onClick={handleClose}>
          <Typography>
            <FormattedMessage id={'Commons.button.cancel'} />
          </Typography>
        </div>
        <CustomButton labelId={'Commons.button.confirm'} type={'button'} action={handleConfirm} />
      </div>
    </div>
  )
}

export default TechForm
