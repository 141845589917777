import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useIntl } from 'react-intl'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { Button, Typography } from '@mui/material'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import LoadMore from 'components/LoadMore/LoadMore'
import Loader from 'components/Loader/Loader'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import NoData from 'components/NoData/NoData'
import SearchBar from 'components/SearchBar/SearchBar'
import Table from 'components/Table/Table'
import useCallService from 'services/useCallService'
import { useDebounce } from 'utils/Utils'
import { usePartnersContext } from 'context/PartnersContext'
import { ALARM_HISTORY_FORM_CONFIRMATION_STEP_ID, REPORTS_STEP_ID } from 'data/ReportsData'
import { GET_BOILERS_MANAGEMENT_URL } from 'Constants'
import useStyles from './styles'

const AlarmHistoryFormStep = forwardRef(
  ({ step, changeStep = () => undefined, setNeedsToEval = () => undefined, alarms, alarmTypes, reportForm }, ref) => {
    const classes = useStyles()
    const { formatMessage } = useIntl()
    const pageSize = process.env.REACT_APP_DASHBOARD_PAGE_SIZE
    const [last, setLast] = useState(true)
    const [isLoading, setIsLoading] = useState(true)
    const [isLoadingMore, setIsLoadingMore] = useState(false)
    const { getPartners, getModelTypes } = usePartnersContext()
    const { callBoilerService } = useCallService()
    const [partners, setPartners] = useState([])
    const [modelTypes, setModelTypes] = useState([])
    const [boilersList, setBoilersList] = useState([])
    const [parameters, setParameters] = useState({
      search: '',
      page: 0,
      sortBy: 'serialNumber',
      sorting: 'ASC',
      partners: [],
      modelTypes: []
    })
    const debouncedParameters = useDebounce(parameters, 500)

    useEffect(() => {
      async function fetchPartners() {
        return getPartners()
      }

      fetchPartners().then((response) => {
        setPartners(response)
        setParameters((currentParameters) => ({
          search: currentParameters.search,
          page: currentParameters.page,
          sortBy: currentParameters.sortBy,
          sorting: currentParameters.sorting,
          partners: response.map((el) => el.enumName),
          modelTypes: currentParameters.modelTypes
        }))
      })
    }, [getPartners])

    useEffect(() => {
      async function fetchModelTypes() {
        return getModelTypes()
      }

      fetchModelTypes().then((response) => {
        setModelTypes(response)
        setParameters((currentParameters) => ({
          search: currentParameters.search,
          page: currentParameters.page,
          sortBy: currentParameters.sortBy,
          sorting: currentParameters.sorting,
          partners: currentParameters.partners,
          modelTypes: response.map((el) => el.enumName)
        }))
      })
    }, [getModelTypes])

    const loadBoilers = () => {
      setIsLoading(true)

      async function fetchBoilers() {
        return callBoilerService(
          'GET',
          `${GET_BOILERS_MANAGEMENT_URL}?size=${pageSize}&page=${parameters.page}&sortBy=${parameters.sortBy}&sorting=${
            parameters.sorting
          }${
            parameters.search ? `&searchString=${encodeURIComponent(parameters.search)}` : ''
          }&partners=${parameters.partners.filter(Boolean).join(',')}&types=${parameters.modelTypes
            .filter(Boolean)
            .join(',')}`,
          {},
          true
        )
      }

      if (partners.length !== 0) {
        fetchBoilers()
          .then((response) => {
            setLast(response.data.last)
            if (parameters.page === 0) {
              setBoilersList(response.data.data)
            } else {
              setBoilersList(boilersList.concat(response.data.data))
            }
          })
          .finally(() => {
            setIsLoading(false)
            setIsLoadingMore(false)
          })
      }
    }

    useEffect(() => {
      loadBoilers()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedParameters])

    const apply = () => {
      if (reportForm.isValid) {
        setNeedsToEval(true)
        reportForm.setFieldValue('filters', parameters)
        changeStep(ALARM_HISTORY_FORM_CONFIRMATION_STEP_ID)
      }
    }

    const back = () => {
      changeStep(REPORTS_STEP_ID)
    }

    const reset = () => {
      reportForm.resetForm()
      setParameters(() => ({
        search: '',
        page: 0,
        sortBy: 'serialNumber',
        sorting: 'ASC',
        partners: partners.map((el) => el.enumName),
        modelTypes: modelTypes.map((el) => el.enumName)
      }))
      reportForm.setFieldValue('alarmType', 'ALL')
      reportForm.setFieldValue('boilers', [])
      reportForm.setFieldValue('allSelected', false)
      reportForm.setFieldValue('boilersFullList', [])
      reportForm.setFieldValue('filters', null)
    }

    const search = (e) => {
      setParameters((currentParameters) => ({
        search: e.target.value,
        page: currentParameters.page,
        sortBy: currentParameters.sortBy,
        sorting: currentParameters.sorting,
        partners: currentParameters.partners,
        modelTypes: currentParameters.modelTypes
      }))
    }

    const loadMore = async () => {
      setIsLoadingMore(true)
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page + 1,
        sortBy: currentParameters.sortBy,
        sorting: currentParameters.sorting,
        partners: currentParameters.partners,
        modelTypes: currentParameters.modelTypes
      }))
    }

    const checkPartners = (e) => {
      if (e.target.checked) {
        parameters.partners[e.target.id - 1] = e.target.value
      } else {
        parameters.partners[e.target.id - 1] = null
      }
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page,
        sortBy: currentParameters.sortBy,
        sorting: currentParameters.sorting,
        partners: currentParameters.partners,
        modelTypes: currentParameters.modelTypes
      }))
    }

    const checkModelTypes = (e) => {
      if (e.target.checked) {
        parameters.modelTypes[e.target.id] = e.target.value
      } else {
        parameters.modelTypes[e.target.id] = null
      }
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page,
        sortBy: currentParameters.sortBy,
        sorting: currentParameters.sorting,
        partners: currentParameters.partners,
        modelTypes: currentParameters.modelTypes
      }))
    }

    const handleSort = (order, orderBy) => {
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page,
        sortBy: orderBy,
        sorting: order.toUpperCase(),
        partners: currentParameters.partners,
        modelTypes: currentParameters.modelTypes
      }))
    }

    const handleSelect = (selectedBoiler, checked) => {
      const elemIndex = reportForm.values.boilers.findIndex((boiler) => selectedBoiler.id === boiler.id)
      const isChecked = elemIndex > -1
      if (checked && !isChecked) {
        reportForm.values.boilers.push(selectedBoiler)
      }
      if (!checked && isChecked) {
        reportForm.values.boilers.splice(elemIndex, 1)
      }
      reportForm.setFieldValue('boilers', reportForm.values.boilers)
    }

    const handleSelectAll = (checked) => {
      reportForm.setFieldValue('allSelected', checked)
    }

    useImperativeHandle(ref, () => ({
      reset
    }))

    const columns = [
      { id: 'serialNumber', sorting: true, label: formatMessage({ id: 'Reports.form.boilerSerialNumber' }) },
      { id: 'model', sorting: true, label: formatMessage({ id: 'Reports.form.model' }) },
      { id: 'installer', sorting: true, label: formatMessage({ id: 'Reports.form.installer' }) },
      { id: 'company', sorting: true, label: formatMessage({ id: 'Reports.form.company' }) },
      {
        id: 'createdDate',
        sorting: true,
        label: formatMessage({ id: 'Reports.form.createdDate' }),
        type: 'date'
      }
    ]

    return (
      <React.Fragment key={step}>
        <CustomForm form={reportForm} className={classes.formContainer}>
          <div className={classes.gridContainer}>
            <div className={classes.filtersContainer}>
              <div className={classes.body}>
                <CustomSelect
                  form={reportForm}
                  formFieldName={'alarmType'}
                  labelId={'Reports.form.alarmType'}
                  items={alarmTypes}
                  disabled={false}
                />
                <CustomSelect
                  form={reportForm}
                  formFieldName={'alarm'}
                  labelId={'Reports.form.alarm'}
                  isMultiple={true}
                  items={alarms}
                  disabled={false}
                />
              </div>
            </div>
            <div className={classes.resultsContainer}>
              <div className={classes.filtersRow}>
                <Typography className={classes.tableTitle}>
                  {formatMessage({ id: 'Reports.alarmHistory.selectBoilers' })}
                </Typography>
                <div className={classes.rightHeaders}>
                  <NavbarCheckboxes
                    values={parameters.modelTypes}
                    checkboxes={modelTypes}
                    onChange={checkModelTypes}
                    groupLabel={formatMessage({ id: 'Reports.form.type' })}
                  />
                  <div />
                  <NavbarCheckboxes
                    values={parameters.partners}
                    checkboxes={partners}
                    onChange={checkPartners}
                    groupLabel={formatMessage({ id: 'Reports.form.brands' })}
                  />
                  <SearchBar
                    value={parameters.search}
                    placeholderId={'Reports.alarmHistory.search'}
                    onChange={search}
                  />
                </div>
              </div>
              <div className={classes.resultsRow}>
                {isLoading && <Loader />}
                {!isLoading && boilersList.length === 0 && <NoData />}
                {!isLoading && boilersList.length > 0 && (
                  <Table
                    checkboxSelection={true}
                    columns={columns}
                    data={boilersList}
                    defaultOrderKey={parameters.sortBy}
                    defaultOrder={parameters.sorting.toLowerCase()}
                    onSortChange={handleSort}
                    onSelectAll={handleSelectAll}
                    onSelect={handleSelect}
                    selectedItems={reportForm.values.boilers}
                  />
                )}
                {!isLoading && !last && <LoadMore loadMore={loadMore} isLoading={isLoadingMore} />}
              </div>
            </div>
          </div>
          <div className={classes.bottom}>
            <Button onClick={back} className={classes.transitionButton}>
              <KeyboardArrowLeft /> {formatMessage({ id: 'Reports.actions.back' })}
            </Button>
            <div>
              <Button onClick={reset} className={classes.secondaryButton}>
                {formatMessage({ id: 'Reports.actions.clear' })}
              </Button>
              <Button
                onClick={apply}
                className={classes.primaryButton}
                disabled={!reportForm.isValid || reportForm.values.boilers.length === 0}
              >
                {formatMessage({ id: 'Reports.actions.apply' })}
              </Button>
            </div>
          </div>
        </CustomForm>
      </React.Fragment>
    )
  }
)

export default AlarmHistoryFormStep
