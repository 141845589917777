import React from 'react'
import { ReactSVG } from 'react-svg'
import { useSnackbar } from 'notistack'
import IconButton from '@material-ui/core/IconButton'
import closeIcon from 'assets/images/icons-close.svg'

const CloseButton = ({ id }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton onClick={() => closeSnackbar(id)}>
      <ReactSVG src={closeIcon} />
    </IconButton>
  )
}

export default CloseButton
