import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Table from 'components/Table/Table'
import { useMobileContext } from 'context/MobileContext'
import NoData from '../NoData/NoData'
import useStyles from './styles'

const AlarmsTab = ({ alarms }) => {
  const [categoryFilter, setCategoryFilter] = useState('active')
  const { formatMessage } = useIntl()
  const classes = useStyles()
  const { isMobile } = useMobileContext()

  const changeCategoryFilter = (value) => {
    setCategoryFilter(value)
  }

  const columns = [
    {
      sorting: true,
      id: 'status',
      secondaryId: 'type',
      label: formatMessage({ id: 'BoilerStatus.alarms.column.type' }),
      width: classes.smallWidth
    },
    {
      sorting: true,
      id: 'description',
      label: formatMessage({ id: 'BoilerStatus.alarms.column.alarm' }),
      width: classes.mediumWidth
    },
    {
      id: 'errorDescription',
      label: formatMessage({ id: 'BoilerStatus.alarms.column.description' }),
      width: classes.largeWidth
    }
  ]

  if (alarms.length === 0) {
    return <NoData />
  }

  return (
    <div>
      <div className={classes.headerContainer}>
        <div className={classes.buttonsContainer}>
          <div onClick={() => changeCategoryFilter('active')}>
            <span
              className={classNames(classes.filterButton, {
                [classes.activeFilterButton]: categoryFilter === 'active'
              })}
            >
              {formatMessage({ id: 'BoilerStatus.alarms.button.active' })}
            </span>
          </div>
          <div onClick={() => changeCategoryFilter('history')}>
            <span
              className={classNames(classes.filterButton, {
                [classes.activeFilterButton]: categoryFilter === 'history'
              })}
            >
              {formatMessage({ id: 'BoilerStatus.alarms.button.history' })}
            </span>
          </div>
        </div>
      </div>
      {!isMobile ? (
        <Table
          columns={columns}
          data={
            categoryFilter === 'active'
              ? alarms.filter((alarm) => alarm.active === true)
              : alarms.filter((alarm) => alarm.active === false)
          }
        />
      ) : (
        <div className={classes.itemsList}>
          {alarms
            .filter((alarm) => alarm.active === (categoryFilter === 'active'))
            .map((al, index) => (
              <div key={index} className={classes.tableRowSimple}>
                <div className={classes.primaryLine}>
                  {al.status} {al.type} - {al.description}
                </div>
                <div className={classes.secondaryLine}>{al.errorDescription}</div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default AlarmsTab
