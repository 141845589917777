import React from 'react'
import useStyles from './styles'

const ReportsStepper = ({ activeStep = '', children }) => {
  const classes = useStyles()
  const childrenArray = React.Children.toArray(children)

  return (
    <>
      {childrenArray.map((child, index) => (
        <div
          key={index}
          className={classes.stepContainer}
          style={{ display: child.props.step === activeStep ? 'flex' : 'none' }}
        >
          {child}
        </div>
      ))}
    </>
  )
}

export default ReportsStepper
