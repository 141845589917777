import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.web.white,
    border: `1px solid ${theme.palette.web.lightGrey}`,
    color: theme.palette.web.darkGrey,
    height: 36,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.web.darkRed}`,
      color: theme.palette.web.red
    },
    '&.Mui-disabled': {
      backgroundColor: 'var(--shades-1, #F7F7F7) !important'
    }
  },
  input: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.44px'
  },
  label: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.38px',
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase',
    '&.Mui-focused, &.Mui-error': {
      color: theme.palette.web.usbGrey
    }
  },
  shrink: {
    transform: 'scale(0.8) !important'
  },
  errorMessage: {
    position: 'absolute',
    top: '60px',
    color: theme.palette.web.red,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.16px',
    lineHeight: 'normal'
  },
  select: {
    padding: '0px 6px 0px 12px',
    display: 'flex',
    alignItems: 'center',
    height: 34,
    '&:focus': {
      backgroundColor: theme.palette.web.white
    }
  },
  icon: {
    width: 24,
    height: 24,
    borderLeft: `1px solid ${theme.palette.web.lightGrey}`,
    fill: theme.palette.web.lightGrey
  },
  paper: {
    border: `1px solid ${theme.palette.web.lightGrey}`,
    boxShadow: 'none',
    borderRadius: 2,
    backdropFilter: 'blur(5px)',
    backgroundColor: theme.palette.web.dropdownColor
  },
  menuItem: {
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.web.darkGrey,
    '&.Mui-selected, &.Mui-selected:hover, &:hover': {
      backgroundColor: 'transparent'
    }
  },
  checked: {
    color: theme.palette.web.blue
  },
  checkbox: {
    marginRight: 5,
    padding: 0,
    color: theme.palette.web.lightGrey,
    '&.Mui-checked:hover, &:hover': {
      backgroundColor: 'transparent'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'nowrap'
  },
  chip: {
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: '-0.34px',
    backgroundColor: theme.palette.web.chipBlue,
    color: theme.palette.web.blue,
    borderRadius: 4,
    margin: 2,
    maxWidth: 129,
    '& svg': {
      width: 10,
      height: 10,
      fill: theme.palette.web.blue
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.web.chipBlue
    },
    '&.Mui-disabled': {
      opacity: 1
    }
  },
  deleteChips: {
    right: '8%',
    position: 'absolute',
    width: 14,
    height: 14,
    color: theme.palette.web.lightGrey
  },
  pointer: {
    cursor: 'pointer'
  }
}))
