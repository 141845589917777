import React, { useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import shortId from 'shortid'
import classNames from 'classnames'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import MaterialTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Typography from '@material-ui/core/Typography'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { getComparator, sortArray } from 'utils/Utils'
import useStyles from './styles'

const Table = ({
  columns,
  defaultOrderKey = '',
  defaultOrder = 'asc',
  data = [],
  actions,
  onSortChange = () => undefined,
  className = {},
  checkboxSelection = false,
  onSelectAll = () => undefined,
  onSelect = () => undefined,
  selectedItems = []
}) => {
  const [orderBy, setOrderBy] = React.useState(defaultOrderKey)
  const [order, setOrder] = React.useState(defaultOrder)
  const classes = useStyles()

  const sortHandler = (property) => () => {
    const isAsc = orderBy === property && order === 'asc'
    const orderValue = isAsc ? 'desc' : 'asc'
    setOrder(orderValue)
    setOrderBy(property)
    onSortChange(orderValue, property)
  }

  const selectAll = (e) => {
    onSelectAll(e.target.checked)
    sortedData.map((value) => onSelect(value, e.target.checked))
  }

  const buildRowValue = (column, row) =>
    column.secondaryId && row[column.secondaryId] ? `${row[column.id]} ${row[column.secondaryId]}` : row[column.id]

  const buildDate = (column, row) => (row[column.id] !== null ? moment(row[column.id]).format('L LTS') : '')

  const renderSingleAction = (action, row) => {
    const actionHide =
      action.visible !== undefined &&
      ((action.visible instanceof Function && action.visible(row) === true) ||
        (!(action.visible instanceof Function) && action.visible))
    const actionDisabled =
      action.disabled !== undefined &&
      ((action.disabled instanceof Function && action.disabled(row) === true) ||
        (!(action.disabled instanceof Function) && action.disabled))
    return (
      !actionHide && (
        <div key={shortId.generate()} onClick={() => (!actionDisabled ? action.onClick(row) : undefined)}>
          <Typography
            className={classNames(classes.actionButton, { [classes.actionDisabled]: actionDisabled }, action.className)}
          >
            {action.label instanceof Function ? action.label(row) : action.label}
          </Typography>
        </div>
      )
    )
  }

  const renderActions = (row) => (
    <TableCell key="actions" className={classNames(classes.tableCell, classes.smallCell)}>
      <div className={classNames(classes.actionsContainer, { [className.actionsContainer]: className })}>
        {actions.map((action) => renderSingleAction(action, row))}
      </div>
    </TableCell>
  )

  const renderCellByValue = (column, row) =>
    column.type === 'date' ? buildDate(column, row) : buildRowValue(column, row)

  const renderCellByRender = (column, row) => column.render(row)

  const renderCellValue = (column, row) =>
    column.render ? renderCellByRender(column, row) : renderCellByValue(column, row)

  const renderCell = (column, row) => (
    <TableCell
      key={column.id}
      className={classNames(classes.tableCell, classes.mediumCell, {
        [classes.sortedData]: orderBy === column.id,
        [column.className]: column.className
      })}
    >
      {renderCellValue(column, row)}
    </TableCell>
  )

  const renderCheckBox = (row) => (
    <TableCell key={row.id} className={classNames(classes.tableCell)}>
      <Checkbox
        icon={<CheckBoxOutlineBlankOutlinedIcon />}
        checkedIcon={<CheckBoxOutlinedIcon className={classes.checked} />}
        className={classes.checkbox}
        disableRipple={true}
        onChange={(e) => onSelect(row, e.target.checked)}
        checked={selectedItems.includes(row)}
      />
    </TableCell>
  )

  const renderRow = (row) => (
    <TableRow key={shortId.generate()}>
      {checkboxSelection && renderCheckBox(row)}
      {columns.map((column) => renderCell(column, row))}
      {actions ? renderActions(row) : null}
    </TableRow>
  )

  const renderActionHeader = () => (
    <TableCell key={'actions'} className={classNames(classes.tableCell, classes.tableHeader)}>
      <FormattedMessage id="BoilerStatus.archives.column.actions" />
    </TableCell>
  )

  const sortedData = useMemo(
    () => (onSortChange ? data : sortArray(data, getComparator(order, orderBy))),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, order, orderBy]
  )

  const allChecked = sortedData ? sortedData.length === selectedItems.length : false

  useEffect(() => {
    if (checkboxSelection === true && allChecked === false) {
      onSelectAll(allChecked)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allChecked])

  return (
    <MaterialTable className={classNames({ [className.table]: className })}>
      <TableHead>
        <TableRow>
          {checkboxSelection && (
            <TableCell
              key={shortId.generate()}
              className={classNames(classes.tableCell, classes.tableHeader, classes.checkboxCell)}
            >
              <Checkbox
                icon={<CheckBoxOutlineBlankOutlinedIcon />}
                checkedIcon={<CheckBoxOutlinedIcon className={classes.checked} />}
                className={classes.checkbox}
                disableRipple={true}
                onChange={selectAll}
                checked={allChecked}
              />
            </TableCell>
          )}
          {columns.map((column) => (
            <TableCell
              key={column.id}
              className={classNames(classes.tableCell, classes.tableHeader, { [column.width]: column.width })}
              sortDirection={orderBy === column.id ? order : false}
            >
              {column.sorting ? (
                <TableSortLabel
                  active={true}
                  direction={orderBy === column.id ? order : 'asc'}
                  IconComponent={ArrowDropUpIcon}
                  onClick={sortHandler(column.id)}
                  className={classNames(classes.sortIcon, { [classes.sortedColumn]: orderBy === column.id })}
                >
                  {column.label}
                </TableSortLabel>
              ) : (
                <>{column.label}</>
              )}
            </TableCell>
          ))}
          {actions ? renderActionHeader() : null}
        </TableRow>
      </TableHead>
      <TableBody>{sortedData.map(renderRow)}</TableBody>
    </MaterialTable>
  )
}

export default Table
