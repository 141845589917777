import React from 'react'
import DeleteBoilerForm from 'components/DeleteBoilerForm/DeleteBoilerForm'
import Modal from 'components/Modal/Modal'

const DeleteBoilerModal = ({ open = false, handleClose, onSuccess, title, boilerToDelete }) => (
  <Modal open={open} handleClose={handleClose} title={title} fullWidth={false}>
    <DeleteBoilerForm handleClose={handleClose} onSuccess={onSuccess} boilerToDelete={boilerToDelete} />
  </Modal>
)

export default DeleteBoilerModal
