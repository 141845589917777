import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout/Layout'
import UsersPanel from 'components/UsersPanel/UsersPanel'

const Users = () => {
  const title = useIntl().formatMessage({ id: 'Users.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout>
        <UsersPanel />
      </Layout>
    </>
  )
}

export default Users
