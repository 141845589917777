import React, { useEffect, useState } from 'react'
import Loader from 'components/Loader/Loader'
import Modal from 'components/Modal/Modal'
import TechForm from 'components/TechForm/TechForm'
import useCallService from 'services/useCallService'
import { buildName } from 'utils/Utils'
import { GET_TECHNICIAN_DATA_URL } from 'Constants'
import useStyles from './styles'

const TechnicianModal = ({ open = false, handleClose, onSuccess, title, techId }) => {
  const classes = useStyles()
  const { callSecurityService } = useCallService()
  const [technicianData, setTechnicianData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchTechnician() {
      setIsLoading(true)
      return callSecurityService('GET', GET_TECHNICIAN_DATA_URL + techId, {}, true)
    }

    if (techId) {
      fetchTechnician()
        .then((response) => {
          const { data } = response
          const tech = {
            id: data.id,
            companyName: data.companyName,
            techName: buildName(data.technicianName, data.technicianSurname),
            techEmail: data.technicianEmail,
            contractorName: buildName(data.contractorName, data.contractorSurname),
            contractorEmail: data.contractorEmail
          }
          setTechnicianData(tech)
        })
        .finally(() => setIsLoading(false))
    } else {
      setTechnicianData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal open={open} handleClose={handleClose} title={title} fullWidth={true} classNameModal={classes.modal}>
      {isLoading ? (
        <Loader />
      ) : (
        <TechForm handleClose={handleClose} onSuccess={onSuccess} technicianData={technicianData} />
      )}
    </Modal>
  )
}

export default TechnicianModal
