import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: 140,
    maxWidth: 203,
    backgroundColor: `var(--shadeswhite, ${theme.palette.web.white})`,
    '&:hover': {
      backgroundColor: theme.palette.web.lightBlue,
      '& div': {
        color: theme.palette.web.white
      },
      '& svg': {
        fill: theme.palette.web.white
      }
    },
    borderRadius: 8,
    border: '2px solid var(--secondaryshade-2, rgba(87, 125, 255, 0.10))',
    overflow: 'hidden',
    cursor: 'pointer'
  },
  selected: {
    backgroundColor: theme.palette.web.blue,
    '& div': {
      color: theme.palette.web.white
    },
    '& div>svg': {
      fill: theme.palette.web.white
    }
  },
  dataContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 16,
    display: 'inline-flex'
  },
  icon: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.blue
    }
  },
  label: {
    margin: '0px 20px',
    color: theme.palette.web.darkGrey,
    fontSize: 18,
    fontWeight: 600,
    wordWrap: 'break-word',
    textAlign: 'center'
  }
}))
