import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  headerMenuButton: {
    fontSize: 0,
    padding: 5,
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    '& svg': {
      fill: theme.palette.header.icon
    }
  }
}))
