import React, { memo } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import BoilerDataRow from 'components/BoilerDataRow/BoilerDataRow'
import BoilerImage from 'components/BoilerImage/BoilerImage'
import ConnectionBadge from 'components/ConnectionBadge/ConnectionBadge'
import ConnectionStatus from 'components/ConnectionStatus/ConnectionStatus'
import { buildName, isBoilerConnected } from 'utils/Utils'
import { BOILER_STATUS, CONNECTION_LOCAL, CONNECTION_REMOTE } from 'Constants'
import useStyles from './styles'

const DashboardCard = ({ boilerData }) => {
  const classes = useStyles()
  const history = useHistory()
  const {
    id,
    model,
    serialNumber,
    remote,
    type,
    subType,
    green,
    lastSeen,
    homeOwnerFirstName,
    homeOwnerLastName,
    companyName,
    technicianName,
    representativeName,
    boilerImage
  } = boilerData
  const connected = isBoilerConnected(lastSeen)

  const redirectToBoilerStatus = () => {
    history.push(`${BOILER_STATUS}/${id}`)
  }

  return (
    <div className={classes.card} onClick={redirectToBoilerStatus}>
      <div className={classes.cardHeader}>
        <div className={classes.connection}>
          <ConnectionStatus
            connected={connected}
            lastSeen={lastSeen}
            connectionType={remote ? CONNECTION_REMOTE : CONNECTION_LOCAL}
          />
        </div>
      </div>
      <div className={classes.cardBody}>
        <div className={classNames(classes.image, { [classes.imageBackground]: !boilerImage })}>
          <BoilerImage imageUrl={boilerImage} height={88} genericHeight={75} />
        </div>
        <div className={classes.dataRows}>
          <BoilerDataRow label={'Dashboard.panel.prop.model'} value={model} />
          <BoilerDataRow label={'Dashboard.panel.prop.serial'} value={serialNumber} />
          <BoilerDataRow
            label={'Dashboard.panel.prop.owner'}
            value={buildName(homeOwnerFirstName, homeOwnerLastName)}
          />
          <BoilerDataRow label={'Dashboard.panel.prop.installer'} value={companyName} optional={technicianName} />
          <BoilerDataRow label={'Dashboard.panel.prop.representative'} value={representativeName} />
        </div>
      </div>
      <ConnectionBadge type={type} connected={connected} subType={subType} green={green} />
    </div>
  )
}

export default memo(DashboardCard)
