import ICON_REPORTS from 'assets/images/icons-reports.svg'
import {
  HISTORY_ALARMS_REPORT_PERMISSION,
  ENROLLED_AUDIENCE_REPORT_PERMISSION,
  USERS_WITH_BOILERS_REPORT_PERMISSION,
  TIME_SINCE_LAST_CONNECTION_REPORT_PERMISSION
} from 'Constants'

export const REPORTS_STEP_ID = 'reportsStep'
//Alarm History Steps
export const ALARM_HISTORY_FORM_STEP_ID = 'alarmHistoryFormStep'
export const ALARM_HISTORY_FORM_CONFIRMATION_STEP_ID = 'alarmHistoryFormConfirmationStep'
export const ALARM_HISTORY_REPORT_STEP_ID = 'alarmHistoryReportStep'
//Enrolled Audience Steps
export const ENROLLED_AUDIENCE_FORM_STEP_ID = 'enrolledAudienceFormStep'
export const ENROLLED_AUDIENCE_FORM_CONFIRMATION_STEP_ID = 'enrolledAudienceFormConfirmationStep'
export const ENROLLED_AUDIENCE_REPORT_STEP_ID = 'enrolledAudienceReportStep'

export const reportsList = [
  {
    id: 1,
    name: 'Reports.alarmHistory.name',
    icon: ICON_REPORTS,
    firstStep: ALARM_HISTORY_FORM_STEP_ID,
    permissions: HISTORY_ALARMS_REPORT_PERMISSION
  },
  {
    id: 2,
    name: 'Reports.enrolledAudience.name',
    icon: ICON_REPORTS,
    firstStep: ENROLLED_AUDIENCE_FORM_STEP_ID,
    permissions: ENROLLED_AUDIENCE_REPORT_PERMISSION
  },
  {
    id: 3,
    name: 'Reports.usersWithBoilers.name',
    icon: ICON_REPORTS,
    firstStep: null,
    permissions: USERS_WITH_BOILERS_REPORT_PERMISSION
  },
  {
    id: 4,
    name: 'Reports.timeSinceLastConnection.name',
    icon: ICON_REPORTS,
    firstStep: null,
    permissions: TIME_SINCE_LAST_CONNECTION_REPORT_PERMISSION
  }
]
