import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    padding: '24px 33px 0px 33px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  containerHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  rightHeaders: {
    display: 'flex',
    columnGap: '20px'
  },
  dataContainer: {
    flex: 1,
    width: '100%',
    marginTop: 25
  },
  tabsHeader: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: `solid 1px ${theme.palette.web.usbGrey};`
  },
  indicator: {
    height: 6,
    backgroundColor: theme.palette.web.blue
  },
  scroller: {
    flex: '0 0 auto'
  },
  fixed: {
    width: 'auto'
  },
  tab: {
    minWidth: 113,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 20
  },
  textWrapper: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.web.usbGrey
  },
  selected: {
    '& span': {
      fontWeight: 800,
      color: theme.palette.web.darkGrey
    }
  },
  tabIcons: {
    marginBottom: 10,
    flex: 1,
    textAlign: 'end',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  icon: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.blue,
      width: 24,
      height: 24
    }
  },
  iconPadding: {
    marginLeft: 30,
    padding: '0px 8px 0px 0px'
  },
  tabContainer: {
    height: '100%',
    padding: '23px 30px',
    backgroundColor: 'white',
    borderRadius: 8,
    boxShadow: `0 2px 4px 0 ${theme.palette.web.cardShadow}`
  }
}))
