import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  leftBox: {
    [theme.breakpoints.up('sm')]: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      height: 270,
      borderRadius: 6,
      boxShadow: `0 2px 4px 0 ${theme.palette.web.cardShadow}`,
      backgroundColor: theme.palette.web.white
    },
    [theme.breakpoints.down('sm')]: {
      display: 'flex'
    }
  },
  image: {
    marginRight: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center'
  },
  imageBackground: {
    width: 270,
    height: 270,
    borderBottomLeftRadius: 6,
    borderTopLeftRadius: 6,
    backgroundColor: theme.palette.web.transparentGrey
  },
  dataRows: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      padding: '15px 20px'
    }
  },
  mobileGreyRow: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.web.greyRow,
      borderRadius: 4,
      padding: '5px 8px',
      height: 24
    }
  },
  mobileWhiteRow: {
    [theme.breakpoints.down('sm')]: {
      padding: '5px 8px',
      height: 24
    }
  }
}))
