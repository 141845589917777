import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import { LinearProgress } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import ChipsList from 'components/ChipsList/ChipsList'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import FieldError from 'components/FieldError/FieldError'
import SearchBar from 'components/SearchBar/SearchBar'
import useCallService from 'services/useCallService'
import { bytesToMB, stringLike, useDebounce, validateFirmwareVersionMask } from 'utils/Utils'
import { GET_GATEWAYS_URL, UPLOAD_FIRMWARE } from 'Constants'
import useStyles from './styles'

const FirmwareForm = ({ handleClose, onSuccess }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callGatewayService, callFileStorageService } = useCallService()
  const [gateway, setGateway] = useState('')
  const [listedGateways, setListedGateways] = useState([])
  const [gatewaysList, setGatewaysList] = useState([])
  const hiddenFileInput = useRef(null)
  const [serviceError, setServiceError] = useState(false)

  const debouncedParameters = useDebounce(gateway, 500)

  yup.addMethod(yup.string, 'validateFirmwareVersionMask', function () {
    return this.test('validate-firmware-version-mask', formatMessage({ id: 'Commons.error.firmware.mask' }), (value) =>
      validateFirmwareVersionMask(value)
    )
  })

  const authValidationSchema = yup.object({
    firmwareVersion: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Firmware.modal.firmwareVersion.error.required' }))
      .validateFirmwareVersionMask(),
    releaseNotes: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Firmware.modal.releaseNotes.error.required' })),
    selectedGatewaysList: yup.array().min(1, formatMessage({ id: 'Firmware.modal.selectedGatewaysList.error.min' })),
    selectedFile: yup.mixed().required(formatMessage({ id: 'Firmware.modal.selectedFile.error.required' }))
  })

  const authForm = useFormik({
    initialValues: {
      firmwareVersion: '',
      releaseNotes: '',
      selectedGatewaysList: [],
      selectedFile: null
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      setServiceError(false)
      const firmwareUploadRequest = {
        gatewaysIds: values.selectedGatewaysList.map((gw) => gw.id),
        firmwareVersion: values.firmwareVersion.trim(),
        releaseNotes: values.releaseNotes
      }
      const data = new FormData()
      data.append('request', JSON.stringify(firmwareUploadRequest))
      data.append('file', values.selectedFile)
      try {
        await callFileStorageService('POST', UPLOAD_FIRMWARE, { data }, true)
        onSuccess()
        handleClose()
      } catch (error) {
        setServiceError(true)
      }
    }
  })

  useEffect(() => {
    async function fetchGateways() {
      return callGatewayService('GET', GET_GATEWAYS_URL, {}, true)
    }
    fetchGateways().then((response) => {
      setGatewaysList(response.data)
      setListedGateways(response.data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const newSelectedFiles = authForm.values.selectedGatewaysList
    setListedGateways(
      gatewaysList.filter(
        (gw) => stringLike(gw.name, gateway) && newSelectedFiles.filter((value) => value.id === gw.id).length === 0
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParameters])

  const handleInputChange = (e) => setGateway(e.target.value)

  const sortByName = (a, b) => {
    if (a.name < b.name) {
      return -1
    }
    if (a.name > b.name) {
      return 1
    }
    return 0
  }

  const handleAddChip = (itemToAdd) => {
    const newList = listedGateways.filter((item) => item.id !== itemToAdd.id)
    setListedGateways(newList)
    const newSelectedFiles = authForm.values.selectedGatewaysList
    if (newSelectedFiles.filter((item) => item.id === itemToAdd.id).length === 0) {
      newSelectedFiles.push(itemToAdd)
      authForm.setFieldValue('selectedGatewaysList', newSelectedFiles)
    }
  }

  const handleDeleteChip = (itemToDelete) => {
    const newList = authForm.values.selectedGatewaysList.filter((item) => item.id !== itemToDelete.id)
    authForm.setFieldValue('selectedGatewaysList', newList)
    listedGateways.push(itemToDelete)
  }

  const handleSelect = () => {
    hiddenFileInput.current.click()
  }

  const handleChange = (event) => {
    const fileSelected = event.target.files[0]
    authForm.setFieldValue('selectedFile', fileSelected)
  }

  return (
    <div className={classes.container}>
      <CustomForm form={authForm}>
        <div className={classes.boxContainer}>
          <CustomTextField
            form={authForm}
            formFieldName={'firmwareVersion'}
            labelId={'Firmware.modal.firmwareVersion'}
            labelClasses={{ formControl: classes.label }}
            helperTextClasses={classes.helperTextPosition}
            inputClasses={{
              formControl: classes.formControl,
              root: classNames(classes.root, classes.inputHeight),
              input: classes.input
            }}
          />
          <CustomTextField
            form={authForm}
            formFieldName={'releaseNotes'}
            labelId={'Firmware.modal.releaseNotes'}
            multiline={true}
            rows={2}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{
              formControl: classes.formControl,
              root: classNames(classes.root, classes.textareaHeight),
              input: classNames(classes.input, classes.textarea)
            }}
          />
          <div hidden={!authForm.values.selectedFile}>
            <Typography className={classes.selectedFile}>
              <FormattedMessage
                id={'Firmware.modal.selectedFile'}
                values={{
                  0: authForm.values.selectedFile ? authForm.values.selectedFile.name : '',
                  1: authForm.values.selectedFile ? bytesToMB(authForm.values.selectedFile.size) : 0
                }}
              />
            </Typography>
          </div>
          <FieldError
            hasError={authForm.touched.selectedFile && authForm.errors.selectedFile}
            errorMessage={authForm.errors.selectedFile}
          />
          <Button fullWidth className={classes.selectButton} onClick={handleSelect}>
            <Typography className={classes.buttonLabel}>
              <FormattedMessage id={'Firmware.modal.selectFile'} />
            </Typography>
          </Button>
          <input
            type={'file'}
            ref={hiddenFileInput}
            onChange={handleChange}
            accept={process.env.REACT_APP_GATEWAY_FIRMWARE_EXTENSION}
            style={{ display: 'none' }}
          />
        </div>
        <div className={classes.boxContainer}>
          <Typography className={classes.title}>
            <FormattedMessage id={'Firmware.modal.gateways'} />
          </Typography>
          <SearchBar
            value={gateway}
            onChange={handleInputChange}
            placeholderId={'Firmware.empty'}
            persistOpened={true}
            fullWidth
            rootClassName={classes.searchBarRoot}
          />
          <ChipsList
            list={listedGateways.sort(sortByName)}
            icon={<AddIcon className={classes.addIcon} />}
            onAction={handleAddChip}
            chipClassName={classes.addChip}
          />
          <Typography className={classes.title}>
            <FormattedMessage id={'Firmware.modal.updateQueue'} />
          </Typography>
          <ChipsList
            list={authForm.values.selectedGatewaysList.sort(sortByName)}
            icon={<CloseIcon className={classes.deleteIcon} />}
            onAction={handleDeleteChip}
            chipClassName={classes.deleteChip}
          />
          <FieldError
            hasError={authForm.touched.selectedGatewaysList && authForm.errors.selectedGatewaysList}
            errorMessage={authForm.errors.selectedGatewaysList}
          />
          <FieldError hasError={serviceError} errorMessage={formatMessage({ id: 'Firmware.modal.service.error' })} />
          {authForm.isSubmitting && <LinearProgress className={classes.loader} />}
          <Button
            disabled={!authForm.isValid || authForm.isSubmitting}
            classes={{ disabled: classes.disabledButton }}
            fullWidth
            className={classes.selectButton}
            type={'submit'}
          >
            <Typography className={classes.buttonLabel}>
              <FormattedMessage id={'Firmware.modal.uploadFile'} />
            </Typography>
          </Button>
        </div>
      </CustomForm>
    </div>
  )
}

export default FirmwareForm
