import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    padding: '24px 33px 0px 33px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  containerHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dataContainer: {
    width: '100%',
    flex: 1,
    marginTop: 25,
    padding: '10px 25px 15px 25px',
    borderRadius: 8,
    boxShadow: `0 2px 4px 0 ${theme.palette.web.shadowGrey}`,
    backgroundColor: theme.palette.web.white
  },
  primaryButton: {
    color: `${theme.palette.web.white} !important`,
    backgroundColor: `${theme.palette.web.blue} !important`,
    borderRadius: '6px !important',
    height: '32px !important',
    minWidth: '118px !important',
    padding: '0px 40px !important',
    fontFamily: 'Open Sans !important',
    fontSize: '12px !important',
    fontWeight: '700 !important',
    textTransform: 'uppercase !important'
  }
}))
