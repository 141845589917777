import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  container: {
    marginTop: 16,
    marginBottom: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start'
  },
  label: {
    color: theme.palette.web.disabled,
    fontFamily: 'Open Sans',
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.38px',
    transform: 'scale(0.8)'
  },
  chipsContainer: {
    flex: 1,
    width: '100%',
    display: 'grid',
    gridGap: '2px',
    gridTemplateColumns: 'repeat(auto-fill, minmax(68px, 1fr))',
    alignItems: 'center'
  },
  chip: {
    fontSize: 11,
    fontWeight: 600,
    letterSpacing: '-0.34px',
    backgroundColor: theme.palette.web.chipBlue,
    color: theme.palette.web.blue,
    borderRadius: 4,
    margin: 2,
    maxWidth: 129,
    '& svg': {
      width: 10,
      height: 10,
      fill: theme.palette.web.blue
    },
    '&:hover, &:focus': {
      backgroundColor: theme.palette.web.chipBlue
    },
    '&.Mui-disabled': {
      opacity: '1'
    }
  }
}))
