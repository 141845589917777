import React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@material-ui/core/Button'
import useStyles from './styles'

const CustomButton = ({ labelId, disabled = false, type = 'button', action = () => undefined, fullWidth = false }) => {
  const classes = useStyles()

  return (
    <Button
      type={type}
      disabled={disabled}
      className={`${classes.button} ${fullWidth ? '' : classes.fixedWidth}`}
      onClick={() => action()}
      fullWidth={fullWidth}
    >
      <FormattedMessage id={labelId} />
    </Button>
  )
}

export default CustomButton
