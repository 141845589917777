import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  appBar: {
    flex: '0 0 78px',
    height: 78,
    backgroundColor: theme.palette.header.main,
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  mainLogo: {
    height: 64,
    padding: 14,
    cursor: 'pointer',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.down('sm')]: {
      flex: 1,
      textAlign: 'center'
    }
  },
  toolbar: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  toolbarNotLogged: {
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0)
    }
  },
  grow: {
    flexGrow: 1
  },
  backButton: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.black,
      width: 28,
      height: 28
    }
  }
}))
