import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { ReactSVG } from 'react-svg'
import Typography from '@material-ui/core/Typography'
import Modal from 'components/Modal/Modal'
import ResetMyPasswordForm from 'components/ResetMyPasswordForm/ResetMyPasswordForm'
import ICON_SUCCESS from 'assets/images/icons-success.svg'
import useStyles from './styles'

const ResetMyPasswordModal = ({ open = false, handleClose, title, userId }) => {
  const classes = useStyles()
  const [finished, setFinished] = useState(false)

  const handleFinished = (value) => {
    setFinished(value)
  }

  const handleCloseAndFinish = () => {
    handleClose()
    setFinished(false)
  }

  return (
    <Modal
      open={open}
      handleClose={handleCloseAndFinish}
      title={!finished ? title : ''}
      fullWidth={false}
      classNameModal={classes.modal}
    >
      {!finished ? (
        <ResetMyPasswordForm userId={userId} handleClose={handleCloseAndFinish} handleFinished={handleFinished} />
      ) : (
        <div className={classes.successContainer}>
          <ReactSVG src={ICON_SUCCESS} className={classes.image} />
          <Typography className={classes.textSuccess}>
            <FormattedMessage id={'Profile.modal.success'} />
          </Typography>
        </div>
      )}
    </Modal>
  )
}

export default ResetMyPasswordModal
