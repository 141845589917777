import React, { createContext, useState } from 'react'
import useMediaQuery from '@mui/material/useMediaQuery'
import theme from 'assets/theme/theme'

const MobileContext = createContext()

const MobileContextProvider = ({ children }) => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const [showBackButton, setShowBackButton] = useState(false)

  return (
    <MobileContext.Provider value={{ isMobile, showBackButton, setShowBackButton }}>{children}</MobileContext.Provider>
  )
}

export default MobileContextProvider

function useMobileContext() {
  const context = React.useContext(MobileContext)
  if (context === undefined) {
    throw new Error('useAuthorizationContext must be used within a MobileContextProvider')
  }
  return context
}

export { useMobileContext }
