import React, { useEffect, useState } from 'react'
import Loader from 'components/Loader/Loader'
import Modal from 'components/Modal/Modal'
import UserForm from 'components/UserForm/UserForm'
import useCallService from 'services/useCallService'
import { GET_USER_BY_ID_URL } from 'Constants'
import useStyles from './styles'

const UserModal = ({
  open = false,
  handleClose,
  onSuccess,
  title,
  userIdToEdit,
  roles,
  brands,
  representatives,
  companies
}) => {
  const classes = useStyles()
  const { callSecurityService } = useCallService()
  const [userToEdit, setUserToEdit] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    async function fetchUser() {
      setIsLoading(true)
      return callSecurityService('GET', GET_USER_BY_ID_URL + userIdToEdit, {}, true)
    }

    if (userIdToEdit) {
      fetchUser()
        .then((response) => {
          const { data } = response
          const user = {
            firstName: data.userHasPartner.firstName,
            lastName: data.userHasPartner.lastName,
            role: data.roles[0],
            company: data.company,
            phoneNumber: data.user.phoneNumber,
            emailAddress: data.user.email,
            address: data.geofence.address,
            city: data.geofence.city,
            state: data.geofence.state,
            zipCode: data.geofence.zipCode,
            brands: data.partnerNames,
            assignRepresentative: data.representativeId ? data.representativeId : '',
            notifyEmail: data.userPreference.emailNotification,
            notifySMS: data.userPreference.smsNotification
          }
          setUserToEdit(user)
        })
        .finally(() => setIsLoading(false))
    } else {
      setUserToEdit(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  return (
    <Modal open={open} handleClose={handleClose} title={title} fullWidth={true} classNameModal={classes.modal}>
      {isLoading ? (
        <Loader />
      ) : (
        <UserForm
          handleClose={handleClose}
          onSuccess={onSuccess}
          userToEdit={userToEdit}
          roles={roles}
          brands={brands}
          representatives={representatives}
          companies={companies}
        />
      )}
    </Modal>
  )
}

export default UserModal
