import React, { useEffect } from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout/Layout'
import ProfilePanel from 'components/ProfilePanel/ProfilePanel'
import { useMobileContext } from 'context/MobileContext'

const Profile = () => {
  const title = useIntl().formatMessage({ id: 'Profile.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })
  const { setShowBackButton } = useMobileContext()

  useEffect(() => {
    setShowBackButton(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout>
        <ProfilePanel />
      </Layout>
    </>
  )
}

export default Profile
