import React from 'react'
import classNames from 'classnames'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import ICON_CLOSE from 'assets/images/icons-close.svg'
import useStyles from './styles'

const Modal = ({ open = false, handleClose, title, children, fullWidth = true, classNameModal = null }) => {
  const classes = useStyles()

  return (
    <Dialog
      classes={{ paperScrollPaper: classes.scrollPaper }}
      PaperProps={{ classes: { root: classNames({ [classNameModal]: classNameModal }) } }}
      fullWidth={fullWidth}
      maxWidth={fullWidth ? 'lg' : false}
      open={open}
      onClose={handleClose}
    >
      <div className={classes.closeContainer}>
        <CustomIcon icon={ICON_CLOSE} onClick={handleClose} className={classes.closeIcon} />
      </div>
      <div className={classes.modalContainer}>
        <DialogTitle className={classes.title}>{title}</DialogTitle>
        <DialogContent className={classes.bodyContainer}>{children}</DialogContent>
      </div>
    </Dialog>
  )
}

export default Modal
