import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@material-ui/core/Button'
import Error from 'components/Error/Error'
import FirmwareModal from 'components/FirmwareModal/FirmwareModal'
import Loader from 'components/Loader/Loader'
import NoData from 'components/NoData/NoData'
import PageTitle from 'components/PageTitle/PageTitle'
import Table from 'components/Table/Table'
import useCallService from 'services/useCallService'
import { GET_GATEWAYS_FIRMWARE_URL } from 'Constants'
import useStyles from './styles'

const FirmwarePanel = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callGatewayService } = useCallService()
  const [gatewaysList, setGatewaysList] = useState([])
  const [parameters, setParameters] = useState({ sortBy: 'gatewayName', sorting: 'ASC' })
  const [openModal, setOpenModal] = React.useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    loadGatewaysTable()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters])

  const loadGatewaysTable = () => {
    async function fetchGateways() {
      setIsLoading(true)
      setIsError(false)
      return callGatewayService(
        'GET',
        `${GET_GATEWAYS_FIRMWARE_URL}?sortBy=${parameters.sortBy}&sorting=${parameters.sorting}`,
        {},
        true
      )
    }

    fetchGateways()
      .then((response) => {
        setGatewaysList(response.data)
      })
      .catch(() => setIsError(true))
      .finally(() => setIsLoading(false))
  }

  const handleSort = (order, orderBy) => {
    setParameters({
      sortBy: orderBy,
      sorting: order.toUpperCase()
    })
  }

  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const columns = [
    {
      id: 'gatewayName',
      sorting: true,
      label: formatMessage({ id: 'Firmware.column.gateways' }),
      width: classes.smallWidth
    },
    {
      id: 'firmwareVersion',
      sorting: true,
      label: formatMessage({ id: 'Firmware.column.firmwareVersion' }),
      width: classes.smallWidth
    },
    {
      id: 'createdDate',
      sorting: true,
      label: formatMessage({ id: 'Firmware.column.releaseDate' }),
      type: 'date',
      width: classes.smallWidth
    },
    {
      id: 'releaseNotes',
      sorting: true,
      label: formatMessage({ id: 'Firmware.column.releaseNotes' }),
      className: classes.wrapText,
      width: classes.mediumWidth
    },
    {
      id: 'createdBy',
      sorting: true,
      label: formatMessage({ id: 'Firmware.column.uploadedBy' }),
      width: classes.smallWidth
    }
  ]

  return (
    <div className={classes.container}>
      <div className={classes.containerHeader}>
        <PageTitle titleId={'Firmware.panel.title'} />
        <Button type={'button'} className={classes.button} onClick={handleOpenModal}>
          <FormattedMessage id={'Firmware.panel.uploadNewFirmware'} />
        </Button>
      </div>
      <div className={classes.dataContainer}>
        {isError && <Error />}
        {isLoading && <Loader />}
        {!isError && !isLoading && gatewaysList.length === 0 && <NoData />}
        {!isError && !isLoading && gatewaysList.length > 0 && (
          <Table
            columns={columns}
            data={gatewaysList}
            defaultOrderKey={parameters.sortBy}
            defaultOrder={parameters.sorting.toLowerCase()}
            onSortChange={handleSort}
            className={{ table: classes.table }}
          />
        )}
      </div>
      <FirmwareModal
        open={openModal}
        handleClose={handleCloseModal}
        onSuccess={loadGatewaysTable}
        title={formatMessage({ id: 'Firmware.modal.title' })}
      />
    </div>
  )
}

export default FirmwarePanel
