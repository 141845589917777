import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  cardFooter: {
    height: 30,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cardFooterRed: {
    color: theme.palette.web.darkRed,
    backgroundColor: theme.palette.web.lightRed
  },
  cardFooterGreen: {
    color: theme.palette.web.green,
    backgroundColor: theme.palette.web.lightGreen
  },
  cardFooterGrey: {
    color: theme.palette.web.usbGrey,
    backgroundColor: theme.palette.web.cardShadow
  },
  errorIcon: {
    fontSize: 0,
    marginRight: 8,
    '& svg': {
      fill: theme.palette.web.darkRed
    }
  },
  status: {
    fontSize: 14,
    fontWeight: 800,
    marginRight: 8,
    textTransform: 'uppercase'
  },
  message: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.17,
    paddingTop: 4
  }
}))
