import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  rightBox: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      padding: '21px 29px',
      borderRadius: 6,
      boxShadow: `0 2px 4px 0 ${theme.palette.web.cardShadow}`,
      backgroundColor: theme.palette.web.white,
      height: 270
    }
  }
}))
