import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import LoginContainer from 'components/LoginContainer/LoginContainer'
import ResetPasswordForm from 'components/ResetPasswordForm/ResetPasswordForm'

const ResetPassword = () => {
  const title = useIntl().formatMessage({ id: 'ResetPassword.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LoginContainer>
        <ResetPasswordForm />
      </LoginContainer>
    </>
  )
}

export default ResetPassword
