import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  dataRow: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-between'
    }
  },
  label: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    letterSpacing: 0.4,
    fontSize: 8,
    width: '50%',
    textTransform: 'uppercase',
    color: theme.palette.web.greyText
  },
  statusLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    letterSpacing: 0.4,
    [theme.breakpoints.up('sm')]: {
      fontSize: 8,
      width: '50%',
      textTransform: 'uppercase',
      color: theme.palette.web.greyText
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 10,
      textTransform: 'capitalize',
      color: theme.palette.web.black
    }
  },
  valueBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right'
    }
  },
  statusValueBox: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: '50%'
    },
    [theme.breakpoints.down('sm')]: {
      textAlign: 'right'
    }
  },
  value: {
    fontSize: 10,
    fontWeight: 'normal',
    letterSpacing: 0.5,
    color: theme.palette.web.darkGrey,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  textOverflow: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  wrapText: {
    whiteSpace: 'normal'
  },
  boldValue: {
    fontWeight: 'bold'
  },
  statusBoldValue: {
    [theme.breakpoints.up('sm')]: {
      fontWeight: 'bold'
    }
  },
  emailValue: {
    textDecoration: 'none',
    color: theme.palette.web.blue
  }
}))
