import React from 'react'
import cookie from 'js-cookie'
import { useMobileContext } from 'context/MobileContext'
import { TOKEN } from 'Constants'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import useStyles from './styles'

const Layout = ({ children }) => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const logged = cookie.get(TOKEN)

  return (
    <div className={classes.root}>
      {logged && !isMobile && <Sidebar />}
      <div className={classes.contentBody}>
        <Header />
        <div className={classes.widthShift}>
          {children}
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Layout
