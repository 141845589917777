import React from 'react'
import FirmwareForm from 'components/FirmwareForm/FirmwareForm'
import Modal from 'components/Modal/Modal'

const FirmwareModal = ({ open = false, handleClose, onSuccess, title }) => (
  <Modal open={open} handleClose={handleClose} title={title} fullWidth={false}>
    <FirmwareForm handleClose={handleClose} onSuccess={onSuccess} />
  </Modal>
)

export default FirmwareModal
