import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  viewButton: {
    color: theme.palette.web.blue
  },
  itemsList: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableRowSimple: {
    display: 'flex',
    flexDirection: 'row'
  },
  rowData: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 10px 24px 10px',
    justifyContent: 'center',
    flex: 1
  },
  primaryLine: {
    color: theme.palette.web.black,
    fontSize: 14,
    fontWeight: 700,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  },
  secondaryLine: {
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 600,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center'
  }
}))
