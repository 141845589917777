import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  container: {
    display: 'grid',
    gridGap: 12,
    gridTemplateColumns: 'repeat(auto-fill, minmax(104px, 1fr))',
    marginTop: 8,
    marginBottom: 20
  }
}))
