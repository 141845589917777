import React from 'react'
import { useIntl } from 'react-intl'
import { Autocomplete, createFilterOptions, InputLabel, TextField } from '@mui/material'
import CompanyModal from 'components/CompanyModal/CompanyModal'
import useStyles from 'components/CustomAutocomplete/styles'

const CustomAutocomplete = ({
  form,
  formFieldName,
  elemName = 'name',
  labelId,
  options,
  serviceError = false,
  disablePortal = true,
  disableClearable = true,
  freeSolo = true,
  disabled = false,
  clearOnBlur = true,
  helperText = ''
}) => {
  const classes = useStyles()
  const [open, toggleOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const { formatMessage } = useIntl()
  const fieldLabel = formatMessage({ id: labelId })
  const filter = createFilterOptions()

  const onChange = (value) => {
    if (value && value.inputValue) {
      toggleOpen(true)
      setInputValue(value.inputValue)
    } else {
      form.setFieldValue(formFieldName, value)
    }
  }

  const handleCloseModal = () => {
    toggleOpen(false)
  }

  const handleSubmitModal = (value) => {
    const newCompany = {
      id: value.id,
      name: value.name
    }
    options.push(newCompany)
    onChange(newCompany)
  }

  const isOptionEqualToValue = (option, value) => option.id === value.id

  return (
    <React.Fragment>
      <Autocomplete
        classes={{ inputRoot: classes.autocompleteInputRoot, input: classes.input, paper: classes.paper }}
        disablePortal={disablePortal}
        disableClearable={disableClearable}
        freeSolo={freeSolo}
        clearOnBlur={clearOnBlur}
        id={formFieldName}
        name={formFieldName}
        value={form.values[formFieldName]}
        onChange={(e, v) => onChange(v)}
        isOptionEqualToValue={isOptionEqualToValue}
        onBlur={form.handleBlur}
        options={options}
        getOptionLabel={(option) => option[elemName]}
        disabled={disabled}
        filterOptions={(opts, params) => {
          const filtered = filter(opts, params)
          if (
            params.inputValue !== '' &&
            filtered.filter((el) => el.name.toLowerCase().trim() === params.inputValue.toLowerCase().trim()).length !==
              1
          ) {
            filtered.push({
              inputValue: params.inputValue,
              name: `${formatMessage({ id: 'Users.form.company.add' })} "${params.inputValue}"`
            })
          }
          return filtered
        }}
        renderInput={(params) => (
          <div className={classes.autoCompleteBox}>
            <InputLabel
              disabled={disabled}
              classes={{ shrink: classes.labelShrink, root: classes.labelRoot, disabled: classes.labelDisabled }}
              shrink={true}
            >
              {fieldLabel}
            </InputLabel>
            <TextField
              margin="normal"
              classes={{ root: classes.inputRoot }}
              {...params}
              FormHelperTextProps={{
                className: classes.errorMessage
              }}
              error={(form.touched[formFieldName] && Boolean(form.errors[formFieldName])) || serviceError}
              helperText={
                (form.touched[formFieldName] && Boolean(form.errors[formFieldName])) || serviceError
                  ? form.errors[formFieldName]
                  : helperText
              }
              inputProps={{
                ...params.inputProps,
                autoComplete: 'new-password'
              }}
            />
          </div>
        )}
      />
      <CompanyModal
        open={open}
        handleClose={handleCloseModal}
        handleSubmit={handleSubmitModal}
        inputValue={inputValue}
      />
    </React.Fragment>
  )
}

export default CustomAutocomplete
