import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import CreatePasswordForm from 'components/CreatePasswordForm/CreatePasswordForm'
import LoginContainer from 'components/LoginContainer/LoginContainer'

const CreatePassword = () => {
  const title = useIntl().formatMessage({ id: 'CreatePassword.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <LoginContainer>
        <CreatePasswordForm />
      </LoginContainer>
    </>
  )
}

export default CreatePassword
