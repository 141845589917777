import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  readOnlyTextField: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  label: {
    width: '30%',
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.web.darkGrey,
    marginRight: 35,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  valueBox: {
    flex: 1,
    height: 38,
    maxWidth: 800,
    borderRadius: 6,
    backgroundColor: theme.palette.web.grey,
    padding: '8px 13px 5px 13px'
  },
  value: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.web.darkGrey,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  icon: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.blue,
      width: 24,
      height: 24
    }
  },
  iconPadding: {
    padding: '8px 16px'
  },
  formControl: {
    margin: 0,
    'p + &': {
      position: 'static'
    }
  },
  root: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.web.white,
    border: `1px solid ${theme.palette.web.lightGrey}`,
    padding: '25px 15px 8px 15px',
    color: theme.palette.web.darkGrey
  },
  textareaHeight: {
    height: 160
  },
  input: {
    fontSize: 14,
    color: theme.palette.web.darkGrey,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.44px'
  },
  textarea: {
    height: 'auto !important'
  }
}))
