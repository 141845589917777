import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import AltaFirmwarePanel from 'components/AltaFirmwarePanel/AltaFirmwarePanel'
import Layout from 'components/Layout/Layout'

const AltaFirmware = () => {
  const title = useIntl().formatMessage({ id: 'AltaFirmware.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout>
        <AltaFirmwarePanel />
      </Layout>
    </>
  )
}

export default AltaFirmware
