import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import SockJsClient from 'react-stomp'
import cookie from 'js-cookie'
import BrandsChipsList from 'components/BrandsChipsList/BrandsChipsList'
import CustomIcon from 'components/CustomIcon/CustomIcon'
import DashboardCard from 'components/DashboardCard/DashboardCard'
import FiltersModal from 'components/FiltersModal/FiltersModal'
import LoadMore from 'components/LoadMore/LoadMore'
import Loader from 'components/Loader/Loader'
import MenuPopperItem from 'components/MenuPopperItem/MenuPopperItem'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import PageTitle from 'components/PageTitle/PageTitle'
import SearchBar from 'components/SearchBar/SearchBar'
import SortingButton from 'components/SortingButton/SortingButton'
import useCallService from 'services/useCallService'
import { useDebounce } from 'utils/Utils'
import { useLanguageContext } from 'context/LanguageContext'
import { useMobileContext } from 'context/MobileContext'
import { usePartnersContext } from 'context/PartnersContext'
import { sortingOptions } from 'data/SortingOptions'
import ICON_FILTERS from 'assets/images/icons-filters.svg'
import {
  ADMIN_ROLE,
  BEARER,
  DASHBOARD_SEND_TO,
  DASHBOARD_SUBSCRIBE_TO,
  ENGINEERING_ADMIN_ROLE,
  GET_BOILERS_URL,
  REP_ROLE,
  SELECTED_PARTNERS,
  SELECTED_SORTING,
  SOCKET_PATH,
  SOCKET_TRANSPORTS,
  TOKEN,
  USER_ADMIN_ROLE,
  USER_DATA
} from 'Constants'
import useStyles from './styles'

const DashboardPanel = () => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { isMobile } = useMobileContext()
  const { getPartners } = usePartnersContext()
  const { callBoilerService } = useCallService()
  const [socketRef, setSocketRef] = React.useState(null)
  const [socketConnected, setSocketConnected] = useState(false)
  const userData = JSON.parse(cookie.get(USER_DATA))
  const name = userData.role === REP_ROLE ? userData.lastName : userData.firstName
  const showBrandFilter =
    userData.role === ADMIN_ROLE || userData.role === ENGINEERING_ADMIN_ROLE || userData.role === USER_ADMIN_ROLE
  const [cardsList, setCardsList] = useState([])
  const [partners, setPartners] = useState([])
  const [last, setLast] = useState(true)
  const selectedSorting = cookie.get(SELECTED_SORTING) ? cookie.get(SELECTED_SORTING) : 'dateTime'
  const [parameters, setParameters] = useState({ search: '', page: 0, partners: [], sorting: selectedSorting })
  const pageSize = process.env.REACT_APP_DASHBOARD_PAGE_SIZE
  const { language } = useLanguageContext()
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingMore, setIsLoadingMore] = useState(false)
  const [openFilters, setOpenFilters] = useState(false)
  const options = sortingOptions

  const debouncedParameters = useDebounce(parameters, 500)

  useEffect(() => {
    const handle = setInterval(() => {
      const serialList = cardsList.map((card) => card.serialNumber)
      const request = { boilerSerials: serialList }
      if (serialList.length > 0 && socketRef && socketConnected) {
        const token = cookie.get(TOKEN) ? cookie.get(TOKEN) : ''
        socketRef.sendMessage(DASHBOARD_SEND_TO, JSON.stringify(request), {
          language,
          Authorization: `${BEARER} ${token}`
        })
      }
    }, process.env.REACT_APP_MS_FOR_FETCHING_DASHBOARD)

    return () => {
      clearInterval(handle)
    }
  })

  useEffect(() => {
    async function fetchPartners() {
      return getPartners()
    }

    fetchPartners().then((response) => {
      setPartners(response)
      const selectedPartners = cookie.get(SELECTED_PARTNERS)
      setParameters((currentParameters) => ({
        search: currentParameters.search,
        page: currentParameters.page,
        partners: selectedPartners ? JSON.parse(selectedPartners) : response.map((el) => el.enumName),
        sorting: currentParameters.sorting
      }))
    })
  }, [getPartners])

  const handleMessage = (msg) => {
    const newCardsList = cardsList.map((card) => {
      const boilerUpdates = msg.find((boiler) => card.serialNumber === boiler.boilerSerial)
      if (boilerUpdates) {
        return {
          ...card,
          model: boilerUpdates.boilerModel,
          lastSeen: boilerUpdates.lastSeen,
          remote: boilerUpdates.remote,
          type: boilerUpdates.type,
          subType: boilerUpdates.subType,
          green: boilerUpdates.green
        }
      }
      return card
    })
    setCardsList(newCardsList)
  }

  const onConnect = () => {
    setSocketConnected(true)
  }

  const onDisconnect = () => {
    setSocketConnected(false)
  }

  useEffect(() => {
    async function fetchBoilers() {
      return callBoilerService(
        'GET',
        `${GET_BOILERS_URL}?size=${pageSize}&page=${parameters.page}&sorting=${parameters.sorting}${
          parameters.search ? `&searchString=${encodeURIComponent(parameters.search)}` : ''
        }&partners=${parameters.partners.filter(Boolean).join(',')}`,
        {},
        true
      )
    }

    if (partners.length !== 0) {
      fetchBoilers()
        .then((response) => {
          setLast(response.data.last)
          if (parameters.page === 0) {
            setCardsList(response.data.data)
          } else {
            setCardsList(cardsList.concat(response.data.data))
          }
        })
        .finally(() => {
          setIsLoading(false)
          setIsLoadingMore(false)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedParameters])

  const loadMore = async () => {
    setIsLoadingMore(true)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: currentParameters.page + 1,
      partners: currentParameters.partners,
      sorting: currentParameters.sorting
    }))
  }

  const search = (e) => {
    setIsLoading(true)
    setParameters((currentParameters) => ({
      search: e.target.value,
      page: 0,
      partners: currentParameters.partners,
      sorting: currentParameters.sorting
    }))
  }

  const sort = (key) => {
    setIsLoading(true)
    cookie.set(SELECTED_SORTING, key)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      partners: currentParameters.partners,
      sorting: key
    }))
  }

  const check = (e) => {
    setIsLoading(true)
    if (e.target.checked) {
      parameters.partners[e.target.id - 1] = e.target.value
    } else {
      parameters.partners[e.target.id - 1] = null
    }
    cookie.set(SELECTED_PARTNERS, parameters.partners)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      partners: currentParameters.partners,
      sorting: currentParameters.sorting
    }))
  }

  const deleteChip = (brandId) => {
    setIsLoading(true)
    parameters.partners[brandId - 1] = null
    cookie.set(SELECTED_PARTNERS, parameters.partners)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      partners: currentParameters.partners,
      sorting: currentParameters.sorting
    }))
  }

  const applyFilters = (selectedBrands, sorting) => {
    setIsLoading(true)
    parameters.partners = selectedBrands
    cookie.set(SELECTED_PARTNERS, parameters.partners)
    cookie.set(SELECTED_SORTING, sorting)
    setParameters((currentParameters) => ({
      search: currentParameters.search,
      page: 0,
      partners: selectedBrands,
      sorting
    }))
    handleCloseFilters()
  }

  const handleOpenFilters = () => {
    setOpenFilters(true)
  }

  const handleCloseFilters = () => {
    setOpenFilters(false)
  }

  return (
    <div className={classes.container}>
      <SockJsClient
        url={`${process.env.REACT_APP_SOCKET_HOST}${SOCKET_PATH}`}
        topics={[DASHBOARD_SUBSCRIBE_TO]}
        options={{ transports: SOCKET_TRANSPORTS }}
        onMessage={handleMessage}
        ref={setSocketRef}
        onConnect={onConnect}
        onDisconnect={onDisconnect}
      />
      <div className={classes.containerHeader}>
        <PageTitle titleId={'Dashboard.panel.title'} values={{ 0: name }} />
        {!isMobile && (
          <div className={classes.rightHeaders}>
            {showBrandFilter && (
              <NavbarCheckboxes values={parameters.partners} checkboxes={partners} onChange={check} />
            )}
            <SortingButton>
              {options.map((option) => (
                <MenuPopperItem
                  labelId={option.label}
                  className={classes.sortingOption}
                  key={option.key}
                  onClick={() => sort(option.key)}
                  selected={parameters.sorting === option.key}
                />
              ))}
            </SortingButton>
            <SearchBar value={parameters.search} placeholderId={'Dashboard.panel.search'} onChange={search} />
          </div>
        )}
        {isMobile && (
          <div className={classes.mobileFilters}>
            <div className={classes.mobileSearch}>
              <SearchBar
                value={parameters.search}
                mobileRoot={classes.mobileRoot}
                placeholderId={'Dashboard.panel.search'}
                onChange={search}
                persistOpened={true}
              />
              <CustomIcon icon={ICON_FILTERS} className={classes.filterIcon} onClick={handleOpenFilters} />
            </div>
            <BrandsChipsList values={parameters.partners} chips={partners} onChange={deleteChip} />
          </div>
        )}
      </div>
      {isLoading && <Loader />}
      {!isLoading && (
        <div className={classes.gridRoot}>
          {cardsList.map((boiler) => (
            <DashboardCard key={boiler.id} boilerData={boiler} />
          ))}
        </div>
      )}
      {!isLoading && !last && <LoadMore loadMore={loadMore} isLoading={isLoadingMore} />}
      {isMobile && (
        <FiltersModal
          open={openFilters}
          handleClose={handleCloseFilters}
          title={formatMessage({ id: 'Dashboard.filters.title' })}
          brands={partners}
          selectedBrands={parameters.partners}
          sorting={options}
          selectedSorting={parameters.sorting}
          onApply={applyFilters}
        />
      )}
    </div>
  )
}

export default DashboardPanel
