import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import cookie from 'js-cookie'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import useMediaQuery from '@mui/material/useMediaQuery'
import SnackbarProvider from 'components/Snackbar/Snackbar.Provider'
import AuthorizationContextProvider from 'context/AuthorizationContext'
import LanguageContextProvider from 'context/LanguageContext'
import { LayoutProvider } from 'context/LayoutContext'
import MobileContextProvider from 'context/MobileContext'
import PartnersContextProvider from 'context/PartnersContext'
import theme from 'assets/theme/theme'
import messages from 'assets/translations/translations.json'
import {
  ALTA_FIRMWARE,
  ALTA_FIRMWARE_MODULE,
  ARCHIVES,
  ARCHIVES_MODULE,
  BOILER_STATUS,
  BOILERS,
  BOILERS_MODULE,
  CREATE_PASSWORD,
  DASHBOARD,
  FIRMWARE,
  FIRMWARE_MODULE,
  FORGOT_PASSWORD,
  HEADER_MODULES,
  INDEX,
  LANGUAGE,
  NOTIFICATIONS,
  NOTIFICATIONS_MODULE,
  PRIVACY,
  PROFILE,
  REPORTS,
  REPORTS_MODULE,
  RESET_PASSWORD,
  SIDEBAR_MODULES,
  STATUS_RECORDS,
  STATUS_RECORDS_MODULE,
  TERMS_AND_CONDITIONS,
  TOKEN,
  USERS,
  USERS_MODULE
} from 'Constants'
import AltaFirmware from 'pages/AltaFirmware/AltaFirmware'
import Archives from 'pages/Archives/Archives'
import BoilerStatus from 'pages/BoilerStatus/BoilerStatus'
import Boilers from 'pages/Boilers/Boilers'
import CreatePassword from 'pages/CreatePassword/CreatePassword'
import Dashboard from 'pages/Dashboard/Dashboard'
import Firmware from 'pages/Firmware/Firmware'
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword'
import Login from 'pages/Login/Login'
import Notifications from 'pages/Notifications/Notifications'
import Privacy from 'pages/Privacy/Privacy'
import Profile from 'pages/Profile/Profile'
import Reports from 'pages/Reports/Reports'
import ResetPassword from 'pages/ResetPassword/ResetPassword'
import StatusRecords from 'pages/StatusRecords/StatusRecords'
import TermsAndConditions from 'pages/TermsAndConditions/TermsAndConditions'
import Users from 'pages/Users/Users'

const App = () => {
  const title = process.env.REACT_APP_PAGE_TITLE
  const favicon = process.env.REACT_APP_PAGE_FAVICON
  const appIconMedium = process.env.REACT_APP_QUICK_ACCESS_ICON_MEDIUM
  const appIconSmall = process.env.REACT_APP_QUICK_ACCESS_ICON_SMALL
  const userLang = cookie.get(LANGUAGE)
  const notMobile = useMediaQuery(theme.breakpoints.up('sm'))
  const [language, setLanguage] = useState(userLang || navigator.language.split(/[-_]/)[0])

  const updateLanguage = () => setLanguage(cookie.get(LANGUAGE) || navigator.language.split(/[-_]/)[0])

  const needsAuthorization = () => cookie.get(TOKEN)

  const hasHeaderModule = (module) => (cookie.get(HEADER_MODULES) ? cookie.get(HEADER_MODULES).includes(module) : false)

  const hasSidebarModule = (module) =>
    cookie.get(SIDEBAR_MODULES) ? cookie.get(SIDEBAR_MODULES).includes(module) : false

  const renderComponent = (condition, componentToRender, redirect) =>
    condition ? componentToRender : <Redirect to={redirect} />

  const onlyDesktop = () => notMobile

  return (
    <>
      <IntlProvider locale={language} messages={messages[language]}>
        <LanguageContextProvider language={language} updateLanguage={updateLanguage}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider>
              <Helmet>
                <title>{title}</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <link rel="shortcut icon" type="image/x-icon" href={appIconSmall} />
                <link rel="apple-touch-icon" href={appIconMedium} />
              </Helmet>
              <BrowserRouter>
                <AuthorizationContextProvider>
                  <PartnersContextProvider>
                    <LayoutProvider>
                      <MobileContextProvider>
                        <Switch>
                          <Route
                            exact
                            path={INDEX}
                            render={(props) => renderComponent(!needsAuthorization(), <Login {...props} />, DASHBOARD)}
                          />
                          <Route
                            exact
                            path={FORGOT_PASSWORD}
                            render={(props) =>
                              renderComponent(!needsAuthorization(), <ForgotPassword {...props} />, DASHBOARD)
                            }
                          />
                          <Route
                            exact
                            path={RESET_PASSWORD}
                            render={(props) =>
                              renderComponent(!needsAuthorization(), <ResetPassword {...props} />, DASHBOARD)
                            }
                          />
                          <Route
                            exact
                            path={CREATE_PASSWORD}
                            render={(props) =>
                              renderComponent(!needsAuthorization(), <CreatePassword {...props} />, DASHBOARD)
                            }
                          />
                          <Route
                            exact
                            path={DASHBOARD}
                            render={(props) => renderComponent(needsAuthorization(), <Dashboard {...props} />, INDEX)}
                          />
                          <Route
                            exact
                            path={`${BOILER_STATUS}/:boilerId`}
                            render={(props) =>
                              renderComponent(needsAuthorization(), <BoilerStatus {...props} />, INDEX)
                            }
                          />
                          <Route
                            exact
                            path={ARCHIVES}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasSidebarModule(ARCHIVES_MODULE) && onlyDesktop(),
                                <Archives {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={STATUS_RECORDS}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasSidebarModule(STATUS_RECORDS_MODULE) && onlyDesktop(),
                                <StatusRecords {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={BOILERS}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasSidebarModule(BOILERS_MODULE) && onlyDesktop(),
                                <Boilers {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={REPORTS}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasSidebarModule(REPORTS_MODULE) && onlyDesktop(),
                                <Reports {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={USERS}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasSidebarModule(USERS_MODULE) && onlyDesktop(),
                                <Users {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={FIRMWARE}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasSidebarModule(FIRMWARE_MODULE) && onlyDesktop(),
                                <Firmware {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={ALTA_FIRMWARE}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasSidebarModule(ALTA_FIRMWARE_MODULE) && onlyDesktop(),
                                <AltaFirmware {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={NOTIFICATIONS}
                            render={(props) =>
                              renderComponent(
                                needsAuthorization() && hasHeaderModule(NOTIFICATIONS_MODULE),
                                <Notifications {...props} />,
                                INDEX
                              )
                            }
                          />
                          <Route
                            exact
                            path={PROFILE}
                            render={(props) => renderComponent(needsAuthorization(), <Profile {...props} />, INDEX)}
                          />
                          <Route
                            exact
                            path={TERMS_AND_CONDITIONS}
                            render={(props) => renderComponent(true, <TermsAndConditions {...props} />, INDEX)}
                          />
                          <Route
                            exact
                            path={PRIVACY}
                            render={(props) => renderComponent(true, <Privacy {...props} />, INDEX)}
                          />
                          <Redirect to={INDEX} />
                        </Switch>
                      </MobileContextProvider>
                    </LayoutProvider>
                  </PartnersContextProvider>
                </AuthorizationContextProvider>
              </BrowserRouter>
            </SnackbarProvider>
          </ThemeProvider>
        </LanguageContextProvider>
      </IntlProvider>
    </>
  )
}

export default App
