import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: '100vw',
    overflowX: 'hidden'
  },
  contentBody: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    flex: 1
  },
  widthShift: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      whiteSpace: 'nowrap',
      scrollbarWidth: 'none' /* Firefox */,
      msOverflowStyle: 'none' /* Internet Explorer 11 */,
      '&::-webkit-scrollbar': {
        display: 'none' /* WebKit (Chrome, Safari) */
      }
    }
  }
}))
