import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    flex: 1,
    display: 'grid',
    marginBottom: 40
  },
  actions: {
    padding: '20px 0 30px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  line: {
    margin: '25px 0',
    opacity: 0.2,
    borderTop: `2px solid ${theme.palette.web.usbGrey}`
  },
  backButton: {
    cursor: 'pointer',
    marginRight: 30,
    '& p': {
      fontSize: 14,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.19px',
      textTransform: 'uppercase',
      color: theme.palette.web.blue
    }
  },
  label: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.38px',
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase',
    '&.Mui-focused, &.Mui-error': {
      color: theme.palette.web.usbGrey
    }
  },
  formControl: {
    margin: 0
  },
  root: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.web.white,
    border: `1px solid ${theme.palette.web.lightGrey}`,
    padding: '8px 6px 8px 12px',
    color: theme.palette.web.darkGrey,
    height: 36,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.web.darkRed}`,
      color: theme.palette.web.red
    }
  },
  input: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.44px'
  },
  notificationLabel: {
    marginTop: 3,
    marginRight: 10,
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 'bold',
    letterSpacing: '-0.38px',
    color: theme.palette.web.darkGrey
  },
  notificationsBox: {
    marginRight: 20,
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row'
  },
  notificationsJustify: {
    justifySelf: 'right'
  },
  helperTextPosition: {
    top: '60px'
  }
}))
