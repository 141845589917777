import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useIntl } from 'react-intl'
import moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download'
import { Typography } from '@mui/material'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomNumberInput from 'components/CustomNumberInput/CustomNumberInput'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import Loader from 'components/Loader/Loader'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import NoData from 'components/NoData/NoData'
import Table from 'components/Table/Table'
import useCallService from 'services/useCallService'
import { usePartnersContext } from 'context/PartnersContext'
import { GET_ENROLLED_AUDIENCE_URL } from 'Constants'
import useStyles from './styles'

const EnrolledAudienceReportStep = forwardRef(({ step, reportForm, userStatuses, representatives }, ref) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callSecurityService } = useCallService()
  const { getPartners } = usePartnersContext()
  const [partners, setPartners] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [reportResult, setReportResult] = useState([])
  const [parameters, setParameters] = useState({
    sortBy: 'username',
    sorting: 'ASC'
  })

  useEffect(() => {
    async function fetchPartners() {
      return getPartners()
    }

    fetchPartners().then((response) => {
      setPartners(response)
    })
  }, [getPartners])

  const loadEnrolledAudience = () => {
    setIsLoading(true)

    async function fetchEnrolledAudience() {
      return callSecurityService(
        'GET',
        `${GET_ENROLLED_AUDIENCE_URL}?sortBy=${parameters.sortBy}&sorting=${parameters.sorting}${
          reportForm.values.statuses && reportForm.values.statuses !== 'ALL'
            ? `&statuses=${reportForm.values.statuses}`
            : ''
        }${
          reportForm.values.representative && reportForm.values.representative !== 'ALL'
            ? `&representative=${reportForm.values.representative}`
            : ''
        }${
          reportForm.values.boilersAssigned ? `&boilersAssigned=${reportForm.values.boilersAssigned}` : ''
        }&partners=${reportForm.values.filters.partners.filter(Boolean).join(',')}`,
        {},
        true
      )
    }

    fetchEnrolledAudience()
      .then((response) => {
        setReportResult(response.data.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (reportForm.values.completed === true) {
      loadEnrolledAudience()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportForm.values.completed, parameters])

  const reset = () => {}

  const handleSort = (order, orderBy) => {
    setParameters(() => ({
      sortBy: orderBy,
      sorting: order.toUpperCase()
    }))
  }

  useImperativeHandle(ref, () => ({
    reset
  }))

  const columns = [
    { id: 'username', sorting: true, label: formatMessage({ id: 'Reports.form.username' }), width: classes.largeWidth },
    { id: 'email', sorting: true, label: formatMessage({ id: 'Reports.form.email' }), width: classes.largeWidth },
    { id: 'phone', sorting: true, label: formatMessage({ id: 'Reports.form.phone' }), width: classes.smallWidth },
    {
      id: 'boilerQuantity',
      sorting: true,
      label: formatMessage({ id: 'Reports.form.boilerQuantity' }),
      render: (user) => formatMessage({ id: 'Users.table.boilerQuantity' }, { 0: user.boilerQuantity }),
      width: classes.mediumWidth
    },
    { id: 'company', sorting: true, label: formatMessage({ id: 'Reports.form.company' }), width: classes.mediumWidth },
    {
      id: 'representative',
      sorting: true,
      label: formatMessage({ id: 'Reports.form.representative' }),
      width: classes.largeWidth
    },
    { id: 'role', sorting: true, label: formatMessage({ id: 'Reports.form.role' }), width: classes.smallWidth },
    { id: 'status', sorting: true, label: formatMessage({ id: 'Reports.form.status' }), width: classes.smallWidth },
    {
      id: 'lastConnection',
      sorting: true,
      type: 'date',
      label: formatMessage({ id: 'Reports.form.lastConnection' }),
      width: classes.mediumWidth
    },
    {
      id: 'createdDate',
      sorting: true,
      type: 'date',
      label: formatMessage({ id: 'Reports.form.createdDate' }),
      width: classes.mediumWidth
    }
  ]

  const csvHeaders = [
    { label: formatMessage({ id: 'Reports.form.username' }), key: 'username' },
    { label: formatMessage({ id: 'Reports.form.email' }), key: 'email' },
    { label: formatMessage({ id: 'Reports.form.phone' }), key: 'phone' },
    { label: formatMessage({ id: 'Reports.form.boilerQuantity' }), key: 'boilerQuantity' },
    { label: formatMessage({ id: 'Reports.form.company' }), key: 'company' },
    { label: formatMessage({ id: 'Reports.form.representative' }), key: 'representative' },
    { label: formatMessage({ id: 'Reports.form.role' }), key: 'role' },
    { label: formatMessage({ id: 'Reports.form.status' }), key: 'status' },
    { label: formatMessage({ id: 'Reports.form.lastConnection' }), key: 'lastConnection' },
    { label: formatMessage({ id: 'Reports.form.createdDate' }), key: 'createdDate' }
  ]

  return (
    <React.Fragment key={step}>
      <CustomForm form={reportForm} className={classes.formContainer}>
        <div className={classes.gridContainer}>
          <div className={classes.filtersContainer}>
            <div className={classes.body}>
              <CustomSelect
                form={reportForm}
                formFieldName={'statuses'}
                labelId={'Reports.form.statuses'}
                items={userStatuses}
                disabled={true}
              />
              <CustomSelect
                form={reportForm}
                formFieldName={'representative'}
                labelId={'Reports.form.representative'}
                items={representatives}
                disabled={true}
              />
              <CustomNumberInput
                type={'number'}
                form={reportForm}
                formFieldName={'boilersAssigned'}
                labelId={'Reports.form.boilersAssigned'}
                disabled={true}
              />
            </div>
            <div className={classes.checkboxes}>
              <NavbarCheckboxes
                values={reportForm.values.filters ? reportForm.values.filters.partners : []}
                checkboxes={partners}
                groupLabel={formatMessage({ id: 'Reports.form.brands' })}
                disabled={true}
              />
            </div>
          </div>
          <div className={classes.resultsContainer}>
            <div className={classes.filtersRow}>
              <Typography className={classes.tableTitle}>
                {formatMessage({ id: 'Reports.enrolledAudience.title' })}
              </Typography>
              <div className={classes.rightHeaders}>
                <div className={classes.exportButton}>
                  <CSVLink
                    headers={csvHeaders}
                    data={reportResult}
                    filename={`report_enrolled_audience_${moment().format()}.csv`}
                    enclosingCharacter={`"`}
                  >
                    <DownloadIcon />
                    {formatMessage({ id: 'Reports.actions.export' })}
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className={classes.resultsRow}>
              {isLoading && <Loader />}
              {!isLoading && reportResult.length === 0 && <NoData />}
              {!isLoading && reportResult.length > 0 && (
                <Table
                  columns={columns}
                  data={reportResult}
                  defaultOrderKey={parameters.sortBy}
                  defaultOrder={parameters.sorting.toLowerCase()}
                  onSortChange={handleSort}
                />
              )}
            </div>
          </div>
        </div>
      </CustomForm>
    </React.Fragment>
  )
})

export default EnrolledAudienceReportStep
