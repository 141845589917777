import React from 'react'
import { useIntl } from 'react-intl'
import { TextField } from '@material-ui/core'
import useStyles from './styles'

const CustomNumberInput = ({ form, formFieldName, labelId, required = false, disabled = false, min = 0 }) => {
  const classes = useStyles()

  const onNumberChange = (e) => {
    const inputValue = e.target.value
    if ((/^\d*$/.test(inputValue) && parseInt(inputValue, 10) > min) || inputValue === '') {
      form.handleChange(e)
    }
  }

  return (
    <TextField
      InputLabelProps={{
        disableAnimation: true,
        shrink: true,
        classes: {
          shrink: classes.shrink,
          formControl: classes.label
        }
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          formControl: classes.formControl,
          root: classes.root,
          input: classes.input
        }
      }}
      inputProps={{ min }}
      id={formFieldName}
      name={formFieldName}
      value={form.values[formFieldName]}
      onChange={onNumberChange}
      onBlur={form.handleBlur}
      margin="normal"
      variant={'standard'}
      label={useIntl().formatMessage({ id: labelId })}
      type={'number'}
      fullWidth
      autoComplete={'off'}
      required={required}
      disabled={disabled}
    />
  )
}

export default CustomNumberInput
