import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as yup from 'yup'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomForm from 'components/CustomForm/CustomForm'
import FieldError from 'components/FieldError/FieldError'
import PasswordTextField from 'components/PasswordTextField/PasswordTextField'
import useCallService from 'services/useCallService'
import { useMobileContext } from 'context/MobileContext'
import { PASSWORD_REGEX, CHANGE_PASSWORD_URL } from 'Constants'
import useStyles from './styles'

const ResetMyPasswordForm = ({ userId, handleClose, handleFinished }) => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const { formatMessage } = useIntl()
  const { callSecurityService } = useCallService()
  const [serviceError, setServiceError] = useState(false)
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')

  const authValidationSchema = yup.object({
    oldPassword: yup
      .string()
      .required(formatMessage({ id: 'Profile.form.error.required' }))
      .min(8, formatMessage({ id: 'Profile.form.error.short' })),
    newPassword: yup
      .string()
      .required(formatMessage({ id: 'Profile.form.error.required' }))
      .matches(PASSWORD_REGEX, formatMessage({ id: 'Commons.error.passwordFormat' }))
      .notOneOf([yup.ref('oldPassword')], formatMessage({ id: 'Profile.form.error.sameAsOld' })),
    repeatPassword: yup
      .string()
      .required(formatMessage({ id: 'Profile.form.error.required' }))
      .oneOf([yup.ref('newPassword')], formatMessage({ id: 'Profile.form.error.equals' }))
  })

  const authForm = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      repeatPassword: ''
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      const changePasswordRequest = {
        actualPassword: values.oldPassword,
        newPassword: values.newPassword,
        confirmPassword: values.repeatPassword
      }
      try {
        await callSecurityService(
          'PUT',
          CHANGE_PASSWORD_URL.replace('{userId}', userId),
          { data: changePasswordRequest },
          true
        )
        handleFinished(true)
      } catch (error) {
        setServiceError(true)
        if (error.response && error.response.data.message) {
          setServiceErrorMessage(error.response.data.message)
        } else {
          setServiceErrorMessage(formatMessage({ id: 'Commons.genericError' }))
        }
      }
    }
  })

  return (
    <div className={classes.container}>
      <CustomForm form={authForm}>
        <div className={classes.body}>
          <PasswordTextField
            form={authForm}
            formFieldName={'oldPassword'}
            labelId={'Profile.form.oldPassword'}
            labelClasses={{ formControl: classes.label }}
            helperTextClasses={classes.helperTextPosition}
            inputClasses={{
              formControl: classes.formControl,
              root: classNames(classes.root, classes.inputHeight),
              input: classes.input
            }}
          />
          <PasswordTextField
            form={authForm}
            formFieldName={'newPassword'}
            labelId={'Profile.form.newPassword'}
            helperText={formatMessage({ id: 'Commons.newPassword.helperText' })}
            labelClasses={{ formControl: classes.label }}
            helperTextClasses={classes.helperTextPosition}
            inputClasses={{
              formControl: classes.formControl,
              root: classNames(classes.root, classes.inputHeight),
              input: classes.input
            }}
          />
          {isMobile && <div className={classes.emptySpace} />}
          <PasswordTextField
            form={authForm}
            formFieldName={'repeatPassword'}
            labelId={'Profile.form.repeatPassword'}
            labelClasses={{ formControl: classes.label }}
            helperTextClasses={classes.helperTextPosition}
            inputClasses={{
              formControl: classes.formControl,
              root: classNames(classes.root, classes.inputHeight),
              input: classes.input
            }}
          />
        </div>
        <FieldError hasError={serviceError} errorMessage={serviceErrorMessage} />
        <div className={classes.actions}>
          <div className={classes.backButton} onClick={handleClose}>
            <Typography>
              <FormattedMessage id={'Commons.button.cancel'} />
            </Typography>
          </div>
          <CustomButton labelId={'Commons.button.save'} type={'submit'} fullWidth={isMobile} />
        </div>
      </CustomForm>
    </div>
  )
}

export default ResetMyPasswordForm
