import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import PasswordTextField from 'components/PasswordTextField/PasswordTextField'
import { useAuthorizationContext } from 'context/AuthorizationContext'
import { useMobileContext } from 'context/MobileContext'
import { FORGOT_PASSWORD } from 'Constants'
import useStyles from './styles'

const LoginForm = () => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const { handleLogin } = useAuthorizationContext()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [serviceError, setServiceError] = useState(false)

  const authValidationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .email(formatMessage({ id: 'Login.email.error.invalid' }))
      .required(formatMessage({ id: 'Login.email.error.required' })),
    password: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Login.password.error.required' }))
      .min(8, formatMessage({ id: 'Login.password.error.short' }))
  })

  const authForm = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      const loginRequest = {
        email: values.email.toLowerCase(),
        password: values.password,
        partner: process.env.REACT_APP_PARTNER_NAME
      }

      try {
        await handleLogin(loginRequest)
      } catch (error) {
        setServiceError(true)

        if (error.response && error.response.data.message) {
          enqueueSnackbar(error.response.data.message, { variant: 'error', onClose: () => setServiceError(false) })
        } else {
          enqueueSnackbar(formatMessage({ id: 'Commons.genericError' }), {
            variant: 'error',
            onClose: () => setServiceError(false)
          })
        }
      }
    }
  })

  const handleForgot = () => {
    history.push(FORGOT_PASSWORD)
  }

  return (
    <div className={classes.loginContainer}>
      <CustomForm form={authForm} className={classes.customForm}>
        <div className={classes.form}>
          <CustomTextField
            form={authForm}
            formFieldName={'email'}
            labelId={'Login.email.label'}
            serviceError={serviceError}
          />
          <PasswordTextField
            form={authForm}
            formFieldName={'password'}
            labelId={'Login.password.label'}
            serviceError={serviceError}
          />
        </div>
        <div className={classes.bottomContainer}>
          <Typography className={classes.forgotMessage} onClick={() => handleForgot()}>
            <FormattedMessage id="Login.forgot" />
          </Typography>
          <CustomButton
            fullWidth={isMobile}
            labelId={'Login.button'}
            disabled={!authForm.isValid || authForm.isSubmitting || serviceError}
            type={'submit'}
          />
        </div>
      </CustomForm>
    </div>
  )
}

export default LoginForm
