import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  gridContainer: {
    padding: '15px 24px 0px 24px',
    flex: 1,
    overflowY: 'auto'
  },
  gridRoot: {
    margin: '20px 0',
    display: 'grid',
    gridGap: '3em',
    gridTemplateColumns: 'repeat(auto-fill, minmax(203px, 3em))'
  },
  bottom: {
    width: '100%',
    flex: '0 0 auto',
    height: 50,
    alignSelf: 'flex-end',
    borderTop: '0.50px rgba(87, 125, 255, 0.60) solid',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 15
  },
  transitionButton: {
    height: '32px !important',
    fontFamily: 'Open Sans !important',
    fontSize: '12px !important',
    fontStyle: 'normal !important',
    fontWeight: '600 !important',
    textTransform: 'uppercase !important'
  }
}))
