import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  icon: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.usbGrey,
      width: 30,
      height: 30
    }
  },
  iconPadding: {
    padding: '0px 8px 0px 0px'
  },
  inputIconPadding: {
    padding: theme.spacing(0)
  },
  root: {
    margin: theme.spacing(0),
    borderRadius: 6,
    position: 'relative',
    backgroundColor: theme.palette.web.white,
    padding: '9px 8px 9px 12px',
    color: theme.palette.web.darkGrey,
    height: 40,
    width: 316
  },
  input: {
    fontSize: 16,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    '&::placeholder': {
      fontStyle: 'italic',
      color: theme.palette.web.usbGrey
    }
  },
  mobileRoot: {
    [theme.breakpoints.down('sm')]: {
      flex: 1
    }
  }
}))
