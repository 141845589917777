import React from 'react'
import { useIntl } from 'react-intl'
import { Typography } from '@material-ui/core'

const Error = () => {
  const { formatMessage } = useIntl()
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography>{formatMessage({ id: 'Commons.genericError' })}</Typography>
    </div>
  )
}

export default Error
