import React from 'react'
import FiltersForm from 'components/FiltersForm/FiltersForm'
import MobileModal from 'components/MobileModal/MobileModal'

const FiltersModal = ({
  open = false,
  handleClose,
  title,
  brands,
  selectedBrands,
  sorting,
  selectedSorting,
  onApply = () => undefined
}) => (
  <MobileModal open={open} handleClose={handleClose} title={title}>
    <FiltersForm
      brands={brands}
      sorting={sorting}
      selectedBrands={selectedBrands}
      selectedSorting={selectedSorting}
      onApply={onApply}
    />
  </MobileModal>
)

export default FiltersModal
