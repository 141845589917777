import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import ConfirmationScreen from 'components/ConfirmationScreen/ConfirmationScreen'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomForm from 'components/CustomForm/CustomForm'
import PasswordTextField from 'components/PasswordTextField/PasswordTextField'
import useCallService from 'services/useCallService'
import { useMobileContext } from 'context/MobileContext'
import { CREATED, ENABLE_WEBPORTAL_URL, INDEX, PASSWORD_REGEX, RESET_URL, TOKEN } from 'Constants'
import useStyles from './styles'

const CreatePasswordForm = () => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const queryParams = new URLSearchParams(useLocation().search)
  const history = useHistory()
  const { callSecurityService } = useCallService()
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [created, setCreated] = useState(queryParams.get(CREATED))
  const [serviceError, setServiceError] = useState(false)
  const [loadingEnable, setLoadingEnable] = useState(true)
  const [enableButton, setEnableButton] = useState(false)
  const [confirmationText, setConfirmationText] = useState('CreatePassword.confirmation.text')

  useEffect(() => {
    async function enableWebPortal() {
      return callSecurityService('PUT', `${ENABLE_WEBPORTAL_URL}?token=${queryParams.get(TOKEN)}`, {}, false)
    }

    if (created) {
      enableWebPortal()
        .then(() => {
          setConfirmationText('CreatePassword.confirmation.text')
          setEnableButton(true)
          setLoadingEnable(false)
        })
        .catch((error) => {
          setLoadingEnable(false)
          setConfirmationText('CreatePassword.confirmation.text.wrong')
          setEnableButton(false)
          const errorMessage =
            error.response && error.response.data.message
              ? error.response.data.message
              : formatMessage({ id: 'Commons.genericError' })
          enqueueSnackbar(errorMessage, { variant: 'error' })
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [created])

  const authValidationSchema = yup.object({
    newPassword: yup
      .string()
      .required(formatMessage({ id: 'CreatePassword.newPassword.error.required' }))
      .matches(PASSWORD_REGEX, formatMessage({ id: 'Commons.error.passwordFormat' })),
    repeatPassword: yup
      .string()
      .required(formatMessage({ id: 'CreatePassword.repeatPassword.error.required' }))
      .oneOf([yup.ref('newPassword')], formatMessage({ id: 'CreatePassword.repeatPassword.error.equals' }))
  })

  const authForm = useFormik({
    initialValues: {
      newPassword: '',
      repeatPassword: ''
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      const createRequest = {
        password: values.newPassword,
        confirmPassword: values.repeatPassword,
        token: queryParams.get(TOKEN),
        partnerName: process.env.REACT_APP_PARTNER_NAME
      }
      try {
        await callSecurityService('POST', RESET_URL, { data: createRequest }, false)
        setCreated(true)
      } catch (error) {
        setCreated(false)
        setServiceError(true)
        const errorMessage =
          error.response && error.response.data.message
            ? error.response.data.message
            : formatMessage({ id: 'Commons.genericError' })
        enqueueSnackbar(errorMessage, { variant: 'error', onClose: () => setServiceError(false) })
      }
    }
  })

  const handleBackToLogin = () => {
    history.push(INDEX)
  }

  return (
    <div className={classes.createContainer}>
      {!created ? (
        <CustomForm form={authForm} className={classes.customForm}>
          <div className={classes.form}>
            <PasswordTextField
              form={authForm}
              formFieldName={'newPassword'}
              labelId={'CreatePassword.newPassword.label'}
              helperText={formatMessage({ id: 'Commons.newPassword.helperText' })}
              serviceError={serviceError}
            />
            {isMobile && <div className={classes.emptySpace} />}
            <PasswordTextField
              form={authForm}
              formFieldName={'repeatPassword'}
              labelId={'CreatePassword.repeatPassword.label'}
              serviceError={serviceError}
            />
          </div>
          <div className={classes.bottomContainer}>
            <CustomButton
              labelId={'CreatePassword.reset.button'}
              disabled={!authForm.isValid || authForm.isSubmitting || serviceError}
              type={'submit'}
              fullWidth
            />
          </div>
        </CustomForm>
      ) : (
        <ConfirmationScreen
          messageId={confirmationText}
          hasButton={enableButton}
          buttonId={'CreatePassword.login.button'}
          buttonAction={handleBackToLogin}
          loading={loadingEnable}
        />
      )}
    </div>
  )
}

export default CreatePasswordForm
