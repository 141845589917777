import ICON_ARCHIVES from 'assets/images/icons-archive.svg'
import ICON_BOILERS from 'assets/images/icons-boiler-management.svg'
import ICON_DASHBOARD from 'assets/images/icons-dashboard.svg'
import ICON_FIRMWARE from 'assets/images/icons-firmware.svg'
import ICON_RECORDS from 'assets/images/icons-records.svg'
import ICON_REPORTS from 'assets/images/icons-reports.svg'
import ICON_USERS from 'assets/images/icons-settings.svg'
import {
  ARCHIVES_MODULE,
  DASHBOARD_MODULE,
  STATUS_RECORDS_MODULE,
  USERS_MODULE,
  BOILERS_MODULE,
  FIRMWARE_MODULE,
  ALTA_FIRMWARE_MODULE,
  USERS,
  BOILERS,
  FIRMWARE,
  ALTA_FIRMWARE,
  STATUS_RECORDS,
  ARCHIVES,
  DASHBOARD,
  REPORTS_MODULE,
  REPORTS
} from 'Constants'

export const sidebar = [
  { name: DASHBOARD_MODULE, icon: ICON_DASHBOARD, label: 'Dashboard.label', link: DASHBOARD, needsAuth: false },
  { name: ARCHIVES_MODULE, icon: ICON_ARCHIVES, label: 'Archives.label', link: ARCHIVES, needsAuth: true },
  {
    name: STATUS_RECORDS_MODULE,
    icon: ICON_RECORDS,
    label: 'StatusRecords.label',
    link: STATUS_RECORDS,
    needsAuth: true
  },
  { name: USERS_MODULE, icon: ICON_USERS, label: 'Users.label', link: USERS, needsAuth: true },
  { name: BOILERS_MODULE, icon: ICON_BOILERS, label: 'Boilers.label', link: BOILERS, needsAuth: true },
  { name: REPORTS_MODULE, icon: ICON_REPORTS, label: 'Reports.label', link: REPORTS, needsAuth: true },
  { name: FIRMWARE_MODULE, icon: ICON_FIRMWARE, label: 'Firmware.label', link: FIRMWARE, needsAuth: true },
  { name: ALTA_FIRMWARE_MODULE, icon: ICON_FIRMWARE, label: 'AltaFirmware.label', link: ALTA_FIRMWARE, needsAuth: true }
]
