import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import Typography from '@material-ui/core/Typography'
import ConfirmationScreen from 'components/ConfirmationScreen/ConfirmationScreen'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import useCallService from 'services/useCallService'
import { useMobileContext } from 'context/MobileContext'
import { FORGOT_URL, INDEX } from 'Constants'
import useStyles from './styles'

const ForgotPassword = () => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const history = useHistory()
  const { callSecurityService } = useCallService()
  const { formatMessage } = useIntl()
  const { enqueueSnackbar } = useSnackbar()
  const [notYetFinished, setNotYetFinished] = useState(true)
  const [serviceError, setServiceError] = useState(false)

  const authValidationSchema = yup.object({
    email: yup
      .string()
      .email(formatMessage({ id: 'ForgotPassword.email.error.invalid' }))
      .required(formatMessage({ id: 'ForgotPassword.email.error.required' }))
  })

  const authForm = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      const forgotRequest = {
        email: values.email.toLowerCase(),
        partnerName: process.env.REACT_APP_PARTNER_NAME
      }
      try {
        await callSecurityService('POST', FORGOT_URL, { data: forgotRequest }, false)
        setNotYetFinished(false)
      } catch (error) {
        setServiceError(true)
        const errorMessage =
          error.response && error.response.data.message
            ? error.response.data.message
            : formatMessage({ id: 'Commons.genericError' })
        enqueueSnackbar(errorMessage, { variant: 'error', onClose: () => setServiceError(false) })
      }
    }
  })

  const handleBackToLogin = () => {
    history.push(INDEX)
  }

  return (
    <div className={classes.forgotContainer}>
      {notYetFinished ? (
        <CustomForm form={authForm} className={classes.customForm}>
          <div className={classes.textContainer}>
            <Typography className={classes.textTitle}>
              <FormattedMessage id={'ForgotPassword.text.title'} />
            </Typography>
            <Typography className={classes.text}>
              <FormattedMessage id={'ForgotPassword.text'} />
            </Typography>
          </div>
          <div className={classes.form}>
            <CustomTextField
              form={authForm}
              formFieldName={'email'}
              labelId={'ForgotPassword.email.label'}
              serviceError={serviceError}
            />
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.backButton} onClick={() => handleBackToLogin()}>
              <Typography>
                <FormattedMessage id={'ForgotPassword.back'} />
              </Typography>
            </div>
            <CustomButton
              fullWidth={isMobile}
              labelId={'ForgotPassword.button'}
              type={'submit'}
              disabled={!authForm.isValid || authForm.isSubmitting || serviceError}
            />
          </div>
        </CustomForm>
      ) : (
        <ConfirmationScreen
          messageId={'ForgotPassword.confirmation.text'}
          buttonId={'ForgotPassword.login.button'}
          buttonAction={handleBackToLogin}
        />
      )}
    </div>
  )
}

export default ForgotPassword
