import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.web.red,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: '0.16px',
    lineHeight: 'normal',
    margin: '5px 0px'
  }
}))
