import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  gridRoot: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))',
    [theme.breakpoints.up('sm')]: {
      margin: '20px 0',
      gridGap: 32
    },
    [theme.breakpoints.down('sm')]: {
      gridGap: 10
    }
  }
}))
