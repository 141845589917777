import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  body: {
    flex: 1,
    display: 'grid'
  },
  actions: {
    padding: '20px 0 30px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  backButton: {
    cursor: 'pointer',
    marginRight: 30,
    '& p': {
      fontSize: 14,
      fontWeight: 600,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.19px',
      textTransform: 'uppercase',
      color: theme.palette.web.blue
    }
  },
  loader: {
    marginTop: '10px'
  }
}))
