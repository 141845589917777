import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.header.transparentRed,
    '& svg': {
      fill: theme.palette.header.selected
    },
    '&:hover': {
      backgroundColor: theme.palette.header.transparentRed
    }
  }
}))
