import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useIntl } from 'react-intl'
import moment from 'moment'
import DownloadIcon from '@mui/icons-material/Download'
import { Typography } from '@mui/material'
import CustomChipsList from 'components/CustomChipsList/CustomChipsList'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import Loader from 'components/Loader/Loader'
import NoData from 'components/NoData/NoData'
import Table from 'components/Table/Table'
import useCallService from 'services/useCallService'
import { GET_ALARMS_REPORT_URL } from 'Constants'
import useStyles from './styles'

const AlarmHistoryReportStep = forwardRef(({ step, alarms, alarmTypes, reportForm }, ref) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callBoilerService } = useCallService()
  const [isLoading, setIsLoading] = useState(true)
  const [reportResult, setReportResult] = useState([])
  const [parameters, setParameters] = useState({
    sortBy: 'boilerSerialNumber',
    sorting: 'ASC'
  })

  const loadReportAlarms = () => {
    setIsLoading(true)

    async function fetchReportAlarms() {
      const request = {
        sortBy: parameters.sortBy,
        sorting: parameters.sorting,
        alarmType:
          reportForm.values.alarmType && reportForm.values.alarmType !== 'ALL' ? reportForm.values.alarmType : null,
        alarms: reportForm.values.alarm && reportForm.values.alarm.length > 0 ? reportForm.values.alarm : null,
        boilerIds: reportForm.values.boilersFullList.map((boiler) => boiler.id)
      }
      return callBoilerService('POST', GET_ALARMS_REPORT_URL, { data: request }, true)
    }

    fetchReportAlarms()
      .then((response) => {
        setReportResult(response.data.data)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    if (reportForm.values.completed === true) {
      loadReportAlarms()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportForm.values.completed, parameters])

  const reset = () => {}

  const handleSort = (order, orderBy) => {
    setParameters(() => ({
      sortBy: orderBy,
      sorting: order.toUpperCase()
    }))
  }

  useImperativeHandle(ref, () => ({
    reset
  }))

  const columns = [
    { id: 'boilerSerialNumber', sorting: true, label: formatMessage({ id: 'Reports.form.serialNumber' }) },
    { id: 'boilerModel', sorting: true, label: formatMessage({ id: 'Reports.form.model' }) },
    {
      id: 'type',
      sorting: true,
      label: formatMessage({ id: 'Reports.form.type' })
    },
    {
      id: 'alarm',
      sorting: true,
      label: formatMessage({ id: 'Reports.form.alarm' })
    },
    {
      id: 'description',
      sorting: true,
      label: formatMessage({ id: 'Reports.form.description' })
    }
  ]

  const csvHeaders = [
    { label: 'ID', key: 'id' },
    { label: formatMessage({ id: 'Reports.form.serialNumber' }), key: 'boilerSerialNumber' },
    { label: formatMessage({ id: 'Reports.form.model' }), key: 'boilerModel' },
    { label: formatMessage({ id: 'Reports.form.type' }), key: 'type' },
    { label: formatMessage({ id: 'Reports.form.alarm' }), key: 'alarm' },
    { label: formatMessage({ id: 'Reports.form.description' }), key: 'description' }
  ]

  return (
    <React.Fragment key={step}>
      <CustomForm form={reportForm} className={classes.formContainer}>
        <div className={classes.gridContainer}>
          <div className={classes.filtersContainer}>
            <div className={classes.body}>
              <CustomSelect
                form={reportForm}
                formFieldName={'alarmType'}
                labelId={'Reports.form.alarmType'}
                items={alarmTypes}
                disabled={true}
              />
              <CustomSelect
                form={reportForm}
                formFieldName={'alarm'}
                labelId={'Reports.form.alarm'}
                isMultiple={true}
                items={alarms}
                disabled={true}
              />
              <CustomChipsList
                form={reportForm}
                formFieldName={'boilersFullList'}
                labelId={'Reports.form.boilers'}
                offset={10}
              />
            </div>
          </div>
          <div className={classes.resultsContainer}>
            <div className={classes.filtersRow}>
              <Typography className={classes.tableTitle}>
                {formatMessage({ id: 'Reports.alarmHistory.activeAlarms' })}
              </Typography>
              <div className={classes.rightHeaders}>
                <div className={classes.exportButton}>
                  <CSVLink
                    headers={csvHeaders}
                    data={reportResult}
                    filename={`report_history_alarms_${moment().format()}.csv`}
                    enclosingCharacter={''}
                  >
                    <DownloadIcon />
                    {formatMessage({ id: 'Reports.actions.export' })}
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className={classes.resultsRow}>
              {isLoading && <Loader />}
              {!isLoading && reportResult.length === 0 && <NoData />}
              {!isLoading && reportResult.length > 0 && (
                <Table
                  columns={columns}
                  data={reportResult}
                  defaultOrderKey={parameters.sortBy}
                  defaultOrder={parameters.sorting.toLowerCase()}
                  onSortChange={handleSort}
                />
              )}
            </div>
          </div>
        </div>
      </CustomForm>
    </React.Fragment>
  )
})

export default AlarmHistoryReportStep
