import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { InputLabel } from '@material-ui/core'
import useStyles from './styles'

const CustomLabel = ({ labelId, labelClass }) => {
  const classes = useStyles()

  return (
    <InputLabel className={classNames(classes.label, labelClass)}>
      {useIntl().formatMessage({ id: labelId })}
    </InputLabel>
  )
}

export default CustomLabel
