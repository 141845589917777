import React, { createContext } from 'react'
import cookie from 'js-cookie'
import useCallService from 'services/useCallService'
import { TOKEN, PARTNERS, GET_BRANDS_URL, SELECTED_PARTNERS, MODEL_TYPES, GET_MODEL_TYPES_URL } from 'Constants'

const PartnersContext = createContext()

const PartnersContextProvider = ({ children }) => {
  const { callSecurityService, callBoilerService } = useCallService()

  const getPartners = async () => {
    const token = cookie.get(TOKEN) ? cookie.get(TOKEN) : null
    const partners = cookie.get(PARTNERS) ? cookie.get(PARTNERS) : null
    if (partners !== null) {
      return JSON.parse(partners)
    }
    if (token !== null) {
      const response = await callSecurityService('GET', GET_BRANDS_URL, {}, true)
      const partnersResponse = response.data.brands
      cookie.set(PARTNERS, partnersResponse)
      cookie.set(
        SELECTED_PARTNERS,
        partnersResponse.map((el) => el.enumName)
      )
      return partnersResponse
    }
  }

  const getModelTypes = async () => {
    const token = cookie.get(TOKEN) ? cookie.get(TOKEN) : null
    const modelTypes = cookie.get(MODEL_TYPES) ? cookie.get(MODEL_TYPES) : null
    if (modelTypes !== null) {
      return JSON.parse(modelTypes)
    }
    if (token !== null) {
      const response = await callBoilerService('GET', GET_MODEL_TYPES_URL, {}, true)
      const modelTypesResponse = response.data
      cookie.set(MODEL_TYPES, modelTypesResponse)
      return modelTypesResponse
    }
  }

  return <PartnersContext.Provider value={{ getPartners, getModelTypes }}>{children}</PartnersContext.Provider>
}

export default PartnersContextProvider

function usePartnersContext() {
  const context = React.useContext(PartnersContext)
  if (context === undefined) {
    throw new Error('usePartnersContext must be used within a PartnersContextProvider')
  }
  return context
}

export { usePartnersContext }
