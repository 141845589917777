import React, { memo } from 'react'
import { useIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import useStyles from './styles'

const ReportCard = ({ report, selected, onClick = () => undefined }) => {
  const classes = useStyles()
  const { name, icon } = report
  const { formatMessage } = useIntl()

  return (
    <div className={classNames(classes.card, { [classes.selected]: selected })} onClick={onClick}>
      <div className={classes.dataContainer}>
        <ReactSVG src={icon} className={classes.icon} />
        <div className={classes.label}>{formatMessage({ id: name })}</div>
      </div>
    </div>
  )
}

export default memo(ReportCard)
