import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  loadMore: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 50,
    marginBottom: 20,
    alignItems: 'center',
    justifyContent: 'center'
  },
  line: {
    flex: 1,
    height: 1,
    opacity: 0.49,
    borderBottom: `1px solid ${theme.palette.web.usbGrey}`
  },
  loadMoreText: {
    margin: '0 15px',
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: 0.16,
    color: theme.palette.web.darkGrey,
    textTransform: 'uppercase',
    cursor: 'pointer'
  }
}))
