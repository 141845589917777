import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%'
  }
}))
