import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  checkboxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  checkbox: {
    padding: 0,
    color: theme.palette.web.lightGrey,
    '&.Mui-checked:hover, &:hover': {
      backgroundColor: 'transparent'
    }
  },
  notificationLabel: {
    marginLeft: 5,
    fontSize: 14,
    fontWeight: 'bold',
    letterSpacing: '-0.44px',
    color: theme.palette.web.darkGrey
  },
  checked: {
    color: theme.palette.web.blue
  }
}))
