import React from 'react'
import { useIntl } from 'react-intl'
import shortId from 'shortid'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import useStyles from './styles'

const CustomRadioButtonGroup = ({
  radioButtonList,
  selectedValue,
  handleChange,
  needsTranslate = false,
  valueField = 'value'
}) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <FormControl>
      <RadioGroup value={selectedValue} onChange={handleChange}>
        {radioButtonList.map((radioButton) => (
          <FormControlLabel
            classes={{ root: classes.label }}
            key={shortId.generate()}
            value={radioButton[valueField]}
            control={
              <Radio
                classes={{ root: classes.root, checked: classes.checked }}
                color={'default'}
                disableRipple={true}
              />
            }
            label={needsTranslate ? formatMessage({ id: radioButton.label }) : radioButton.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default CustomRadioButtonGroup
