import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import { ListItem, ListItemIcon, ListItemText, Tooltip } from '@material-ui/core'
import useStyles from './styles'

const SidebarLink = ({ id, link, icon, label, isSidebarOpened }) => {
  const classes = useStyles()
  const isLinkActive = link
  const currentPath = useLocation()

  return (
    <Link to={link}>
      <ListItem
        id={id}
        button
        selected={currentPath.pathname.includes(link)}
        className={classes.link}
        classes={{
          root: classNames({
            [classes.linkActive]: isLinkActive
          })
        }}
        disableRipple
      >
        <ListItemIcon className={classNames(classes.linkIcon)}>
          {!isSidebarOpened && (
            <Tooltip title={<FormattedMessage id={label} />} placement="right">
              <ReactSVG src={icon} className={classes.linkImage} />
            </Tooltip>
          )}
          {isSidebarOpened && <ReactSVG src={icon} className={classes.linkImage} />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classNames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened
            })
          }}
          primary={<FormattedMessage id={label} />}
        />
      </ListItem>
    </Link>
  )
}

export default SidebarLink
