import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import Checkbox from '@material-ui/core/Checkbox/Checkbox'
import CheckBoxOutlineBlankOutlinedIcon from '@material-ui/icons/CheckBoxOutlineBlankOutlined'
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined'
import { FormControlLabel } from '@mui/material'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomRadioButtonGroup from 'components/CustomRadioButtonGroup/CustomRadioButtonGroup'
import useStyles from './styles'

const FiltersForm = ({ brands, selectedBrands, sorting, selectedSorting, onApply = () => undefined }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const [selectedBrandsTemp] = useState([...selectedBrands])
  const [selectedSortingTemp, setSelectedSortingTemp] = useState(selectedSorting)

  const checkBrand = (e) => {
    if (e.target.checked) {
      selectedBrandsTemp[e.target.id - 1] = e.target.value
    } else {
      selectedBrandsTemp[e.target.id - 1] = null
    }
  }

  const selectSorting = (e) => {
    setSelectedSortingTemp(e.target.value)
  }

  return (
    <div className={classes.container}>
      <div className={classes.brandsContainer}>
        <div className={classes.subtitle}>{formatMessage({ id: 'Dashboard.filters.brands' })}</div>
        <div className={classes.checkboxes}>
          {brands.map((brand) => (
            <FormControlLabel
              key={brand.id.toString()}
              control={
                <Checkbox
                  icon={<CheckBoxOutlineBlankOutlinedIcon />}
                  checkedIcon={<CheckBoxOutlinedIcon className={classes.checked} />}
                  checked={selectedBrandsTemp[brand.id - 1] != null || false}
                  onChange={checkBrand}
                  className={classes.checkbox}
                  disableRipple={true}
                  value={brand.enumName}
                  id={brand.id.toString()}
                />
              }
              classes={{ label: classes.label }}
              className={classes.checkboxLabel}
              label={brand.name}
            />
          ))}
        </div>
      </div>
      <div className={classes.sortingContainer}>
        <div className={classes.subtitle}>{formatMessage({ id: 'Dashboard.filters.sorting' })}</div>
        <div className={classes.radioButtons}>
          <CustomRadioButtonGroup
            selectedValue={selectedSortingTemp}
            radioButtonList={sorting}
            needsTranslate={true}
            valueField={'key'}
            handleChange={selectSorting}
          />
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <CustomButton
          labelId={'Commons.button.apply'}
          action={() => onApply(selectedBrandsTemp, selectedSortingTemp)}
          fullWidth={true}
        />
      </div>
    </div>
  )
}

export default FiltersForm
