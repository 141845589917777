import React from 'react'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { TextField } from '@material-ui/core'
import useStyles from './styles'

const CustomTextField = ({
  form,
  formFieldName,
  labelClasses,
  inputClasses,
  labelId,
  type = 'text',
  inputProps = null,
  inputVariant = 'standard',
  autoComplete = 'off',
  required = false,
  disabled = false,
  serviceError = false,
  multiline = false,
  rows = 1,
  rowsMax = 1,
  helperText = '',
  helperTextClasses = null
}) => {
  const classes = useStyles()

  return (
    <TextField
      FormHelperTextProps={{
        className: classNames(
          classes.helperText,
          multiline ? classes.helperTextMultilinePosition : classes.helperTextPosition,
          helperTextClasses,
          {
            [classes.errorMessage]: (form.touched[formFieldName] && Boolean(form.errors[formFieldName])) || serviceError
          }
        )
      }}
      InputLabelProps={{
        disableAnimation: true,
        shrink: true,
        classes: {
          shrink: classes.shrink,
          formControl: classes.label,
          ...labelClasses
        }
      }}
      InputProps={{
        disableUnderline: true,
        classes: {
          formControl: classes.formControl,
          root: classes.root,
          input: classes.input,
          ...inputClasses
        },
        ...inputProps
      }}
      id={formFieldName}
      name={formFieldName}
      value={form.values[formFieldName]}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
      error={(form.touched[formFieldName] && Boolean(form.errors[formFieldName])) || serviceError}
      helperText={
        (form.touched[formFieldName] && Boolean(form.errors[formFieldName])) || serviceError
          ? form.errors[formFieldName]
          : helperText
      }
      margin="normal"
      variant={inputVariant}
      label={useIntl().formatMessage({ id: labelId })}
      type={type}
      fullWidth
      autoComplete={autoComplete}
      required={required}
      disabled={disabled}
      multiline={multiline}
      minRows={rows}
      maxRows={rowsMax}
    />
  )
}

export default CustomTextField
