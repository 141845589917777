import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  bottomContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      marginTop: 40
    }
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: 40
    }
  },
  text: {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 18,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.44',
    letterSpacing: '0.24px',
    color: theme.palette.web.blueGrey
  }
}))
