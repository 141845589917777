import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    padding: '24px 33px 0px 33px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  containerHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  rightHeaders: {
    display: 'flex',
    columnGap: '20px'
  },
  dataContainer: {
    flex: 1,
    width: '100%',
    marginTop: 25,
    padding: '24px 48px',
    borderRadius: 8,
    boxShadow: `0 2px 4px 0 ${theme.palette.web.shadowGrey}`,
    backgroundColor: theme.palette.web.white
  },
  viewButton: {
    color: theme.palette.web.blue
  },
  loadMoreMargin: {
    marginTop: 20
  }
}))
