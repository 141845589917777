import React from 'react'
import AltaFirmwareForm from 'components/AltaFirmwareForm/AltaFirmwareForm'
import Modal from 'components/Modal/Modal'

const AltaFirmwareModal = ({ open = false, handleClose, onSuccess, title }) => (
  <Modal open={open} handleClose={handleClose} title={title} fullWidth={false}>
    <AltaFirmwareForm handleClose={handleClose} onSuccess={onSuccess} />
  </Modal>
)

export default AltaFirmwareModal
