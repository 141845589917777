import React from 'react'
import { useIntl } from 'react-intl'
import { ReactSVG } from 'react-svg'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import ICON_ERROR from 'assets/images/icons-error-ic.svg'
import useStyles from './styles'

const ConnectionBadge = ({ badgeClassName, connected, type, subType, green }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <div
      className={classNames(badgeClassName || classes.cardFooter, {
        [classes.cardFooterRed]: connected && !green,
        [classes.cardFooterGreen]: connected && green,
        [classes.cardFooterGrey]: !connected
      })}
    >
      {connected && !green && <ReactSVG src={ICON_ERROR} className={classes.errorIcon} />}
      <Typography className={classes.status}>
        {connected ? type : formatMessage({ id: 'Connection.noConnection' })}
      </Typography>
      {connected && <Typography className={classes.message}>{subType}</Typography>}
    </div>
  )
}

export default ConnectionBadge
