import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const PageTitle = ({ titleId, values }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.title}>
      <FormattedMessage id={titleId} values={values} />
    </Typography>
  )
}

export default PageTitle
