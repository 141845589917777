import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  section: {
    marginTop: 10,
    marginBottom: 50
  },
  sectionTitle: {
    fontSize: 18,
    color: theme.palette.web.usbGrey
  }
}))
