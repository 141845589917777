import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import BoilerStatusInfo from 'components/BoilerStatusInfo/BoilerStatusInfo'
import Layout from 'components/Layout/Layout'
import { useMobileContext } from 'context/MobileContext'

const BoilerStatus = () => {
  const { boilerId } = useParams()
  const { setShowBackButton } = useMobileContext()

  useEffect(() => {
    setShowBackButton(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Layout>
        <BoilerStatusInfo boilerId={boilerId} />
      </Layout>
    </>
  )
}

export default BoilerStatus
