import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  loginContainer: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex'
  },
  customForm: {
    flexDirection: 'column',
    flex: 1,
    display: 'flex'
  },
  form: {
    padding: `${theme.spacing(2)}px 0`,
    flex: 1
  },
  bottomContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  forgotMessage: {
    fontSize: 12,
    fontWeight: 600,
    textDecoration: 'underline',
    color: theme.palette.web.blueGrey,
    cursor: 'pointer',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.web.blue,
      marginTop: 20
    }
  }
}))
