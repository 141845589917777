import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import moment from 'moment'
import { Button, IconButton, CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { ArrowBack, ArrowBackIos, ArrowForwardIos, Delete } from '@material-ui/icons'
import useStyles from './styles'

const DetailedPhotoView = ({ onBack, activePhoto, onPreviousPhoto, onNextPhoto, onDelete }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  const [loadingImage, setLoadingImage] = useState(false)

  const handleDelete = async () => {
    try {
      setLoadingImage(true)
      await onDelete(activePhoto.id)
    } finally {
      setLoadingImage(false)
    }
  }

  return (
    <div className={classes.detailedPhotoContainer}>
      <div className={classes.backButtonRow}>
        <Button onClick={onBack} startIcon={<ArrowBack />} className={classes.backButton}>
          <Typography>{formatMessage({ id: 'BoilerStatus.photos.action.back' })}</Typography>
        </Button>
      </div>
      <div className={classes.imageRow}>
        <IconButton disabled={loadingImage || onPreviousPhoto === null} onClick={onPreviousPhoto}>
          <ArrowBackIos />
        </IconButton>
        {loadingImage && (
          <div className={classes.loadingImage}>
            <CircularProgress />
          </div>
        )}
        {!loadingImage && <img className={classes.activeImage} src={activePhoto.url} />}
        <IconButton disabled={loadingImage || onNextPhoto === null} onClick={onNextPhoto}>
          <ArrowForwardIos />
        </IconButton>
      </div>
      <div className={classes.imageDetailRow}>
        {moment(activePhoto.createdDate).format('L LTS')}
        <Button disabled={loadingImage} onClick={handleDelete} startIcon={<Delete />}>
          <Typography>{formatMessage({ id: 'BoilerStatus.photos.action.delete' })}</Typography>
        </Button>
      </div>
    </div>
  )
}

export default DetailedPhotoView
