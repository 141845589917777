import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const PolicyBlock = ({ id, title, children }) => {
  const classes = useStyles()

  return (
    <div>
      <Typography id={id} className={classNames(classes.text, classes.title)}>
        <FormattedMessage id={title} />
      </Typography>
      <div>{children}</div>
    </div>
  )
}

export default PolicyBlock
