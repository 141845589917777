import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  tableCell: {
    border: 0,
    padding: 12,
    fontSize: 12,
    fontWeight: 600,
    color: theme.palette.web.darkGrey
  },
  checkboxCell: {
    width: '5%'
  },
  smallCell: {
    width: '10%'
  },
  mediumCell: {
    width: '20%'
  },
  largeCell: {
    width: '25%'
  },
  tableHeader: {
    paddingTop: 0,
    paddingBottom: 20,
    fontSize: 10,
    fontWeight: 'bold',
    letterSpacing: 1,
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase'
  },
  sortIcon: {
    '& svg': {
      margin: '0px 10px'
    }
  },
  sortedColumn: {
    color: `${theme.palette.web.black} !important`,
    '& svg': {
      fill: theme.palette.web.darkRed
    }
  },
  sortedData: {
    fontWeight: 'bold'
  },
  actionsContainer: {
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    '& :not(:first-child)': {
      marginLeft: 20
    }
  },
  actionButton: {
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.web.darkGrey,
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  viewButton: {
    color: theme.palette.web.blue
  },
  actionDisabled: {
    color: `${theme.palette.web.lightGrey} !important`,
    cursor: 'default',
    '& button': {
      fill: `${theme.palette.web.lightGrey} !important`,
      cursor: 'default'
    },
    '& svg': {
      fill: `${theme.palette.web.lightGrey} !important`,
      cursor: 'default'
    }
  },
  checked: {
    color: theme.palette.web.blue
  },
  checkbox: {
    padding: 0,
    color: theme.palette.web.lightGrey,
    '&.Mui-checked:hover, &:hover': {
      backgroundColor: 'transparent'
    }
  }
}))
