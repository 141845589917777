import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const ReadOnlyTextField = ({ labelId, value = '' }) => {
  const classes = useStyles()

  return (
    <div className={classes.readOnlyTextField}>
      <Typography className={classes.label}>
        <FormattedMessage id={labelId} />
      </Typography>
      <div className={classes.valueBox}>
        <Typography className={classes.value}>{value}</Typography>
      </div>
    </div>
  )
}

export default ReadOnlyTextField
