import { makeStyles } from '@material-ui/styles'

const drawerWidth = 165

export default makeStyles((theme) => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
    flex: `0 0 ${drawerWidth}`
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.palette.text.primary,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    backgroundColor: theme.palette.text.primary,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 48,
    [theme.breakpoints.down('sm')]: {
      width: drawerWidth
    }
  },
  toolbar: {
    backgroundColor: theme.palette.sidebar.primary,
    height: 78,
    ...theme.mixins.toolbar,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  noBorder: {
    border: 0
  },
  list: {
    padding: 0,
    '& a': {
      textDecoration: 'none'
    }
  },
  sidebarMenuButton: {
    float: 'right',
    marginTop: theme.spacing(2.5),
    padding: '0px !important',
    '& span.MuiIconButton-label': {
      padding: `8px 8px 0px 0px`,
      textAlign: 'right',
      display: 'block'
    }
  }
}))
