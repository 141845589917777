import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  title: {
    paddingTop: 0,
    paddingRight: 0,
    paddingLeft: 0,
    '& h2': {
      fontSize: 24,
      fontWeight: 'bold',
      color: theme.palette.web.darkGrey
    }
  },
  closeContainer: {
    textAlign: 'right',
    padding: '10px 15px 0 0'
  },
  closeIcon: {
    fontSize: 0,
    padding: 0,
    '& svg': {
      fill: theme.palette.web.darkGrey,
      width: 14,
      height: 14
    }
  },
  modalContainer: {
    padding: '0 110px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      padding: '0 25px'
    }
  },
  bodyContainer: {
    padding: 0,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  scrollPaper: {
    display: 'flex'
  }
}))
