import React, { createContext } from 'react'
import { useIntl } from 'react-intl'
import cookie from 'js-cookie'
import { LANGUAGE } from 'Constants'

const LanguageContext = createContext()

const LanguageContextProvider = ({ children, language, updateLanguage }) => {
  const { formatMessage } = useIntl()
  const languagesList = [
    { value: 'en', label: formatMessage({ id: 'Language.english' }) },
    { value: 'es', label: formatMessage({ id: 'Language.spanish' }) }
  ]

  const changeLanguage = (lang) => {
    cookie.set(LANGUAGE, lang)
    updateLanguage()
  }

  return (
    <LanguageContext.Provider value={{ language, languagesList, changeLanguage, updateLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContextProvider

function useLanguageContext() {
  const context = React.useContext(LanguageContext)
  if (context === undefined) {
    throw new Error('useAuthorizationContext must be used within a AuthorizationContextProvider')
  }
  return context
}

export { useLanguageContext }
