import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  label: {
    fontSize: '13px',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.38px',
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase',
    marginTop: theme.spacing(1)
  }
}))
