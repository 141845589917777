import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  title: {
    color: theme.palette.web.darkGrey,
    fontSize: 32,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    [theme.breakpoints.down('sm')]: {
      fontSize: 28
    }
  }
}))
