import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    padding: '24px 33px 0px 33px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  containerHeader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  dataContainer: {
    flex: 1,
    width: '100%',
    marginTop: 25,
    padding: '24px',
    borderRadius: 8,
    boxShadow: `0 2px 4px 0 ${theme.palette.web.shadowGrey}`,
    backgroundColor: theme.palette.web.white,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  },
  upperCaseText: {
    fontSize: 14,
    lineHeight: 1.57,
    color: theme.palette.web.darkGrey,
    textTransform: 'uppercase'
  },
  text: {
    fontSize: 14,
    lineHeight: 1.57,
    color: theme.palette.web.darkGrey
  },
  title: {
    fontWeight: 'bold',
    marginTop: 30,
    marginBottom: 10
  },
  newParagraph: {
    marginTop: 15
  },
  indexContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  index: {
    marginRight: 10
  },
  indexMargin: {
    marginLeft: 25
  }
}))
