import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useIntl } from 'react-intl'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { Button } from '@mui/material'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomNumberInput from 'components/CustomNumberInput/CustomNumberInput'
import CustomSelect from 'components/CustomSelect/CustomSelect'
import NavbarCheckboxes from 'components/NavbarCheckboxes/NavbarCheckboxes'
import { usePartnersContext } from 'context/PartnersContext'
import { ENROLLED_AUDIENCE_FORM_CONFIRMATION_STEP_ID, REPORTS_STEP_ID } from 'data/ReportsData'
import useStyles from './styles'

const EnrolledAudienceFormStep = forwardRef(
  ({ step, changeStep = () => undefined, reportForm, userStatuses, representatives }, ref) => {
    const classes = useStyles()
    const { formatMessage } = useIntl()
    const { getPartners } = usePartnersContext()
    const [partners, setPartners] = useState([])
    const [parameters, setParameters] = useState({
      partners: []
    })

    useEffect(() => {
      async function fetchPartners() {
        return getPartners()
      }

      fetchPartners().then((response) => {
        setPartners(response)
        setParameters(() => ({
          partners: response.map((el) => el.enumName)
        }))
      })
    }, [getPartners])

    const checkPartners = (e) => {
      if (e.target.checked) {
        parameters.partners[e.target.id - 1] = e.target.value
      } else {
        parameters.partners[e.target.id - 1] = null
      }
      setParameters((currentParameters) => ({
        partners: currentParameters.partners
      }))
    }

    const apply = () => {
      reportForm.setFieldValue('filters', parameters)
      changeStep(ENROLLED_AUDIENCE_FORM_CONFIRMATION_STEP_ID)
    }

    const back = () => {
      changeStep(REPORTS_STEP_ID)
    }

    const reset = () => {
      reportForm.resetForm()
      setParameters(() => ({
        partners: partners.map((el) => el.enumName)
      }))
      reportForm.setFieldValue('statuses', 'ALL')
      reportForm.setFieldValue('representative', 'ALL')
      reportForm.setFieldValue('boilersAssigned', '')
      reportForm.setFieldValue('filters', null)
    }

    useImperativeHandle(ref, () => ({
      reset
    }))

    return (
      <React.Fragment key={step}>
        <CustomForm form={reportForm} className={classes.formContainer}>
          <div className={classes.gridContainer}>
            <div className={classes.filtersContainer}>
              <div className={classes.body}>
                <CustomSelect
                  form={reportForm}
                  formFieldName={'statuses'}
                  labelId={'Reports.form.statuses'}
                  items={userStatuses}
                  disabled={false}
                />
                <CustomSelect
                  form={reportForm}
                  formFieldName={'representative'}
                  labelId={'Reports.form.representative'}
                  items={representatives}
                  disabled={false}
                />
                <CustomNumberInput
                  type={'number'}
                  form={reportForm}
                  formFieldName={'boilersAssigned'}
                  labelId={'Reports.form.boilersAssigned'}
                  disabled={false}
                />
              </div>
            </div>
            <div className={classes.resultsContainer}>
              <div className={classes.filtersRow}>
                <div className={classes.rightHeaders}>
                  <NavbarCheckboxes
                    values={parameters.partners}
                    checkboxes={partners}
                    onChange={checkPartners}
                    groupLabel={formatMessage({ id: 'Reports.form.brands' })}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bottom}>
            <Button onClick={back} className={classes.transitionButton}>
              <KeyboardArrowLeft /> {formatMessage({ id: 'Reports.actions.back' })}
            </Button>
            <div>
              <Button onClick={reset} className={classes.secondaryButton}>
                {formatMessage({ id: 'Reports.actions.clear' })}
              </Button>
              <Button onClick={apply} className={classes.primaryButton}>
                {formatMessage({ id: 'Reports.actions.apply' })}
              </Button>
            </div>
          </div>
        </CustomForm>
      </React.Fragment>
    )
  }
)

export default EnrolledAudienceFormStep
