import React from 'react'
import classNames from 'classnames'
import useStyles from 'components/StatusTab/styles'

const StatusTableRow = ({ label, value, secondaryValue = null }) => {
  const classes = useStyles()

  return (
    <div className={classes.tableRow}>
      <div className={classNames(classes.statusCell, classes.statusLabel)}>{label}</div>
      <div className={classNames(classes.statusCell, classes.statusValue)}>{secondaryValue}</div>
      <div className={classNames(classes.statusCell, classes.statusValue)}>
        {value !== undefined && value !== null ? value : ''}
      </div>
    </div>
  )
}

export default StatusTableRow
