import React from 'react'
import { useIntl } from 'react-intl'
import CompanyForm from 'components/CompanyForm/CompanyForm'
import useStyles from 'components/CompanyModal/styles'
import Modal from 'components/Modal/Modal'

const CompanyModal = ({ open = false, handleClose, handleSubmit, inputValue }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()

  return (
    <Modal
      open={open}
      handleClose={handleClose}
      title={formatMessage({ id: 'Users.form.company.create.title' })}
      fullWidth={false}
      classNameModal={classes.modal}
    >
      <CompanyForm handleClose={handleClose} handleSubmit={handleSubmit} inputValue={inputValue} />
    </Modal>
  )
}

export default CompanyModal
