import React from 'react'
import { FormattedMessage } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const TextBlock = ({ label, values, style }) => {
  const classes = useStyles()

  return (
    <Typography className={classes.text} style={style}>
      <FormattedMessage id={label} values={values} />
    </Typography>
  )
}

export default TextBlock
