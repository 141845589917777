import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Typography from '@material-ui/core/Typography'
import useStyles from 'components/CompanyForm/styles'
import CustomButton from 'components/CustomButton/CustomButton'
import CustomForm from 'components/CustomForm/CustomForm'
import CustomLabel from 'components/CustomLabel/CustomLabel'
import CustomTextField from 'components/CustomTextField/CustomTextField'
import FieldError from 'components/FieldError/FieldError'
import useCallService from 'services/useCallService'
import { CREATE_COMPANY_URL } from 'Constants'

const CompanyForm = ({ handleClose, handleSubmit, inputValue }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const { callSecurityService } = useCallService()
  const [serviceError, setServiceError] = useState(false)
  const [serviceErrorMessage, setServiceErrorMessage] = useState('')

  const authValidationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    address: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    city: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    state: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' })),
    zip: yup
      .string()
      .trim()
      .required(formatMessage({ id: 'Commons.error.required' }))
  })

  const authForm = useFormik({
    initialValues: {
      name: inputValue || '',
      address: '',
      city: '',
      state: '',
      zip: ''
    },
    validationSchema: authValidationSchema,
    onSubmit: async (values) => {
      setServiceError(false)
      const createCompanyRequest = {
        name: values.name,
        geofence: {
          address: values.address,
          city: values.city,
          state: values.state,
          zipCode: values.zip
        }
      }
      try {
        const response = await callSecurityService('POST', CREATE_COMPANY_URL, { data: createCompanyRequest }, true)
        handleSubmit(response.data)
        handleClose()
      } catch (error) {
        setServiceError(true)
        if (error.response && error.response.data.message) {
          setServiceErrorMessage(error.response.data.message)
        } else {
          setServiceErrorMessage(formatMessage({ id: 'Commons.genericError' }))
        }
      }
    }
  })

  return (
    <div>
      <CustomForm form={authForm}>
        <div>
          <CustomTextField
            form={authForm}
            formFieldName={'name'}
            labelId={'Users.form.company.create.name'}
            labelClasses={{ formControl: classes.label }}
            inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
            helperTextClasses={classes.helperTextPosition}
            serviceError={serviceError}
          />
          <CustomLabel labelId={'Users.form.company.create.companyAddress'} labelClass={classes.companyAddressLabel} />
          <div className={classes.companyAddress}>
            <CustomTextField
              form={authForm}
              formFieldName={'address'}
              labelId={'Users.form.company.create.address'}
              labelClasses={{ formControl: classes.label }}
              inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
              helperTextClasses={classes.helperTextPosition}
              serviceError={serviceError}
            />
            <CustomTextField
              form={authForm}
              formFieldName={'city'}
              labelId={'Users.form.company.create.city'}
              labelClasses={{ formControl: classes.label }}
              inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
              helperTextClasses={classes.helperTextPosition}
              serviceError={serviceError}
            />
            <CustomTextField
              form={authForm}
              formFieldName={'state'}
              labelId={'Users.form.company.create.state'}
              labelClasses={{ formControl: classes.label }}
              inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
              helperTextClasses={classes.helperTextPosition}
              serviceError={serviceError}
            />
            <CustomTextField
              form={authForm}
              formFieldName={'zip'}
              labelId={'Users.form.company.create.zip'}
              labelClasses={{ formControl: classes.label }}
              inputClasses={{ formControl: classes.formControl, root: classes.root, input: classes.input }}
              helperTextClasses={classes.helperTextPosition}
              serviceError={serviceError}
            />
          </div>
          <FieldError hasError={serviceError} errorMessage={serviceErrorMessage} />
        </div>
        <div className={classes.actions}>
          <div className={classes.backButton} onClick={handleClose}>
            <Typography>
              <FormattedMessage id={'Commons.button.cancel'} />
            </Typography>
          </div>
          <CustomButton
            disabled={!authForm.isValid || authForm.isSubmitting}
            labelId={'Commons.button.save'}
            type={'submit'}
          />
        </div>
      </CustomForm>
    </div>
  )
}

export default CompanyForm
