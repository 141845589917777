import React from 'react'
import { ReactSVG } from 'react-svg'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Typography from '@material-ui/core/Typography'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import MenuPopper from 'components/MenuPopper/MenuPopper'
import { useMobileContext } from 'context/MobileContext'
import useStyles from './styles'

const ProfileMenu = ({ children, icon, text }) => {
  const classes = useStyles()
  const { isMobile } = useMobileContext()
  const anchorRef = React.useRef(null)
  const [open, setOpen] = React.useState(false)

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  return (
    <>
      <ButtonGroup
        className={classes.buttonContainer}
        ref={anchorRef}
        onClick={() => handleToggle()}
        variant="contained"
      >
        <Button disableTouchRipple className={classes.profileButton}>
          <ReactSVG className={classes.profileIcon} src={icon} />
          {!isMobile && <Typography className={classes.text}>{text}</Typography>}
          {!isMobile && <ArrowDropDownIcon className={classes.arrowIcon} />}
        </Button>
      </ButtonGroup>
      <MenuPopper open={open} anchorRef={anchorRef} handleClose={handleClose}>
        {children}
      </MenuPopper>
    </>
  )
}

export default ProfileMenu
