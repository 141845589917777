import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginTop: 30,
    overflowX: 'auto'
  },
  indicator: {
    [theme.breakpoints.up('sm')]: {
      height: 6,
      backgroundColor: theme.palette.web.blue
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent'
    }
  },
  tab: {
    minWidth: 113,
    paddingBottom: 20
  },
  tabContainer: {
    height: '100%',
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
      borderRadius: 8,
      boxShadow: `0 2px 4px 0 ${theme.palette.web.cardShadow}`,
      padding: '23px 30px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 19px'
    }
  },
  textWrapper: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
    color: theme.palette.web.usbGrey,
    [theme.breakpoints.down('sm')]: {
      height: 36,
      borderRadius: 4,
      backgroundColor: theme.palette.web.greyRow,
      fontSize: 14,
      fontStyle: 'normal',
      lineHeight: 'normal',
      textTransform: 'capitalize',
      color: theme.palette.web.mobileTabText
    }
  },
  selected: {
    [theme.breakpoints.up('sm')]: {
      '& span': {
        fontWeight: 800,
        color: theme.palette.web.darkGrey
      }
    },
    [theme.breakpoints.down('sm')]: {
      '& span': {
        backgroundColor: theme.palette.web.blue,
        color: theme.palette.web.white
      }
    }
  },
  scroller: {
    flex: '0 0 auto'
  },
  fixed: {
    width: 'auto'
  },
  tabsHeader: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.up('sm')]: {
      borderBottom: `1px solid ${theme.palette.web.usbGrey}`
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: `1px solid ${theme.palette.web.mobileTabsBorder}`
    }
  },
  searchBar: {
    marginBottom: 10,
    flex: 1,
    textAlign: 'end',
    alignSelf: 'center'
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
      overflowY: 'hidden',
      whiteSpace: 'nowrap',
      scrollbarWidth: 'none' /* Firefox */,
      msOverflowStyle: 'none' /* Internet Explorer 11 */,
      '&::-webkit-scrollbar': {
        display: 'none' /* WebKit (Chrome, Safari) */
      }
    }
  }
}))
