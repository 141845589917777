import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%'
  },
  subtitle: {
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: 'normal',
    color: theme.palette.web.darkGrey
  },
  brandsContainer: {
    marginTop: 5
  },
  checkboxes: {
    display: 'flex',
    flexDirection: 'column'
  },
  checked: {
    color: theme.palette.web.blue
  },
  checkbox: {
    padding: 5,
    color: theme.palette.web.lightGrey,
    '&.Mui-checked:hover, &:hover': {
      backgroundColor: 'transparent'
    }
  },
  label: {
    paddingTop: 3
  },
  checkboxLabel: {
    marginTop: 10,
    '&.MuiTypography-root': {
      fontSize: '14px !important'
    }
  },
  sortingContainer: {
    marginTop: 20
  },
  radioButtons: {
    display: 'flex',
    flexDirection: 'column'
  },
  buttonContainer: {
    marginTop: 20
  }
}))
