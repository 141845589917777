import React from 'react'
import classNames from 'classnames'
import { SnackbarProvider as NisSnackbarProvider } from 'notistack'
import VariantIcon from 'components/Snackbar/VariantIcon'
import errorIcon from 'assets/images/icons-error-toast.svg'
import successIcon from 'assets/images/icons-success-toast.svg'
import warningIcon from 'assets/images/icons-warning-toast.svg'
import CloseButton from './CloseButton'
import useStyles from './styles'

const SnackbarProvider = ({ children }) => {
  const classes = useStyles()

  const iconVariant = {
    error: <VariantIcon icon={errorIcon} />,
    success: <VariantIcon icon={successIcon} />,
    warning: <VariantIcon icon={warningIcon} />
  }

  const snackbarClasses = {
    anchorOriginTopRight: classes.topRight,
    variantError: classNames(classes.snackbar, classes.variantError),
    variantSuccess: classNames(classes.snackbar, classes.variantSuccess),
    variantWarning: classNames(classes.snackbar, classes.variantWarning)
  }

  return (
    <NisSnackbarProvider
      action={(id) => <CloseButton id={id} />}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      classes={snackbarClasses}
      iconVariant={iconVariant}
      autoHideDuration={3000}
      preventDuplicate={true}
    >
      {children}
    </NisSnackbarProvider>
  )
}

export default SnackbarProvider
