import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  autoCompleteBox: {
    marginTop: 16
  },
  labelRoot: {
    fontSize: '16px !important',
    fontWeight: 'normal !important',
    fontStretch: 'normal !important',
    fontStyle: 'normal !important',
    lineHeight: 'normal !important',
    letterSpacing: '-0.38px !important',
    color: `${theme.palette.web.usbGrey} !important`,
    textTransform: 'uppercase !important',
    '&.Mui-focused, &.Mui-error': {
      color: `${theme.palette.web.usbGrey} !important`
    }
  },
  labelShrink: {
    transform: 'scale(0.8) !important'
  },
  labelDisabled: {
    color: `${theme.palette.web.disabled} !important`
  },
  autocompleteInputRoot: {
    height: 36,
    borderRadius: '2px !important',
    padding: '0px !important'
  },
  inputRoot: {
    margin: '5px 0 !important',
    '& .Mui-error': {
      '& fieldset': {
        border: `1px solid ${theme.palette.web.red} !important`
      }
    },
    '& .Mui-error:hover': {
      '& fieldset': {
        border: `1px solid ${theme.palette.web.red} !important`
      }
    },
    '& .Mui-error.Mui-focused': {
      '& fieldset': {
        border: `1px solid ${theme.palette.web.red} !important`
      }
    },
    '& .MuiOutlinedInput-root:hover': {
      '& fieldset': {
        border: `1px solid ${theme.palette.web.lightGrey} !important`
      }
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        border: `1px solid ${theme.palette.web.lightGrey} !important`
      }
    }
  },
  errorMessage: {
    fontFamily: `${['Open Sans'].join(',')} !important`,
    position: 'absolute !important',
    top: '35px !important',
    color: `${theme.palette.web.red} !important`,
    fontSize: '12px !important',
    fontWeight: '400 !important',
    letterSpacing: '0.16px !important',
    lineHeight: 'normal !important',
    margin: '3px 0px 10px 0px !important'
  },
  paper: {
    border: `1px solid ${theme.palette.web.lightGrey} !important`,
    boxShadow: 'none !important',
    borderRadius: '2px !important',
    backdropFilter: 'blur(5px) !important',
    backgroundColor: `${theme.palette.web.dropdownColor} !important`,
    fontFamily: `${['Open Sans'].join(',')} !important`,
    fontSize: '12px !important',
    fontWeight: '600 !important',
    fontStretch: 'normal !important',
    fontStyle: 'normal !important',
    lineHeight: 'normal !important',
    letterSpacing: '0.19px !important',
    color: `${theme.palette.web.darkGrey} !important`
  },
  input: {
    fontFamily: `${['Open Sans'].join(',')} !important`,
    fontSize: '14px !important',
    fontWeight: 'normal !important',
    fontStretch: 'normal !important',
    fontStyle: 'normal !important',
    lineHeight: 'normal !important',
    letterSpacing: '0.19px !important',
    padding: '0 6px 0 12px !important'
  }
}))
