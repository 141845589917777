import React from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import useStyles from './styles'

const BoilerDataRow = ({
  className,
  label,
  value,
  optional,
  bold = true,
  email = false,
  wrap = false,
  isStatus = false
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.dataRow, className)}>
      <Typography className={isStatus ? classes.statusLabel : classes.label}>
        <FormattedMessage id={label} />
      </Typography>
      <div
        className={classNames(isStatus ? classes.statusValueBox : classes.valueBox, classes.textOverflow, {
          [classes.wrapText]: wrap
        })}
      >
        <Typography
          className={classNames(classes.value, {
            [classes.boldValue]: bold && !isStatus,
            [classes.statusBoldValue]: bold && isStatus
          })}
        >
          {!email ? (
            value
          ) : (
            <a className={classes.emailValue} href={`mailto:${value}`} target="_blank" rel="noreferrer">
              {value}
            </a>
          )}
        </Typography>
        {optional && <Typography className={classes.value}>{optional}</Typography>}
      </div>
    </div>
  )
}

export default BoilerDataRow
