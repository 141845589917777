import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    flex: 1,
    [theme.breakpoints.up('sm')]: {
      padding: '24px 33px 0px 33px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '24px 24px 0px 20px'
    }
  },
  containerHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'normal',
      flexDirection: 'column'
    }
  },
  mobileFilters: {
    display: 'flex',
    flexDirection: 'column'
  },
  mobileSearch: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 24
  },
  filterIcon: {
    fontSize: 0,
    '& svg': {
      fill: theme.palette.web.usbGrey,
      width: 30,
      height: 30
    },
    marginLeft: 16,
    padding: 0
  },
  mobileRoot: {
    width: '100%'
  },
  rightHeaders: {
    display: 'flex',
    columnGap: '20px'
  },
  gridRoot: {
    margin: '20px 0',
    display: 'grid',
    gridGap: '1rem',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))'
  },
  sortingOption: {
    minHeight: 28,
    height: 30
  }
}))
