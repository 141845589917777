import React from 'react'
import classNames from 'classnames'
import Chip from '@material-ui/core/Chip'
import useStyles from './styles'

const ChipItem = ({ item, icon, onAction, chipClassName }) => {
  const classes = useStyles()

  return (
    <Chip
      onDelete={() => onAction(item)}
      label={item.name}
      deleteIcon={icon}
      className={classNames(classes.container, chipClassName)}
    />
  )
}

export default ChipItem
