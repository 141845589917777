import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  shrink: {
    transform: 'scale(0.8)'
  },
  label: {
    fontSize: '16px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.38px',
    color: theme.palette.web.usbGrey,
    textTransform: 'uppercase',
    '&.Mui-focused, &.Mui-error': {
      color: theme.palette.web.usbGrey
    }
  },
  formControl: {
    margin: `${theme.spacing(2)}px 0`
  },
  root: {
    borderRadius: 2,
    position: 'relative',
    backgroundColor: theme.palette.web.white,
    border: `1px solid ${theme.palette.web.lightGrey}`,
    color: theme.palette.web.darkGrey,
    padding: '0px 10px',
    height: 36,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '&.Mui-error': {
      border: `1px solid ${theme.palette.web.darkRed}`,
      color: theme.palette.web.red
    },
    '&.Mui-disabled': {
      backgroundColor: 'var(--shades-1, #F7F7F7) !important'
    }
  },
  input: {
    fontSize: 14,
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '0.19px'
  }
}))
