import React, { useState, useEffect } from 'react'
import { ReactSVG } from 'react-svg'
import shortId from 'shortid'
import classNames from 'classnames'
import cookie from 'js-cookie'
import { Drawer, IconButton, List } from '@material-ui/core'
import { useLayoutState, useLayoutDispatch, toggleSidebar, closeSidebar } from 'context/LayoutContext'
import { sidebar } from 'data/SidebarData'
import ICON_MENU from 'assets/images/icons-menu.svg'
import ICON_MINIMIZE from 'assets/images/icons-minimize.svg'
import theme from 'assets/theme/theme'
import { SIDEBAR_MODULES } from 'Constants'
import SidebarLink from '../SidebarLink/SidebarLink'
import useStyles from './styles'

const Sidebar = () => {
  const classes = useStyles()
  const { isSidebarOpened } = useLayoutState()
  const layoutDispatch = useLayoutDispatch()
  const [isPermanent, setPermanent] = useState(true)
  const sidebarItems = sidebar
  const sidebarModules = cookie.get(SIDEBAR_MODULES)

  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth
    const breakpointWidth = theme.breakpoints.values.sm
    const isSmallScreen = windowWidth < breakpointWidth

    if (isSmallScreen && isPermanent) {
      setPermanent(false)
      closeSidebar(layoutDispatch)
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowWidthChange)
    handleWindowWidthChange()
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange)
    }
  })

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened
      })}
      classes={{
        paper: classNames(classes.noBorder, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened
        })
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)} className={classes.sidebarMenuButton}>
          {isSidebarOpened ? <ReactSVG src={ICON_MINIMIZE} /> : <ReactSVG src={ICON_MENU} />}
        </IconButton>
      </div>
      <List
        classes={{
          padding: classNames(classes.list)
        }}
      >
        {sidebarItems.map(
          (item) =>
            (!item.needsAuth || sidebarModules.indexOf(item.name) > 0) && (
              <SidebarLink key={shortId.generate()} isSidebarOpened={isSidebarOpened} {...item} />
            )
        )}
      </List>
    </Drawer>
  )
}

export default Sidebar
