import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import DotIcon from '@material-ui/icons/FiberManualRecord'
import { CONNECTION_LOCAL } from 'Constants'
import useStyles from './styles'

const ConnectionStatus = ({
  dotClassName,
  textClassName,
  connected = false,
  lastSeen = null,
  connectionType = null
}) => {
  const classes = useStyles()

  const getConnectionStatus = () => {
    const type = connectionType === CONNECTION_LOCAL ? 'Connection.local' : 'Connection.remote'
    return connected ? type : 'Connection.lastSeen'
  }

  return (
    <div className={classes.statusContainer}>
      <DotIcon
        className={classNames(dotClassName || classes.dot, {
          [classes.dotRed]: !connected,
          [classes.dotGreen]: connected
        })}
      />
      <Typography className={textClassName || classes.connectionText}>
        <FormattedMessage
          id={getConnectionStatus()}
          values={{ 0: lastSeen ? moment(lastSeen).format('L LTS') : lastSeen }}
        />
      </Typography>
    </div>
  )
}

export default ConnectionStatus
