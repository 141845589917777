import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@material-ui/core/Typography'
import ReadOnlyTextField from 'components/ReadOnlyTextField/ReadOnlyTextField'
import { buildAddress, buildName } from 'utils/Utils'
import useStyles from './styles'

const BoilerForm = ({ boilerToView }) => {
  const classes = useStyles()
  const { formatMessage } = useIntl()
  const noData = formatMessage({ id: 'Commons.noData' })

  const renderAddress = () => {
    const address = buildAddress(boilerToView.homeOwnerAddress, noData)
    return buildAddress(boilerToView.boilerAddress) === address
      ? formatMessage({ id: 'Commons.sameAddress' })
      : buildAddress(boilerToView.homeOwnerAddress, noData)
  }

  return (
    <div className={classes.container}>
      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>
          <FormattedMessage id={'Boilers.modal.boiler'} />
        </Typography>
        <ReadOnlyTextField
          labelId={'Boilers.modal.boilerSerialNumber'}
          value={boilerToView ? boilerToView.serialNumber : ''}
        />
        <ReadOnlyTextField labelId={'Boilers.modal.gatewayType'} value={boilerToView ? boilerToView.gatewayType : ''} />
        <ReadOnlyTextField
          labelId={'Boilers.modal.assignedContractor'}
          value={boilerToView ? boilerToView.assignedContractor : ''}
        />
        <ReadOnlyTextField
          labelId={'Boilers.modal.boilerAddress'}
          value={boilerToView ? buildAddress(boilerToView.boilerAddress, noData) : noData}
        />
      </div>
      <div className={classes.section}>
        <Typography className={classes.sectionTitle}>
          <FormattedMessage id={'Boilers.modal.homeOwner'} />
        </Typography>
        <ReadOnlyTextField
          labelId={'Boilers.modal.name'}
          value={boilerToView ? buildName(boilerToView.homeOwnerFirstName, boilerToView.homeOwnerLastName) : ''}
        />
        <ReadOnlyTextField
          labelId={'Boilers.modal.phoneNumber'}
          value={boilerToView ? boilerToView.homeOwnerPhoneNumber : ''}
        />
        <ReadOnlyTextField labelId={'Boilers.modal.email'} value={boilerToView ? boilerToView.homeOwnerEmail : ''} />
        <ReadOnlyTextField labelId={'Boilers.modal.address'} value={boilerToView ? renderAddress() : noData} />
      </div>
    </div>
  )
}

export default BoilerForm
