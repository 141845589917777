import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  fixedWidth: {
    width: 128
  },
  button: {
    height: 38,
    padding: '7px 3px 9px 5px',
    backgroundColor: theme.palette.web.blue,
    borderRadius: 6,
    fontSize: 16,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.palette.web.white,
    textTransform: 'uppercase',
    float: 'right',
    '&:hover': {
      backgroundColor: theme.palette.web.blue
    },
    '&:disabled': {
      color: theme.palette.web.white,
      opacity: 0.5
    }
  }
}))
