import React from 'react'
import Helmet from 'react-helmet'
import { useIntl } from 'react-intl'
import Layout from 'components/Layout/Layout'

const Notifications = () => {
  const title = useIntl().formatMessage({ id: 'Notifications.title' }, { 0: process.env.REACT_APP_PAGE_TITLE })

  return (
    // This is only dummy data of an example dashboard and will be changed
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Layout></Layout>
    </>
  )
}

export default Notifications
