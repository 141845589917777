import React from 'react'
import shortId from 'shortid'
import LinkedTitle from 'components/LinkedTitle/LinkedTitle'
import useStyles from './styles'

const ContentTable = ({ elements }) => {
  const classes = useStyles()

  return (
    <ul className={classes.list}>
      {elements.map((el) => (
        <li key={shortId.generate()}>
          <LinkedTitle title={el.label} to={el.id} />
        </li>
      ))}
    </ul>
  )
}

export default ContentTable
