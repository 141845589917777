import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  footerContainer: {},
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      padding: '20px 0'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '20px 0 5px 0'
    }
  },
  rootMobile: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    paddingBottom: 15,
    maxWidth: 500
  },
  footerText: {
    fontSize: 12
  },
  clickable: {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))
