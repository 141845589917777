import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import Loader from 'components/Loader/Loader'
import PageTitle from 'components/PageTitle/PageTitle'
import TCBlock from 'components/TermsAndConditionsPanel/TCBlock'
import TCIndex from 'components/TermsAndConditionsPanel/TCIndex'
import useCallService from 'services/useCallService'
import { GET_BRAND_DATA_URL, PRIVACY } from 'Constants'
import useStyles from './styles'

const TermsAndConditionsPanel = () => {
  const classes = useStyles()
  const [partner, setPartner] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const { callSecurityService } = useCallService()

  useEffect(() => {
    async function fetchBrand() {
      setIsLoading(true)
      return callSecurityService(
        'GET',
        GET_BRAND_DATA_URL.replace('{brand}', process.env.REACT_APP_PARTNER_NAME),
        {},
        false
      )
    }

    fetchBrand().then((response) => {
      setPartner(response.data)
      setIsLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          <div className={classes.containerHeader}>
            <PageTitle titleId={'TermsAndConditions.panel.title'} />
          </div>
          <div className={classes.dataContainer}>
            <TCBlock title={'TermsAndConditions.panel.contentTitle'} values={{ 0: partner.name, 1: partner.appName }}>
              <Typography className={classNames(classes.upperCaseText)}>
                <FormattedMessage id={'TermsAndConditions.panel.p1'} values={{ 0: partner.name, 1: partner.appName }} />
              </Typography>
              <Typography className={classNames(classes.text, classes.newParagraph)}>
                <FormattedMessage id={'TermsAndConditions.panel.p2'} values={{ 0: partner.appName.toUpperCase() }} />
              </Typography>
              <TCIndex index={'1.'} text={'TermsAndConditions.panel.p2.l1'} blockClass={classes.indexMargin}>
                <TCIndex
                  index={'a.'}
                  text={'TermsAndConditions.panel.p2.l1.a'}
                  blockClass={classes.indexMargin}
                  textClass={classes.upperCaseText}
                />
                <TCIndex
                  index={'b.'}
                  text={'TermsAndConditions.panel.p2.l1.b'}
                  blockClass={classes.indexMargin}
                  textClass={classes.upperCaseText}
                />
                <TCIndex
                  index={'c.'}
                  text={'TermsAndConditions.panel.p2.l1.c'}
                  blockClass={classes.indexMargin}
                  textClass={classes.upperCaseText}
                />
                <TCIndex
                  index={'d.'}
                  text={'TermsAndConditions.panel.p2.l1.d'}
                  blockClass={classes.indexMargin}
                  textClass={classes.upperCaseText}
                />
              </TCIndex>
              <TCIndex
                index={'2.'}
                text={'TermsAndConditions.panel.p2.l2'}
                blockClass={classes.indexMargin}
                textClass={classes.upperCaseText}
              >
                <TCIndex
                  index={'a.'}
                  text={'TermsAndConditions.panel.p2.l2.a'}
                  blockClass={classes.indexMargin}
                  values={{ 0: partner.name, 1: partner.appName }}
                  textClass={classes.upperCaseText}
                />
                <TCIndex
                  index={'b.'}
                  text={'TermsAndConditions.panel.p2.l2.b'}
                  blockClass={classes.indexMargin}
                  textClass={classes.upperCaseText}
                />
                <TCIndex
                  index={'c.'}
                  text={'TermsAndConditions.panel.p2.l2.c'}
                  blockClass={classes.indexMargin}
                  textClass={classes.upperCaseText}
                />
              </TCIndex>
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t1'}>
              <Typography className={classNames(classes.text)}>
                <FormattedMessage
                  id={'TermsAndConditions.panel.t1.p1'}
                  values={{ 0: partner.name, 1: partner.appName }}
                />
              </Typography>
              <TCIndex
                index={'1.'}
                text={'TermsAndConditions.panel.t1.l1'}
                blockClass={classes.indexMargin}
                values={{ 0: partner.name }}
              />
              <TCIndex
                index={'2.'}
                text={'TermsAndConditions.panel.t1.l2'}
                blockClass={classes.indexMargin}
                values={{ 0: partner.appName }}
              />
              <TCIndex index={'3.'} text={'TermsAndConditions.panel.t1.l3'} blockClass={classes.indexMargin} />
              <TCIndex index={'4.'} text={'TermsAndConditions.panel.t1.l4'} blockClass={classes.indexMargin} />
              <TCIndex index={'5.'} text={'TermsAndConditions.panel.t1.l5'} blockClass={classes.indexMargin} />
              <TCIndex index={'6.'} text={'TermsAndConditions.panel.t1.l6'} blockClass={classes.indexMargin} />
              <TCIndex index={'7.'} text={'TermsAndConditions.panel.t1.l7'} blockClass={classes.indexMargin} />
              <TCIndex index={'8.'} text={'TermsAndConditions.panel.t1.l8'} blockClass={classes.indexMargin} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t2'}>
              <TCIndex index={'2.1'} text={'TermsAndConditions.panel.t2.l1'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t3'}>
              <TCIndex index={'3.1'} text={'TermsAndConditions.panel.t3.l1'} />
              <TCIndex index={'3.2'} text={'TermsAndConditions.panel.t3.l2'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t4'}>
              <Typography className={classNames(classes.text)}>
                <FormattedMessage id={'TermsAndConditions.panel.t4.p1'} />
              </Typography>
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t5'}>
              <TCIndex index={'5.1'} text={'TermsAndConditions.panel.t5.l1'} />
              <TCIndex index={'5.2'} text={'TermsAndConditions.panel.t5.l2'} />
              <TCIndex index={'5.3'} text={'TermsAndConditions.panel.t5.l3'} />
              <TCIndex index={'5.4'} text={'TermsAndConditions.panel.t5.l4'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t6'}>
              <Typography className={classNames(classes.text)}>
                <FormattedMessage
                  id={'TermsAndConditions.panel.t6.p1'}
                  values={{
                    0: (
                      <Link to={PRIVACY}>
                        <FormattedMessage id={'Privacy.panel.title'} />
                      </Link>
                    )
                  }}
                />
              </Typography>
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t7'}>
              <Typography className={classNames(classes.text)}>
                <FormattedMessage id={'TermsAndConditions.panel.t7.p1'} />
              </Typography>
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t8'}>
              <TCIndex index={'8.1'} text={'TermsAndConditions.panel.t8.l1'} />
              <TCIndex index={'8.2'} text={'TermsAndConditions.panel.t8.l2'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t9'}>
              <TCIndex index={'9.1'} text={'TermsAndConditions.panel.t9.l1'} />
              <TCIndex index={'9.2'} text={'TermsAndConditions.panel.t9.l2'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t10'}>
              <TCIndex index={'10.1'} text={'TermsAndConditions.panel.t10.l1'} />
              <TCIndex index={'10.2'} text={'TermsAndConditions.panel.t10.l2'} />
              <TCIndex index={'10.3'} text={'TermsAndConditions.panel.t10.l3'} />
              <TCIndex index={'10.4'} text={'TermsAndConditions.panel.t10.l4'} />
              <TCIndex index={'10.5'} text={'TermsAndConditions.panel.t10.l5'} />
              <TCIndex index={'10.6'} text={'TermsAndConditions.panel.t10.l6'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t11'}>
              <Typography className={classNames(classes.text)}>
                <FormattedMessage id={'TermsAndConditions.panel.t11.p1'} />
              </Typography>
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t12'}>
              <TCIndex index={'12.1'} text={'TermsAndConditions.panel.t12.l1'} />
              <TCIndex index={'12.2'} text={'TermsAndConditions.panel.t12.l2'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t13'}>
              <Typography className={classNames(classes.text)}>
                <FormattedMessage id={'TermsAndConditions.panel.t13.p1'} />
              </Typography>
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t14'}>
              <TCIndex index={'14.1'} text={'TermsAndConditions.panel.t14.l1'} />
              <TCIndex index={'14.2'} text={'TermsAndConditions.panel.t14.l2'} />
              <TCIndex index={'14.3'} text={'TermsAndConditions.panel.t14.l3'} />
              <TCIndex index={'14.4'} text={'TermsAndConditions.panel.t14.l4'} />
              <TCIndex index={'14.5'} text={'TermsAndConditions.panel.t14.l5'} />
              <TCIndex index={'14.6'} text={'TermsAndConditions.panel.t14.l6'} />
              <TCIndex index={'14.7'} text={'TermsAndConditions.panel.t14.l7'} />
              <TCIndex index={'14.8'} text={'TermsAndConditions.panel.t14.l8'} />
              <TCIndex index={'14.9'} text={'TermsAndConditions.panel.t14.l9'} />
            </TCBlock>
            <TCBlock title={'TermsAndConditions.panel.t15'}>
              <Typography className={classNames(classes.upperCaseText)}>
                <FormattedMessage
                  id={'TermsAndConditions.panel.t15.p1'}
                  values={{ 0: partner.name, 1: partner.appName }}
                />
              </Typography>
            </TCBlock>
          </div>
        </div>
      )}
    </>
  )
}

export default TermsAndConditionsPanel
