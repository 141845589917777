import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  detailedPhotoContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  backButtonRow: {},
  backButton: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10
    }
  },
  imageRow: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  loadingImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 320,
    width: 320,
    zIndex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.7)'
  },
  activeImage: {
    height: 320,
    width: 320,
    objectFit: 'contain'
  },
  imageDetailRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  img: {
    cursor: 'pointer',
    height: 133,
    objectFit: 'contain'
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8
  },
  cardRoot: {
    [theme.breakpoints.down('sm')]: {
      '&:last-child': {
        padding: 8
      }
    }
  },
  checkbox: {
    color: `${theme.palette.web.blue} !important`,
    padding: 0
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15,
    paddingLeft: 8
  }
}))
