import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  container: {
    padding: '24px 33px 0px 33px',
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  containerHeader: {
    width: '100%',
    display: 'flex'
  },
  dataContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      marginTop: 25,
      padding: '42px 80px 24px 80px',
      borderRadius: 8,
      boxShadow: `0 2px 4px 0 ${theme.palette.web.shadowGrey}`,
      backgroundColor: theme.palette.web.white
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 25,
      padding: 0
    }
  },
  line: {
    margin: '25px 0',
    opacity: 0.2,
    borderTop: `2px solid ${theme.palette.web.usbGrey}`
  },
  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  languageContainer: {
    height: 50,
    width: 200
  },
  button: {
    flex: 1,
    maxWidth: 230,
    height: 32,
    padding: '8px 39.5px 7px 38.5px',
    backgroundColor: theme.palette.web.blue,
    borderRadius: 6,
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: theme.palette.web.white,
    textTransform: 'none',
    float: 'right',
    '&:hover': {
      backgroundColor: theme.palette.web.blue
    },
    '&:disabled': {
      color: theme.palette.web.white,
      opacity: 0.5
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      maxWidth: '100%'
    }
  }
}))
