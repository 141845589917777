import React from 'react'
import { useHistory } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import cookie from 'js-cookie'
import { AppBar, Toolbar, IconButton, Box } from '@material-ui/core'
import LinkIcon from 'components/LinkIcon/LinkIcon'
import MenuPopperItem from 'components/MenuPopperItem/MenuPopperItem'
import ProfileMenu from 'components/ProfileMenu/ProfileMenu'
import { useAuthorizationContext } from 'context/AuthorizationContext'
import { useMobileContext } from 'context/MobileContext'
import ICON_BACK from 'assets/images/icons-back-mobile.svg'
import ICON_LOGOUT from 'assets/images/icons-logout.svg'
import ICON_NOTIFICATIONS from 'assets/images/icons-notifications.svg'
import ICON_USER from 'assets/images/icons-user.svg'
import {
  HEADER_MODULES,
  INDEX,
  NOTIFICATIONS,
  NOTIFICATIONS_MODULE,
  PROFILE,
  REP_ROLE,
  TOKEN,
  USER_DATA
} from 'Constants'
import useStyles from './styles'

const Header = () => {
  const classes = useStyles()
  const { isMobile, showBackButton } = useMobileContext()
  const { handleLogout } = useAuthorizationContext()
  const history = useHistory()
  const logo = process.env.REACT_APP_HEADER_LOGO
  const logged = cookie.get(TOKEN)
  const headerModules = cookie.get(HEADER_MODULES)
  const userData = cookie.get(USER_DATA) ? JSON.parse(cookie.get(USER_DATA)) : null
  let userName = ''
  if (userData) {
    if (userData.role === REP_ROLE) {
      userName = userData.lastName
    } else {
      userName = `${userData.firstName} ${userData.lastName}`
    }
  }

  const gotoIndex = () => {
    history.push(INDEX)
  }

  return (
    <AppBar elevation={0} position="static" className={classes.appBar}>
      <Toolbar className={logged ? classes.toolbar : classes.toolbarNotLogged}>
        {isMobile && showBackButton && (
          <IconButton onClick={gotoIndex} className={classes.mobileMenuIcon}>
            <ReactSVG src={ICON_BACK} className={classes.backButton} />
          </IconButton>
        )}
        <Box flex={1} onClick={gotoIndex}>
          <div className={classes.mainLogo}>
            <img src={logo} alt="logo" />
          </div>
        </Box>
        {!isMobile && <div className={classes.grow} />}
        {headerModules && headerModules.includes(NOTIFICATIONS_MODULE) && (
          <LinkIcon icon={ICON_NOTIFICATIONS} link={NOTIFICATIONS} />
        )}
        {logged && (
          <ProfileMenu icon={ICON_USER} text={userName}>
            <MenuPopperItem icon={ICON_USER} labelId={'Profile.profile'} link={PROFILE} />
            <MenuPopperItem
              icon={ICON_LOGOUT}
              labelId={'Profile.logout'}
              onClick={handleLogout}
              onMouseDown={handleLogout}
            />
          </ProfileMenu>
        )}
        {!logged && isMobile && <div className={classes.grow} />}
      </Toolbar>
    </AppBar>
  )
}

export default Header
