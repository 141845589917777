import { makeStyles } from '@material-ui/styles'

export default makeStyles((theme) => ({
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: `1px solid rgb(117, 123, 131, 0.2)`,
    marginRight: 20
  },
  statusCell: {
    padding: '8px 0px'
  },
  statusLabel: {
    fontSize: 16,
    fontWeight: 'bold',
    color: theme.palette.web.black
  },
  statusValue: {
    fontSize: 16,
    fontWeight: 'normal',
    color: theme.palette.web.black
  },
  disconnectedContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  disconnectedImage: {
    '& svg': {
      opacity: 0.6,
      fill: theme.palette.web.lightGrey
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: 60
    }
  },
  disconnectedText: {
    maxWidth: 269,
    marginTop: 20,
    fontSize: 18,
    fontWeight: 300,
    letterSpacing: '-0.6px',
    textAlign: 'center',
    color: theme.palette.web.usbGrey,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    whiteSpace: 'normal'
  },
  table: {
    [theme.breakpoints.down('sm')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      whiteSpace: 'nowrap',
      scrollbarWidth: 'none' /* Firefox */,
      msOverflowStyle: 'none' /* Internet Explorer 11 */,
      '&::-webkit-scrollbar': {
        display: 'none' /* WebKit (Chrome, Safari) */
      }
    }
  }
}))
