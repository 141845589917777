import { makeStyles } from '@material-ui/styles'

export default makeStyles(() => ({
  container: {
    justifyContent: 'space-between',
    height: 21,
    minWidth: 104,
    borderRadius: 2,
    fontSize: 12,
    fontWeight: 'bold'
  }
}))
